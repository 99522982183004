<div class="container">
	<img
		src="assets/search-inactive.svg"
		*ngIf="!active"
	/>

	<img
		src="assets/search-active.svg"
		*ngIf="active"
	/>

	<input
		type="text"
		data-testid="search-input"
		(focus)="onFocus()"
		(input)="onInput()"
		[placeholder]="placeholder"
		#input
	/>

	<img
		src="assets/clear.svg"
		class="clear-btn"
		data-testid="clear-btn"
		*ngIf="displayClearBtn"
		(click)="onClearClicked()"
	/>
</div>
