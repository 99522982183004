import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Defect } from "../../../models/openAPIAliases";
import { DefectListService } from "../service/defect-list.service";

export class DefectListStateModel {
	companyId: string;
	defects: Defect[];
	totalCount: string;
	type: "open" | "closed";
}

const defectListStateDefaults: DefectListStateModel = {
	companyId: null,
	defects: null,
	totalCount: null,
	type: "open",
};

export class GetDefectListWithQuery {
	static readonly type = "[Defect] Get Defects with Queries";
	constructor(public companyPayload: string, public queryPayload: URLSearchParams, public endpoint: string) {}
}

@Injectable()
@State<DefectListStateModel>({
	name: "defects",
	defaults: defectListStateDefaults,
})
export class DefectListState {
	constructor(private defectListService: DefectListService) {}

	@Selector()
	static getDefectList(state: DefectListStateModel): {
		companyId: string;
		readonly defects: Defect[];
		type: "open" | "closed";
	} {
		return {
			companyId: state.companyId,
			defects: state.defects,
			type: state.type,
		};
	}

	@Selector()
	static getTotalCount(state: DefectListStateModel): {
		totalCount: string;
		companyId: string;
	} {
		return {
			totalCount: state.totalCount,
			companyId: state.companyId,
		};
	}

	@Action(GetDefectListWithQuery)
	getDefects(
		{ patchState }: StateContext<DefectListStateModel>,
		{ companyPayload, queryPayload, endpoint }: GetDefectListWithQuery,
	) {
		return this.defectListService.getDefectList(companyPayload, queryPayload, endpoint).pipe(
			tap((result: HttpResponse<Defect[]>) => {
				patchState({
					companyId: companyPayload,
					defects: result.body,
					type: queryPayload.get("statuses") === "open,pending" ? "open" : "closed",
					totalCount: result.headers.get("x-total-count"),
				});
			}),
		);
	}
}
