import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import {
	InspectionDetailFormDataPhotosViewModel,
	InspectionDetailListViewViewModel,
	InspectionDetailStaticInfoViewModel,
} from "src/app/models/inspection-detail-view-models.models";
import { InspectionDetailsViewModelService } from "src/app/services/inspection-details-view-model.service";
import { PageInformation } from "src/app/services/previous-page.service";
import { environment } from "src/environments/environment";
import { isDefined } from "src/utils/isDefined/isDefined";
import { AppState } from "../../app.state";
import { InspectionAsset, InspectionDetailData, InspectionGet, LangDictGet } from "../../models/openAPIAliases";
import { InspectionDetailStaticInfoSortedField } from "./../../models/inspection-detail-view-models.models";
import { ClearInspection, GetInspection, InspectionState } from "./state/inspection-detail.state";

export interface SingleInspectionListView {
	inspectionAssets: InspectionAsset[];
	inspectionDetailData?: InspectionDetailData[];
}

export interface SortedInspectionDetails {
	detailItemContent: string;
	detailItemField: string;
}

@Component({
	selector: "app-inspection-detail",
	templateUrl: "./inspection-detail.component.html",
	styleUrls: ["./inspection-detail.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InspectionDetailComponent implements OnInit, OnDestroy {
	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
	@Select(AppState.selectPageInformation) pageInformation$: Observable<PageInformation>;
	@Select(InspectionState.getInspection) inspection$: Observable<InspectionGet>;
	@Select(InspectionState.getStaticInfo) staticInfo$: Observable<InspectionDetailStaticInfoViewModel>;

	private inspectionCompanyId: string;
	private onDestroy$ = new Subject<void>();
	public defectsOnly = true;
	public formDataPhotos: Array<InspectionDetailFormDataPhotosViewModel> = [];
	public inspectionId: string;
	public previousPageTitle: string = null;
	public previousPageUrl: string = null;
	public sortedStaticInfo: Array<InspectionDetailStaticInfoSortedField> = [];
	public staticInfo: InspectionDetailStaticInfoViewModel;
	public viewModel: InspectionDetailListViewViewModel;
	public viewModelError = false;
	public viewModelErrorDetails = "";

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private store: Store,
		public inspectionDetailsViewModelService: InspectionDetailsViewModelService,
	) {}

	ngOnInit() {
		// get the previous page title and url from the store
		this.pageInformation$.subscribe((pageInformation: PageInformation) => {
			this.previousPageTitle = pageInformation ? pageInformation.previousPageTitle : "";
			this.previousPageUrl = pageInformation ? pageInformation.previousPageUrl : "";
		});

		this.selectedCompanyId$.pipe(filter(isDefined), takeUntil(this.onDestroy$)).subscribe(selectedCompanyId => {
			this.inspectionId = this.route.snapshot.paramMap.get("inspection-uuid");
			this.inspectionCompanyId = this.route.snapshot.paramMap.get("company-uuid");

			if (this.inspectionId && this.inspectionCompanyId === selectedCompanyId) {
				this.store.dispatch(
					new GetInspection(
						this.inspectionId,
						selectedCompanyId,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
					),
				);
				this.getSortedStaticInfoAndInspectionData();
			} else {
				this.router.navigate([
					this.previousPageUrl.includes("asset-details") ? "/inspection-list" : this.previousPageUrl,
				]);
			}
		});
	}

	ngOnDestroy() {
		this.store.dispatch(new ClearInspection());
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	/**
	 * Toggle Defects Only value
	 */
	toggleFilter() {
		this.defectsOnly = !this.defectsOnly;
	}

	/**
	 * Get sorted static info and inspection data
	 */
	private getSortedStaticInfoAndInspectionData(): void {
		this.languageDictionary$.pipe(filter(isDefined), takeUntil(this.onDestroy$)).subscribe(() => {
			this.staticInfo$.subscribe((staticInfo: InspectionDetailStaticInfoViewModel) => {
				this.staticInfo = staticInfo;
				if (staticInfo) {
					this.sortedStaticInfo = this.inspectionDetailsViewModelService.getSortedStaticInfo(staticInfo);
				}
			});

			this.inspection$.pipe(takeUntil(this.onDestroy$)).subscribe((inspection: InspectionGet) => {
				try {
					if (inspection) {
						this.viewModel =
							this.inspectionDetailsViewModelService.createInspectionDetailListViewViewModel(inspection);
						this.formDataPhotos = this.inspectionDetailsViewModelService.getFormDataPhotos(inspection);
					}
				} catch (error) {
					console.log("Error: ");
					console.log(error);
					this.viewModelError = true;
					this.viewModelErrorDetails = error.toString();
				}
			});
		});
	}
}
