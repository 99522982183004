import { tap } from "rxjs/operators";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { AssetService } from "../service/asset.service";
import { Defect, InspectionGet } from "../../../models/openAPIAliases";
import { OpenDefectTableViewModel, ClosedDefectViewModel } from "../../../models/open-defect-table.models";
import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

export interface AssetStateModel {
	inspection: InspectionGet;
	openDefects: Defect[];
	openWithComments: OpenDefectTableViewModel[];
	totalOpenDefects: number;
	closedDefects: Defect[];
	closedWithComments: ClosedDefectViewModel[];
	totalClosedDefects: number;
}

export const assetStateDefaults: AssetStateModel = {
	inspection: null,
	openDefects: null,
	openWithComments: null,
	totalOpenDefects: null,
	closedDefects: null,
	closedWithComments: null,
	totalClosedDefects: null,
};

export class GetAssetInspection {
	static readonly type = "[Asset] Get Asset Inspection";
	constructor(public assetPayload: string, public companyPayload: string, public endpoint: string) {}
}

export class GetOpenAssetDefects {
	static readonly type = "[Asset] Get Open Asset Defects";
	constructor(
		public assetPayload: string,
		public companyPayload: string,
		public endpoint: string,
		public query: URLSearchParams,
	) {}
}

export class SetOpenWithComments {
	static readonly type = "[Asset] Set Open Defects with Comments";
	constructor(public payload: OpenDefectTableViewModel[]) {}
}

export class GetClosedAssetDefects {
	static readonly type = "[Asset] Get Closed Asset Defects";
	constructor(
		public assetPayload: string,
		public companyPayload: string,
		public endpoint: string,
		public query: URLSearchParams,
	) {}
}

export class SetClosedWithComments {
	static readonly type = "[Asset] Set Closed Defects with Comments";
	constructor(public payload: ClosedDefectViewModel[]) {}
}

export class ClearAsset {
	static readonly type = "[Asset] Clear Asset";
}
@Injectable()
@State<AssetStateModel>({
	name: "asset",
	defaults: assetStateDefaults,
})
export class AssetState {
	constructor(private assetService: AssetService) {}

	@Selector()
	static getAssetLastInspection(state: AssetStateModel) {
		return state.inspection;
	}

	@Selector()
	static getAllOpenAssetDefects(state: AssetStateModel) {
		return state.openDefects;
	}

	@Selector()
	static getAllClosedAssetDefects(state: AssetStateModel) {
		return state.closedDefects;
	}

	@Selector()
	static getOpenWithComments(state: AssetStateModel) {
		return state.openWithComments;
	}

	@Selector()
	static getClosedWithComments(state: AssetStateModel) {
		return state.closedWithComments;
	}

	@Selector()
	static getTotalOpenDefects(state: AssetStateModel) {
		return state.totalOpenDefects;
	}

	@Selector()
	static getTotalClosedDefects(state: AssetStateModel) {
		return state.totalClosedDefects;
	}

	@Action(GetAssetInspection)
	getAssetInspection(
		{ patchState }: StateContext<AssetStateModel>,
		{ assetPayload, companyPayload, endpoint }: GetAssetInspection,
	) {
		return this.assetService.getAssetInspection(assetPayload, companyPayload, endpoint).pipe(
			tap((result: InspectionGet[]) => {
				patchState({
					inspection: result[0],
				});
			}),
		);
	}

	@Action(GetOpenAssetDefects)
	getOpenAssetDefects(
		{ patchState }: StateContext<AssetStateModel>,
		{ assetPayload, companyPayload, endpoint, query }: GetOpenAssetDefects,
	) {
		return this.assetService.getAssetDefects(assetPayload, companyPayload, endpoint, query).pipe(
			tap((response: HttpResponse<Defect[]>) => {
				patchState({
					openDefects: response.body,
					totalOpenDefects: parseInt(response.headers.get("x-total-count"), 10),
				});
			}),
		);
	}

	@Action(GetClosedAssetDefects)
	getClosedAssetDefects(
		{ patchState }: StateContext<AssetStateModel>,
		{ assetPayload, companyPayload, endpoint, query }: GetClosedAssetDefects,
	) {
		return this.assetService.getAssetDefects(assetPayload, companyPayload, endpoint, query).pipe(
			tap((response: HttpResponse<Defect[]>) => {
				patchState({
					closedDefects: response.body,
					totalClosedDefects: parseInt(response.headers.get("x-total-count"), 10),
				});
			}),
		);
	}

	@Action(SetOpenWithComments)
	getOpenWithComments({ patchState }: StateContext<AssetStateModel>, { payload }: SetOpenWithComments) {
		patchState({
			openWithComments: payload,
		});
	}

	@Action(SetClosedWithComments)
	setClosedWithComments({ patchState }: StateContext<AssetStateModel>, { payload }: SetClosedWithComments) {
		patchState({
			closedWithComments: payload,
		});
	}

	@Action(ClearAsset)
	clearAsset({ setState }: StateContext<AssetStateModel>) {
		return setState(assetStateDefaults);
	}
}
