/**
 * Given a start date and an end date, return an array that contains a date object for each of the
 * days in the interval, including the endpoints
 *
 * @param startDate starting date for the range
 * @param endDate end date for the range

 * @returns An array of dates; one for each day in the range
 */
export function getDatesInRange(startDate: Date, endDate: Date): Date[] {
	const dates = [];

	for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
		dates.push(new Date(date));
	}

	return dates;
}
