import { Component, ViewEncapsulation } from "@angular/core";

export interface RouteLink {
	label: string;
	link?: string;
	subLabels?: ReadonlyArray<{ label: string; link: string }>;
}

@Component({
	selector: "app-default-layout",
	templateUrl: "./default-layout.component.html",
	styleUrls: ["./default-layout.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent {
	constructor() {}
}
