import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SettingsGet } from "../models/openAPIAliases";
import { SafeHtml } from "@angular/platform-browser";

@Injectable({
	providedIn: "root",
})
export class SettingsService {
	private httpOptions = {
		responseType: "text",
	} as const;

	constructor(private http: HttpClient) {}

	public getSettings(companyId: string, endpoint: string): Observable<SettingsGet[]> {
		return this.http.get<SettingsGet[]>(`${endpoint}/evir-settings?companyId=${companyId}&activeOnly=True`);
	}

	public patchSettings(payload: unknown, endpoint: string): Observable<SettingsGet> {
		return this.http.patch<SettingsGet>(`${endpoint}/evir-settings`, payload);
	}

	public getAssetTypeImage(companyId: string, assetViewId: string, endpoint: string): Observable<SafeHtml> {
		return this.http.get(`${endpoint}/asset-views/${assetViewId}?companyId=${companyId}`, this.httpOptions);
	}
}
