import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { OVERLAY_OPTIONS, OverlayOptions } from "./overlay-injection-token";

@Component({
	selector: "app-overlay",
	templateUrl: "./overlay.component.html",
	styleUrls: ["./overlay.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class OverlayComponent {
	progressValue = 0;

	constructor(@Inject(OVERLAY_OPTIONS) public overlayOptions: OverlayOptions) {}
}
