<div class="custom-calendar-header">
	<div class="month-selected">
		<button
			mat-icon-button
			type="button"
			class="mat-calendar-previous-button"
			[disabled]="!previousEnabled()"
			(click)="previousClicked()"
			[attr.aria-label]="prevButtonLabel"
		></button>
		<span
			class="month-label"
			[ngClass]="{ disabled: !nextEnabled() && !previousEnabled() }"
		>
			{{ selectedDate }}
		</span>
		<button
			mat-icon-button
			type="button"
			class="mat-calendar-next-button"
			[disabled]="!nextEnabled()"
			(click)="nextClicked()"
			[attr.aria-label]="nextButtonLabel"
		></button>
	</div>
</div>
