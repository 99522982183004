import { Injectable } from "@angular/core";
import { Defect, InspectionGet } from "../../../models/openAPIAliases";
import { Observable } from "rxjs";
import { RequestService } from "src/app/services/request/request.service";
import { HttpEvent } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class AssetService {
	public lastInspectedEndpoint = "/last-inspections";
	public defectsEndpoint = "/defects";

	private httpOptions = {
		observe: "response",
	};

	constructor(public requestService: RequestService) {}

	public getAssetInspection(assetId, companyId, api): Observable<InspectionGet[]> {
		return this.requestService.get<InspectionGet[]>({
			url: `${api}${this.lastInspectedEndpoint}?companyId=${companyId}&assetIds=${assetId}`,
		});
	}

	public getAssetDefects(assetIds: string, companyId: string, api: string, query: URLSearchParams) {
		const url = new URL(api);
		url.pathname = `${url.pathname}${this.defectsEndpoint}`;
		query.forEach((value, key) => url.searchParams.set(key, value));
		url.searchParams.set("companyId", companyId);
		url.searchParams.set("assetIds", assetIds);

		return this.requestService.get<HttpEvent<Defect[]>>({
			url: url.toString(),
			httpOptions: this.httpOptions,
		});
	}
}
