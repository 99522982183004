import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestService } from "src/app/services/request/request.service";
import { Id, InspectionGet } from "../../../models/openAPIAliases";

@Injectable({
	providedIn: "root",
})
export class InspectionService {
	constructor(public requestService: RequestService) {}

	public getInspection(inspectionId: string, companyId: Id, endpoint: string): Observable<InspectionGet> {
		return this.requestService.get<InspectionGet>({
			url: `${endpoint}/inspections/${inspectionId}?companyId=${companyId}`,
		});
	}
}
