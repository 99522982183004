<div class="container">
	<!-- label -->
	<div>Rows per page:</div>

	<!-- select  -->
	<mat-select
		[(value)]="pageSize"
		(selectionChange)="onSelectionChange($event)"
	>
		<mat-option
			*ngFor="let number of pageSizeOptions"
			[value]="number"
		>
			{{ number }}
		</mat-option>
	</mat-select>

	<!-- position -->
	<div class="position">
		<div>{{ startOnPage }}</div>
		<div>-</div>
		<div>{{ lastOnPage }}</div>
		<div>of</div>
		<div>{{ length }}</div>
	</div>

	<!-- navigation controls  -->
	<div class="navigation-controls">
		<!-- prev page  -->
		<button
			[disabled]="pageIndex === 0"
			(click)="onPageIndexChange(-1)"
			aria-label="Previous page"
			matTooltip="Previous page"
		>
			<svg
				viewBox="0 0 24 24"
				focusable="false"
				class="paginator-icon"
			>
				<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
			</svg>
		</button>

		<!-- next page  -->
		<button
			[disabled]="isLastPage"
			(click)="onPageIndexChange(1)"
			aria-label="Next page"
			matTooltip="Next page"
		>
			<svg
				viewBox="0 0 24 24"
				focusable="false"
				class="paginator-icon"
			>
				<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
			</svg>
		</button>
	</div>
</div>
