/**
 * Wrapper for `new Date` that makes sense:
 *   - If it receives `undefined`, it returns the current date.
 *   - If it receives a timestamp of type `string`, it parses it.
 *
 * @param value Date value.
 */
export const newDate = (value?: number | string) => {
	const parseIntValue =
		typeof value === "string"
			? /^\d+$/u.test(value)
				? parseInt(value, 10)
				: value.replace(/T(?<invalidSingleDigit>\d):/, "T0$1:")
			: value;

	try {
		return parseIntValue !== undefined ? new Date(parseIntValue) : new Date();
	} catch {
		console.error("Invalid date provided to newDate", value);
		return new Date();
	}
};
