import { commonUnits } from "./common-units";

export const supportedImperialUnits = [
	"ft2",
	"ft3",
	"yd2",
	"yd3",
	"in",
	"ft",
	"yd",
	"mi",
	"floz",
	"pt",
	"qt",
	"gal",
	"g",
	"mph",
	"mpg",
	"psi",
	"F",
	"oz",
	"lb",
	"ton",
	"inHg",
	...commonUnits,
];
