import { Component, Input } from "@angular/core";

@Component({
	selector: "app-no-results-card",
	templateUrl: "./no-results-card.component.html",
	styleUrls: ["./no-results-card.component.scss"],
})
export class NoResultsCardComponent {
	@Input() message: string;
}
