import { translate } from "src/app/i18next";

/**
 * Get Repair Status Detail text
 * @param repairStatus Repair status value
 * @returns String with the repair status details based on the repair status
 */
export const getRepairStatusDetail = (repairStatus: string) => {
	switch (repairStatus) {
		case "pending":
			return `(${translate("pending")})`;
		case "ignored":
			return `(${translate("not needed")})`;
		case "repaired":
			return `(${translate("repaired")})`;
		default:
			return "";
	}
};
