import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { Select } from "@ngxs/store";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import equal from "fast-deep-equal";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { currentLocale, translateAndFormat } from "src/app/i18next";
import { TableRecord } from "src/app/services/local-store/local-store.service";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { arraysEquivalent } from "src/utils/arraysEquivalent/arraysEquivalent";
import { isDefined } from "src/utils/isDefined/isDefined";
import { isZonarTheme } from "src/utils/isZonarTheme/isZonarTheme";
import { newDate } from "src/utils/newDate/newDate";
import { urlSearchParamsReplacer } from "src/utils/urlSearchParamsReplacer/urlSearchParamsReplacer";
import { AppState } from "../../app.state";
import {
	CustomDateRangeFilterChange,
	DateRangeFilterChange,
	DefectTypeOption,
} from "../../models/emitter-events.models";
import {
	Asset,
	EvirDivisionsGetInner,
	InspectionNames,
	InspectionTypes,
	InspectorsInner,
	ZoneLayouts,
} from "../../models/openAPIAliases";
import { GetTableFilterComponent, TableFilterChip } from "../get-table-filter/get-table-filter.component";
import { tableFilterChipsEqual } from "../get-table-filter/helpers/table-filter-chips-equal";
import { MultiSelectDropdownComponent, Option } from "../multi-select-dropdown/multi-select-dropdown.component";

export class EnabledFilters {
	assetId: boolean;
	assetType: boolean;
	customDateRange: boolean;
	dateRange: boolean;
	defectType: boolean;
	inspectionType: boolean;
	inspectionName: boolean;
	homeLocation: boolean;
	inspector: boolean;
	page: string;
	severity: boolean;
	tableFilter: boolean;
	resetFilterButton: boolean;
	searchBar: boolean;
}

function getIdFromRecord(record: TableRecord, idFieldName: string) {
	return record.fields.find(field => field.name === idFieldName).value;
}

// state
export interface FilterCardState {
	currentQueryStrings: {
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	};
	tableFilterChips: Array<TableFilterChip>;
}

@Component({
	selector: "app-filter-card",
	templateUrl: "./filter-card.component.html",
	styleUrls: ["./filter-card.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class FilterCardComponent implements OnInit, OnDestroy {
	@ViewChild("getTableFilter") getTableFilterComponent: GetTableFilterComponent;
	@ViewChild(MultiSelectDropdownComponent) multiSelectDropdownComponent: MultiSelectDropdownComponent;

	filtersActive = false;
	state: FilterCardState;
	stateKey: string;
	subscriptions = new Subscription();

	@Input() context = "inspection-history" || "missing-inspections";
	@Input() enabledFilters: EnabledFilters;
	@Output() filterQueryObject = new EventEmitter<{
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	}>();

	@Select(AppState.selectAssets) assets$: Observable<Asset[]>;
	@Select(AppState.selectAssetTypes) assetTypes$: Observable<ZoneLayouts>;
	@Select(AppState.selectDivisions) divisions$: Observable<EvirDivisionsGetInner>;
	@Select(AppState.selectInspectionNames) inspectionNames$: Observable<InspectionNames>;
	@Select(AppState.selectInspectionTypes) inspectionTypes$: Observable<InspectionTypes>;
	@Select(AppState.selectInspectors) inspectors$: Observable<InspectorsInner>;

	public selectedDivisionId = "";

	public filterCardClass = "mat-card-raised";
	public defaultStartDate = startOfDay(subDays(newDate(), 7));
	public defaultEndDate = newDate();
	public currentStartDate = this.defaultStartDate;
	public currentEndDate: Date = this.defaultEndDate;
	public inspectionNameLabel = translateAndFormat("inspection name", "capitalize");
	public inspectionNameContext: string;
	public inspectionNames: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Inspection type filter vars
	public inspectionTypeLabel = translateAndFormat("inspection type", "capitalize");
	public inspectionTypeContext: string;
	public inspectionTypes: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Inspection type filter vars
	public assetIds: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Asset id filter vars
	public assetIdLabel = translateAndFormat("asset id", "capitalize");
	public assetIdContext: string;
	public assetTypes: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Asset type filter vars
	public assetTypeLabel = translateAndFormat("asset type", "capitalize");
	public assetTypeContext: string;
	public homeLocations: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Home location filter vars
	public homeLocationLabel = translateAndFormat("home location", "capitalize");
	public homeLocationContext: string;
	public inspectors: Array<Option> = [
		{
			label: translateAndFormat("loading", "capitalize"),
			value: "asdfasdofu0987a98sd9f76a9sd7f",
		},
	]; // Inspector filter vars
	public inspectorLabel = translateAndFormat("inspector", "capitalize");
	public inspectorContext: string;
	public severities: Array<Option> = []; // Severity filter
	public severityLabel = translateAndFormat("severity", "capitalize");
	public severityContext: string;
	public currentQueryStrings: { queryParams: URLSearchParams; customDateRangeFilter: CustomDateRangeFilterChange } = {
		queryParams: new URLSearchParams(),
		customDateRangeFilter: {
			dates: [{ startTime: this.defaultStartDate, endTime: this.defaultEndDate }],
		},
	};
	public tableFilterChips: Array<TableFilterChip> = [];
	public defectTypes: DefectTypeOption[] = [];
	public isZonarTheme = isZonarTheme();
	public resetCheckbox = false;

	constructor(public memCacheService: MemCacheService) {}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		this.stateKey = `filter-card: ${this.context}`;

		// date filter init
		if (this.enabledFilters.dateRange) {
			this.currentQueryStrings.queryParams.set("startTime", this.currentStartDate.toISOString());
		}

		// Custom Date filter init
		if (this.enabledFilters.dateRange) {
			this.currentQueryStrings.customDateRangeFilter = {
				dates: [{ startTime: this.defaultStartDate, endTime: this.defaultEndDate }],
			};
		}

		// inspection type filter init
		if (this.enabledFilters.inspectionType) {
			this.populateOptions(this.inspectionTypes$, "inspectionTypeContext", "inspectionTypes");
		}

		// inspection name filter init
		if (this.enabledFilters.inspectionName) {
			this.populateOptions(this.inspectionNames$, "inspectionNameContext", "inspectionNames");
		}

		// asset id filter init
		if (this.enabledFilters.assetId) {
			this.assetIdContext = `asset-id-filter: ${this.context}`;
			this.assets$.pipe(filter(isDefined)).subscribe(assets => {
				this.assetIds = Object.entries(assets).map(([, localizationObject]) => ({
					value: localizationObject.assetId,
					label: localizationObject.assetName,
				}));
			});
		}

		// asset type filter init
		if (this.enabledFilters.assetType) {
			this.assetTypeContext = `asset-type-filter: ${this.context}`;
			this.assetTypes$.pipe(filter(isDefined)).subscribe((assetTypes: ZoneLayouts = {}) => {
				this.assetTypes = Object.entries(assetTypes).map(([uuid, localizationObject]) => ({
					value: uuid,
					label: (localizationObject?.[currentLocale()] || localizationObject?.["en-us"]) ?? "—",
				}));
			});
		}

		// home location filter init
		if (this.enabledFilters.homeLocation) {
			this.homeLocationContext = `home-location-filter: ${this.context}`;
			this.divisions$.pipe(filter(isDefined)).subscribe(divisions => {
				this.homeLocations = Object.entries(divisions).map(([, localizationObject]) => ({
					value: localizationObject.divisionId,
					label: localizationObject.divisionName,
				}));
			});
		}

		// inspector filter init
		this.inspectorContext = `inspector-filter: ${this.context}`;
		this.inspectors$.pipe(filter(isDefined)).subscribe(inspectors => {
			this.inspectors = Object.entries(inspectors).map(([, localizationObject]) => ({
				value: localizationObject.inspectorId,
				label: localizationObject.inspectorFirstName + " " + localizationObject.inspectorLastName,
			}));
		});

		if (this.enabledFilters.severity) {
			this.severityContext = `severity-filter: ${this.context}`;
			this.severities =
				this.enabledFilters.page === "inspection-history"
					? [
							{
								label: translateAndFormat("major defects", "title"),
								value: "255",
								icon: "assets/severity-major.svg",
							},
							{
								label: translateAndFormat("minor defects", "title"),
								value: "63,127",
								icon: "assets/severity-minor.svg",
							},
							{
								label: translateAndFormat("no defects", "title"),
								value: "0",
								icon: "assets/severity-none.svg",
							},
					  ]
					: [
							{
								label: translateAndFormat("major defects", "title"),
								value: "255",
								icon: "assets/severity-major.svg",
							},
							{
								label: translateAndFormat("minor defects", "title"),
								value: "63,127",
								icon: "assets/severity-minor.svg",
							},
					  ];
		}

		this.initState();

		// listen for requests to clear state
		this.subscriptions.add(
			this.memCacheService
				.cleared$()
				.pipe(filter(context => this.context === context))
				.subscribe(() => {
					this.memCacheService.setValue("resetState", true);
					this.clearContext();
					this.clearState();
				}),
		);
	}

	/**
	 * Populates a specified property with options derived from an observable's data.
	 * It maps each item from the observable data to an option object, using a UUID and a localized label.
	 * The function is generic and can be used for different data sets (like inspection types or names).
	 *
	 * @param observable - Observable emitting data for option creation.
	 * @param context - Context identifier for logging or tracking.
	 * @param property - Class property to be populated with options.
	 */
	private populateOptions(observable: Observable<any>, context: string, property: string) {
		this[context] = `${context.split("-")[0]}-filter: ${this.context}`;
		observable.pipe(filter(isDefined)).subscribe(data => {
			this[property] = Object.keys(data).map(uuid => ({
				value: uuid,
				label: (data[uuid]?.[currentLocale()] || data[uuid]?.["en-us"]) ?? "—",
			}));
		});
	}

	clearState() {
		this.filtersActive = false;

		this.memCacheService.setValue(this.stateKey, null);
		this.currentQueryStrings = {
			queryParams: new URLSearchParams(),
			customDateRangeFilter: {
				dates: [{ startTime: this.defaultStartDate, endTime: this.defaultEndDate }],
			},
		};

		this.initState();
	}

	clearContext() {
		this.memCacheService.setValue(this.assetIdContext, null);
		this.memCacheService.setValue(this.assetTypeContext, null);
		this.memCacheService.setValue(this.inspectionTypeContext, null);
		this.memCacheService.setValue(this.inspectionNameContext, null);
		this.memCacheService.setValue(this.homeLocationContext, null);
		this.memCacheService.setValue(this.inspectorContext, null);
		this.memCacheService.setValue(this.severityContext, null);
	}

	getDefaultState(): FilterCardState {
		const defaultState: FilterCardState = {
			currentQueryStrings: {
				queryParams: new URLSearchParams(),
				customDateRangeFilter: {
					dates: [
						{
							startTime: this.defaultStartDate,
							endTime: this.defaultEndDate,
						},
					],
				},
			},
			tableFilterChips: [],
		};

		if (this.enabledFilters.assetId) {
			defaultState.currentQueryStrings.queryParams.delete("assetIds");
		}

		if (this.enabledFilters.assetType) {
			defaultState.currentQueryStrings.queryParams.delete("zoneLayoutIds");
		}

		if (this.enabledFilters.dateRange) {
			defaultState.currentQueryStrings.queryParams.set("startTime", this.defaultStartDate.toISOString());
		}

		if (this.enabledFilters.customDateRange) {
			defaultState.currentQueryStrings.customDateRangeFilter = {
				dates: [
					{
						startTime: this.defaultStartDate,
						endTime: this.defaultEndDate,
					},
				],
			};
		}

		if (this.enabledFilters.inspectionType) {
			defaultState.currentQueryStrings.queryParams.delete("inspectionTypeIds");
		}

		if (this.enabledFilters.inspectionName) {
			defaultState.currentQueryStrings.queryParams.delete("inspectionNameIds");
		}

		if (this.enabledFilters.homeLocation) {
			defaultState.currentQueryStrings.queryParams.delete("divisionIds");
		}

		if (this.enabledFilters.inspector) {
			defaultState.currentQueryStrings.queryParams.delete("inspectorIds");
		}

		if (this.enabledFilters.defectType) {
			defaultState.currentQueryStrings.queryParams.set("statuses", "open,pending");
		}

		return defaultState;
	}

	isDefaultState() {
		const defaultState = this.getDefaultState();

		const defaultCustomDateRangeFilter =
			this.currentQueryStrings.customDateRangeFilter.dates.length === 1 &&
			this.currentQueryStrings.customDateRangeFilter.dates[0].startTime.getTime() ===
				defaultState.currentQueryStrings.customDateRangeFilter.dates[0].startTime.getTime() &&
			defaultState.currentQueryStrings.customDateRangeFilter.dates[0].endTime.toDateString() ===
				this.currentQueryStrings.customDateRangeFilter.dates[0].endTime.toDateString();

		return (
			equal(defaultState.currentQueryStrings.queryParams, this.currentQueryStrings.queryParams) &&
			arraysEquivalent(defaultState.tableFilterChips, this.tableFilterChips) &&
			defaultCustomDateRangeFilter
		);
	}

	initState() {
		this.state = this.getState();

		if (!this.state) {
			this.state = this.getDefaultState();
			this.memCacheService.setValue("resetState", true);
		}

		if (
			JSON.stringify(this.currentQueryStrings, urlSearchParamsReplacer) !==
				JSON.stringify(this.state.currentQueryStrings, urlSearchParamsReplacer) ||
			this.memCacheService.getValue("isLoadingPage")
		) {
			this.currentQueryStrings = this.state.currentQueryStrings;
			this.tableFilterChips = this.state.tableFilterChips;

			this.getQueryStringsForTableFilterChips();

			this.emit();
		}
	}

	flushState() {
		this.memCacheService.setValue<FilterCardState>(this.stateKey, {
			currentQueryStrings: this.currentQueryStrings,
			tableFilterChips: this.tableFilterChips,
		});
	}

	emit(force = false) {
		this.flushState();

		if (
			(force ||
				!this.isDefaultState() ||
				this.memCacheService.getValue("resetState") ||
				this.memCacheService.getValue("isLoadingPage")) &&
			!this.resetCheckbox
		) {
			this.filterQueryObject.emit(this.currentQueryStrings);
		}

		this.filtersActive = !this.isDefaultState();
		this.memCacheService.setValue("resetState", false);
		this.memCacheService.setValue("isLoadingPage", false);
	}

	getState() {
		return this.memCacheService.getValue<FilterCardState>(this.stateKey);
	}

	// Get array of user selected defect types
	handleDefectTypeFilterEvent(event: DefectTypeOption) {
		this.currentQueryStrings.queryParams.set(
			"statuses",
			event.value === "open" ? "open,pending" : "ignored,repaired",
		);
		this.emit();
	}

	// Get the user selected start and end dates from date filter and emit filter query
	public handleDateRangeFilterEvent(dateEvent: DateRangeFilterChange) {
		this.currentStartDate = dateEvent.beginDate;
		this.currentEndDate = dateEvent.endDate;

		this.currentQueryStrings.queryParams.set("startTime", dateEvent.beginDate.toISOString());
		this.currentQueryStrings.queryParams.delete("endTime");

		// only include the end time if we actually need it
		if (dateEvent.currentFilter === "custom") {
			this.currentQueryStrings.queryParams.set("endTime", dateEvent.endDate.toISOString());
		}

		this.emit();
	}

	// Get the user selected start and end dates from date filter and emit filter query
	public handleCustomDateRangeFilterEvent(dateEvent: CustomDateRangeFilterChange) {
		this.currentQueryStrings.customDateRangeFilter = dateEvent;
		if (!equal(this.getDefaultState().currentQueryStrings.customDateRangeFilter, dateEvent.dates))
			this.memCacheService.setValue("resetState", true);

		this.emit();
	}

	// Get an array of user selected inspections types
	handleInspectionTypeFilterEvent(inspectionTypes: Array<string>) {
		if (inspectionTypes.length) {
			this.currentQueryStrings.queryParams.set("inspectionTypeIds", inspectionTypes.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("inspectionTypeIds");
		}

		this.emit();
	}

	// Get an array of user selected inspections names
	handleInspectionNameFilterEvent(inspectionNames: Array<string>) {
		if (inspectionNames.length) {
			this.currentQueryStrings.queryParams.set("inspectionNameIds", inspectionNames.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("inspectionNameIds");
		}

		this.emit();
	}

	// Get an array of user selected asset ids
	public handleAssetIdFilterEvent(assetIds: Array<string>) {
		if (assetIds.length) {
			this.currentQueryStrings.queryParams.set("assetIds", assetIds.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("assetIds");
		}

		this.emit();
	}

	// Get an array of user selected asset types
	public handleAssetTypeFilterEvent(assetTypes: Array<string>) {
		if (assetTypes.length) {
			this.currentQueryStrings.queryParams.set("zoneLayoutIds", assetTypes.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("zoneLayoutIds");
		}

		this.emit();
	}

	// Get an array of user selected asset ids
	public handleHomeLocationFilterEvent(homeLocations: Array<string>) {
		if (homeLocations.length) {
			this.currentQueryStrings.queryParams.set("divisionIds", homeLocations.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("divisionIds");
		}

		this.emit();
	}

	// Get an array of user selected asset ids
	public handleInspectorFilterEvent(inspectors: Array<string>) {
		if (inspectors.length) {
			this.currentQueryStrings.queryParams.set("inspectorIds", inspectors.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("inspectorIds");
		}

		this.emit();
	}

	// Get array of user selected severity types
	public handleSeverityFilterEvent(severities: Array<string>) {
		if (severities.length) {
			this.currentQueryStrings.queryParams.set("severities", severities.join(","));
		} else {
			this.currentQueryStrings.queryParams.delete("severities");
		}

		this.emit();
	}

	public handleTableFilterChips(tableFilterChip: TableFilterChip) {
		this.tableFilterChips = [...this.tableFilterChips, tableFilterChip];
		this.getQueryStringsForTableFilterChips();

		this.emit();
	}

	public getQueryStringsForTableFilterChips() {
		const isBigQuery = this.tableFilterChips[0]?.tableName.startsWith("bigQuery") ?? false;

		const output = new URLSearchParams(
			this.tableFilterChips.length > 0
				? Object.fromEntries(
						[
							["assetId", "assets"],
							["inspectorId", "inspectors"],
							["divisionId", "divisions"],
						]
							.map(([id, tableName]) => [
								`${id}s`,
								this.tableFilterChips
									.filter(
										isBigQuery
											? chip =>
													chip.tableName ===
													`bigQuery${tableName.replace(/./, startLetter =>
														startLetter.toLocaleUpperCase(),
													)}`
											: chip => chip.tableName === tableName,
									)
									.map(chip => getIdFromRecord(chip.record, id))
									.join(","),
							])
							.filter(([, value]) => value !== ""),
				  )
				: undefined,
		);

		output.forEach((value, key) => this.currentQueryStrings.queryParams.set(key, value));
	}

	public handleChipRemove(chip: TableFilterChip) {
		this.tableFilterChips = this.tableFilterChips.filter((tableFilterChip: TableFilterChip) => {
			return !tableFilterChipsEqual(tableFilterChip, chip);
		});

		this.getQueryStringsForTableFilterChips();

		const recordName = `${chip.tableQueryName.slice(0, -1)}Id`;
		const queryName = `${recordName}s`;
		const query = this.currentQueryStrings.queryParams
			.get(queryName)
			?.split(",")
			?.filter(id => id !== getIdFromRecord(chip.record, recordName))
			?.join(",");

		if (query?.length > 0) {
			this.currentQueryStrings.queryParams.set(queryName, query);
		} else {
			if (this.tableFilterChips.length === 0) {
				this.getTableFilterComponent.clearInput();
			}
			this.currentQueryStrings.queryParams.delete(queryName);
		}

		this.emit(true); // force, since we may be returning to the default state
	}

	clearAllState() {
		// signal child controls
		this.memCacheService.clear(this.context);
		if (this.enabledFilters.searchBar) this.getTableFilterComponent.clearInput();
	}
}
