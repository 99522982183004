import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

// place for any options we might want to pass to service for building the overlay or the overlay component
// e.g. can later hold position options, config options, etc.
export class OverlayOptions {
	progress$: Observable<number>;
}

export const OVERLAY_OPTIONS: InjectionToken<OverlayOptions> = new InjectionToken<OverlayOptions>("OVERLAY_OPTIONS");
