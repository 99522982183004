import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { decode } from "html-entities";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { currentLocale } from "src/app/i18next";
import { getSupportedLng } from "src/app/i18next/i18nextOptions";
import { Efigs, LangDictGet, TranslatableString } from "src/app/models/openAPIAliases";
import { isDefined } from "src/utils/isDefined/isDefined";

@Injectable({ providedIn: "root" })
export class LanguageDictionaryService {
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;

	public languageDictionary: LangDictGet;
	public languagesSupported: string[];

	constructor() {
		this.languageDictionary$.pipe(filter(isDefined)).subscribe(langDict => {
			this.languageDictionary = langDict;
		});
	}

	/**
	 * Get the supported language code based on the current locale
	 *
	 */
	private supportedLangDictCode() {
		return getSupportedLng({
			language: currentLocale(),
			locales: ["en-us", "fr-fr", "it-it", "de-de", "es-es"] as ReadonlyArray<keyof Efigs>,
		});
	}

	/**
	 * Get a translation from the langDict base on the locale
	 *
	 * @param keyToTranslate Key to translate, it could be an UUID or a legacy string
	 */
	private getTranslation(keyToTranslate: string) {
		const translationMap = this.languageDictionary?.languageStrings?.[keyToTranslate];
		return decode(
			translationMap?.translations[this.supportedLangDictCode()] ?? translationMap?.translations["en-us"],
		);
	}

	/**
	 *	Get the translation based on the language key and label
	 *
	 * @param translatableString Translation object that contains the language key and label
	 * @returns The translation based on the language key and label
	 */
	public getTranslations({ languageKey, languageLabel }: TranslatableString) {
		const translation =
			languageKey === "ebb68be1-b49e-4f4b-9c72-4a760011c71b" ? languageLabel : this.getTranslation(languageKey);
		return translation;
	}
}
