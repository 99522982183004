import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Select, Store } from "@ngxs/store";
import { LogInfo, LogState } from "../log.state";
import { Observable, Subscription } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LoggerService implements OnDestroy {
	@Select(LogState.selectLogInfo) logInfo$: Observable<LogInfo>;
	public logInfoSubscription: Subscription;
	public logInfo: LogInfo;

	constructor(_http: HttpClient, public store: Store) {
		this.logInfoSubscription = this.logInfo$.subscribe(logInfo => {
			this.logInfo = logInfo;
		});
	}

	public log(message: string, logLevel: string = "DEBUG"): void {
		// log to the console
		let fullMessage = `${logLevel}: ${message}`;

		if (this.logInfo && this.logInfo.sessionId) {
			fullMessage = `${fullMessage}, sessionId: ${this.logInfo.sessionId}`;
		}

		// log to GCP
		const postData = {
			fullMessage: fullMessage,
			message: message,
			logLevel: logLevel,
			sessionId: null,
		};

		if (this.logInfo && this.logInfo.sessionId) {
			postData.sessionId = this.logInfo.sessionId;
		}

		// log to GA here? postData
	}

	ngOnDestroy() {
		this.logInfoSubscription.unsubscribe();
	}
}
