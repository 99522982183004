import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

export interface TabConfig<T> {
	id: string;
	label: string;
	selected: boolean;
	data: T;
}
@Component({
	selector: "app-tab-control",
	templateUrl: "./tab-control.component.html",
	styleUrls: ["./tab-control.component.scss"],
})
export class TabControlComponent implements OnInit, OnChanges {
	@Input()
	tabs: Array<TabConfig<unknown>> = [];

	@Output()
	selected = new EventEmitter<unknown>();

	tabSet: Array<TabConfig<unknown>> = [];

	ngOnInit(): void {
		this.tabSet = JSON.parse(JSON.stringify(this.tabs));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tabs !== undefined) {
			this.tabSet = JSON.parse(JSON.stringify(changes.tabs.currentValue));
		}
	}

	selectTab(selectedTab: TabConfig<unknown>) {
		this.tabSet = this.tabSet.map(tab => {
			return {
				...tab,
				selected: tab.label === selectedTab.label,
			};
		});

		this.selected.emit(selectedTab.data);
	}
}
