/* Map Spanish to 'es-ES' and French to 'fr-FR' */
export const GenericLanguageMap = [
	{
		languageAbbreviation: "en",
		useLanguage: "en-GB",
	},
	{
		languageAbbreviation: "es",
		useLanguage: "es-ES",
	},
	{
		languageAbbreviation: "fr",
		useLanguage: "fr-FR",
	},
	{
		languageAbbreviation: "it",
		useLanguage: "it-IT",
	},
	{
		languageAbbreviation: "de",
		useLanguage: "de-DE",
	},
];
