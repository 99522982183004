import { Injectable } from "@angular/core";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { Store } from "@ngxs/store";
import { SetPageInformation } from "../app.state";
import { translateAndFormat, translate } from "../i18next";

export class PageInformation {
	previousPageUrl: string;
	previousPageTitle: string;
	currentPageUrl: string;
}

@Injectable({
	providedIn: "root",
})
export class PreviousPageService {
	private previousUrl = "";
	private currentUrl = "";
	private pageInformation: PageInformation;

	constructor(private router: Router, private store: Store) {
		this.currentUrl = this.router.url;

		router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd && event.url !== "/server-error") {
				if (this.currentUrl.includes("#access_token")) {
					this.currentUrl = "/inspection-list";
				}

				this.previousUrl = this.getPreviousUrl(event.url);
				this.currentUrl = event.url;

				this.pageInformation = {
					previousPageUrl: this.previousUrl,
					previousPageTitle: this.getPageTitleFromUrl(this.previousUrl),
					currentPageUrl: this.currentUrl,
				};

				this.store.dispatch(new SetPageInformation(this.pageInformation));

				// Save previousUrl of each page into sessionStorage to make sure previousUrl is still kept when page is refreshed.
				sessionStorage.setItem(this.getKeyFromUrl(this.currentUrl), this.previousUrl);
			}
		});
	}

	private getPreviousUrl(eventUrl: string): string {
		// Returns previousUrl saved in sessionStorage when page is refreshed.
		if (!this.pageInformation) {
			return sessionStorage.getItem(this.getKeyFromUrl(eventUrl))
				? sessionStorage.getItem(this.getKeyFromUrl(eventUrl))
				: "";
		}

		// Returns the parent view when user navigates from "Inspections Details" page to "Asset Defect and Repair History" page.
		if (eventUrl.includes("asset") && this.currentUrl.includes("inspection/")) {
			if (this.previousUrl.includes("asset")) {
				return sessionStorage.getItem(this.getKeyFromUrl(eventUrl));
			}
			return this.previousUrl;
		}

		return this.currentUrl;
	}

	private getKeyFromUrl(url: string): string {
		if (url.includes("inspection/")) {
			return "inspection-details";
		}
		if (url.includes("defect")) {
			return "defects";
		}
		if (url.includes("missing-inspections")) {
			return "missing-inspections";
		}
		if (url.includes("asset")) {
			return "asset";
		}
		if (url.includes("resources-panel")) {
			return "resources-panel";
		}
		if (url.includes("account-admin")) {
			return "account-admin";
		}

		return "inspection-history";
	}

	public getPageTitleFromUrl(url: string): string {
		if (url.includes("inspection/")) {
			return translateAndFormat("inspection details", "title");
		}
		if (url.includes("defect")) {
			return translateAndFormat("defects", "title");
		}
		if (url.includes("missing-inspections")) {
			return translateAndFormat("missing inspections", "title");
		}
		if (url.includes("asset")) {
			return translate("asset defect and repair history");
		}
		if (url.includes("resources-panel")) {
			return translateAndFormat("Resources panel", "title");
		}
		if (url.includes("account-admin")) {
			return translateAndFormat("account admin", "title");
		}

		return translateAndFormat("inspection history", "title");
	}
}
