// for severity 63 or 127, show yellow triangle. for severity 255 show red square
export const getSeverityStatusIcon = (severity: number) => {
	switch (severity) {
		case 63:
			return "assets/open-minor-defect.svg";
		case 127:
			return "assets/open-minor-defect.svg";
		case 255:
			return "assets/open-major-defect.svg";
		default:
			return "assets/circle_check.svg";
	}
};
