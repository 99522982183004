import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, pluck } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class MemCacheService {
	private cache: Record<string, unknown> = {};
	private cacheSubject$ = new Subject<{ key: string; value: unknown }>();
	private clear$ = new Subject<string>();

	getValue<T>(key: string): T | null {
		return (this.cache[key] as T) || null;
	}

	setValue<T>(key: string, value: T) {
		this.cache[key] = value;
		this.cacheSubject$.next({ key, value });
	}

	clear(context: string) {
		this.clear$.next(context);
	}

	cleared$() {
		return this.clear$.asObservable();
	}

	// Returns an observable for the updated value of the given key
	cacheChanged$<T>(key: string): Observable<T> {
		return this.cacheSubject$.asObservable().pipe(
			filter(update => update.key === key),
			pluck("value"),
		) as Observable<T>;
	}
}
