import { translateAndFormat } from "src/app/i18next";

/**
 *  Get Severity text
 * @param severity Severity number
 * @returns Minor, Major, or empty string depending on the severity number
 */
export const getSeverity = (severity: number) => {
	switch (severity) {
		case 63:
			return translateAndFormat("minor", "capitalize");
		case 127:
			return translateAndFormat("minor", "capitalize");
		case 255:
			return translateAndFormat("major", "capitalize");
		default:
			return "";
	}
};
