import { Injectable } from "@angular/core";
import { InspectionGet, InspectionInfo } from "../../../models/openAPIAliases";
import { InspectionDetailStateModel } from "./inspection-detail.state";
import { InspectionDetailsViewModelService } from "src/app/services/inspection-details-view-model.service";

@Injectable({
	providedIn: "root",
})
export class InspectionStateService {
	constructor(public inspectionDetailsViewModelService: InspectionDetailsViewModelService) {}

	public populateState(inspectionJson: InspectionGet): InspectionDetailStateModel {
		// filter for action = end to get lat and long of end time
		const endInfo = inspectionJson.inspectionInfo
			? inspectionJson.inspectionInfo.filter((info: InspectionInfo) => info.action === "end").pop()
			: null;

		const lat = endInfo && endInfo.telemetry.latitude ? endInfo.telemetry.latitude : null;
		const long = endInfo && endInfo.telemetry.longitude ? endInfo.telemetry.longitude : null;

		const geolocation: string = lat && long ? `${lat}, ${long}` : null;

		return {
			staticInfo: this.inspectionDetailsViewModelService.getStaticInfo(inspectionJson),
			coordinates: geolocation,
			inspection: inspectionJson,
		} as InspectionDetailStateModel;
	}
}
