import { supportedImperialUnits } from "src/app/models/supported-imperial.units";
import { supportedMetricUnits } from "src/app/models/supported-metric.units";
import { LocaleService } from "src/app/services/locale/locale.service";

/**
 * Find the index of the unit in the supported units array
 * @param localeService Locale Service
 * @param units Single unit or Array of units
 * @returns returns 0 if an empty array or single entity unit is received to normalize it further,else returns the index of supported metric system
 */
export const findUnitIndex = (localeService: LocaleService, units: string | ReadonlyArray<string>) => {
	if (units.length > 1 && Array.isArray(units)) {
		const supportedUnits = localeService.usesMetricSystem() ? supportedMetricUnits : supportedImperialUnits;

		return units.findIndex(unit => supportedUnits.includes(unit));
	}
	return 0;
};
