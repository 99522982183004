import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { isDefined } from "src/utils/isDefined/isDefined";

@Component({
	selector: "app-info-dialog",
	templateUrl: "./info-dialog.component.html",
	styleUrls: ["./info-dialog.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InfoDialogComponent implements OnInit, OnDestroy {
	hasTCU = false;
	subscription = new Subscription();

	@Select(AppState.selectCompanyHasTCU) hasTCU$: Observable<boolean>;

	constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {}

	ngOnInit() {
		this.subscription.add(
			this.hasTCU$.pipe(filter(isDefined)).subscribe(hasTCU => {
				this.hasTCU = hasTCU;
			}),
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
