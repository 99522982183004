import { compressDays } from "./compress-days";
import { getCompressedDaysDesc } from "./get-compressed-days-desc";
import { getDatesInRange } from "./get-dates-in-range";

/**
 * Generate the Request body for the Custom Date Range Filter.
 *
 * @param startDate Initial Date of the range.
 * @param endDate Final Date of the range.
 * @param excludedWeekDays Days of the week to exclude from the range.
 * @param excludedDates Dates to exclude from the range.
 * @returns An array of objects with the different starts and ends dates.
 */
export function getRequestBody({
	endDate,
	excludedDates = [],
	excludedWeekDays = [],
	startDate,
}: {
	endDate?: Date;
	excludedDates?: ReadonlyArray<Date>;
	excludedWeekDays: ReadonlyArray<number>;
	startDate: Date;
}) {
	// create a map of all of the days to be included
	const includedDaysMap: Record<string, Date> = {};

	if (!endDate) {
		endDate = startDate;
	}

	// store the dates in the range into the map
	const datesInRange = getDatesInRange(startDate, endDate);

	for (const date of datesInRange) {
		includedDaysMap[date.toDateString()] = date;
	}

	// iterate across the days and remove those dates that are excluded
	const dateStrings = Object.keys(includedDaysMap);

	for (const dateStr of dateStrings) {
		if (
			excludedWeekDays.some(excludedDay => excludedDay === includedDaysMap[dateStr].getDay()) ||
			excludedDates.some(excludedDate => excludedDate.toDateString() === dateStr)
		) {
			delete includedDaysMap[dateStr];
		}
	}

	// compress the days and then create a small set of date range descriptors
	const compressedDays = compressDays(includedDaysMap);

	return getCompressedDaysDesc(compressedDays);
}
