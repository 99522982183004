<span
	#customDateRangeFilter
	class="date-range-filter"
>
	<button
		mat-button
		[matMenuTriggerFor]="customDateRangeMenu"
		class="filter-button filter-button-custom-date-range"
		[ngClass]="filterButtonClass"
		data-testid="filter-button"
		(menuOpened)="handleMenuOpen()"
	>
		<span
			class="filter-button-label filter-button-label-custom-date-range"
			[ngClass]="filterButtonLabelClass"
		>
			{{ "date range" | i18next: { format: "capitalize" } }}
		</span>
		<span class="filter-button-text date-range-button-text">{{ filterButtonText }}</span>
		<mat-icon>{{ buttonIconValue }}</mat-icon>
	</button>

	<mat-menu
		#customDateRangeMenu
		xPosition="after"
		class="custom-date-range-menu"
		(closed)="handleMenuClose()"
	>
		<div
			class="custom-filter-container"
			(click)="$event.stopPropagation()"
		>
			<div class="custom-filter-header">
				<h1 class="custom-filter-header-title">{{ "date range" | i18next: { format: "capitalize" } }}</h1>
				<p class="custom-filter-header-description">
					{{
						"please enter or select your date range, as well as any exceptions you may want to apply."
							| i18next: { format: "capitalize" }
					}}
				</p>
			</div>
			<div
				fxLayout="row"
				class="date-range-controls-box"
			>
				<div class="custom-date-rage-options">
					<div class="date-range-radio-group-container">
						<form novalidate>
							<mat-radio-group
								class="date-range-radio-group"
								[(ngModel)]="state.selectedDateRange"
								name="customDateRange"
								[attr.aria-label]="'date range' | i18next: { format: 'title' }"
								(change)="matRadioChange($event)"
								(click)="matRadioClick($event)"
								*ngIf="state"
							>
								<mat-radio-button
									id="date-range-filter-radio-button-today"
									class="date-range-radio-button"
									[value]="dateRange.today"
								>
									{{ "today" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-7"
									class="date-range-radio-button"
									[value]="dateRange.previous7"
								>
									{{ "previous 7 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-14"
									class="date-range-radio-button"
									[value]="dateRange.previous14"
								>
									{{ "previous 14 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-30"
									class="date-range-radio-button"
									[value]="dateRange.previous30"
								>
									{{ "previous 30 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-365"
									class="date-range-radio-button"
									[value]="dateRange.previous365"
								>
									{{ "previous 365 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-custom"
									class="date-range-radio-button"
									[value]="dateRange.custom"
								>
									{{ "custom" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
							</mat-radio-group>
						</form>
					</div>
				</div>
				<div class="divider"></div>
				<div
					class="custom-date-filter-inputs"
					[formGroup]="dateRangeForm"
				>
					<mat-form-field
						appearance="outline"
						class="date-picker"
					>
						<mat-label>{{ "start" | i18next: { format: "capitalize" } }}</mat-label>
						<input
							matInput
							[matDatepicker]="startDate"
							formControlName="startDate"
							(dateChange)="inlineRangeChange()"
							[max]="potentialDateInputEndDate"
							class="date-picker-input"
							(focus)="setControlFocus('startDate')"
							[errorStateMatcher]="matcher"
						/>
						<mat-hint>{{ dateFormatPattern }}</mat-hint>
						<mat-error *ngIf="dateRangeForm.get('startDate').errors?.startGreater">
							{{
								dateRangeForm.get("startDate").errors?.startGreater | i18next: { format: "capitalize" }
							}}
						</mat-error>
						<mat-error *ngIf="dateRangeForm.get('startDate').errors?.invalidFormat">
							{{
								dateRangeForm.get("startDate").errors?.invalidFormat | i18next: { format: "capitalize" }
							}}
						</mat-error>
						<mat-error *ngIf="dateRangeForm.get('startDate').errors?.dateGreater">
							{{ dateRangeForm.get("startDate").errors.dateGreater | i18next: { format: "capitalize" } }}
						</mat-error>
						<mat-datepicker-toggle
							matSuffix
							[for]="startDate"
							(click)="setControlFocus('startDate')"
						></mat-datepicker-toggle>
						<mat-datepicker
							#startDate
							[calendarHeaderComponent]="customHeader"
						></mat-datepicker>
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						class="date-picker"
					>
						<mat-label>{{ "end" | i18next: { format: "capitalize" } }}</mat-label>
						<input
							matInput
							[matDatepicker]="endDate"
							formControlName="endDate"
							(dateChange)="inlineRangeChange()"
							(focus)="setControlFocus('endDate')"
							[min]="potentialDateInputStartDate"
							[max]="maxDateInputEndDate"
							class="date-picker-input"
							[errorStateMatcher]="matcher"
						/>
						<mat-hint>{{ dateFormatPattern }}</mat-hint>
						<mat-error *ngIf="dateRangeForm.get('endDate').errors?.endLess">
							{{ dateRangeForm.get("endDate").errors?.endLess | i18next: { format: "capitalize" } }}
						</mat-error>
						<mat-error *ngIf="dateRangeForm.get('endDate').errors?.invalidFormat">
							{{ dateRangeForm.get("endDate").errors?.invalidFormat | i18next: { format: "capitalize" } }}
						</mat-error>
						<mat-error *ngIf="dateRangeForm.get('endDate').errors?.dateGreater">
							{{ dateRangeForm.get("endDate").errors.dateGreater | i18next: { format: "capitalize" } }}
						</mat-error>
						<mat-datepicker-toggle
							matSuffix
							[for]="endDate"
							(click)="setControlFocus('endDate')"
						></mat-datepicker-toggle>
						<mat-datepicker
							#endDate
							[calendarHeaderComponent]="customHeader"
						></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="custom-filter-week-exceptions">
				<h2 class="custom-filter-subtitle">{{ "days of the week" | i18next: { format: "capitalize" } }}</h2>
				<ng-container>
					<mat-checkbox
						*ngFor="
							let day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
						"
						[id]="day"
						class="days-of-week"
						(change)="matCheckboxChange($event)"
					>
						{{ day | i18next: { format: "capitalize" } }}
					</mat-checkbox>
				</ng-container>
			</div>
			<div class="error-message">{{ errorMessage }}</div>
			<div class="custom-filter-buttons-container evir-buttons-container">
				<app-action-button
					title="cancel"
					type="outlined-1"
					[style.marginRight.px]="16"
					(buttonClicked)="filterCancel()"
				></app-action-button>

				<app-action-button
					id="apply-custom-date-range"
					title="apply"
					(buttonClicked)="filterSubmit()"
					[disabled]="dateRangeForm.invalid"
				></app-action-button>
			</div>
		</div>
	</mat-menu>
</span>
