import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, filter, take, timeout } from "rxjs/operators";
import { AppState, ClearLoadTableRequest, NotificationFromDbLoader } from "src/app/app.state";
import { timeoutErrorConstants } from "src/app/constants/error-constants";
import { environment } from "src/environments/environment";
import { isDefined } from "src/utils/isDefined/isDefined";
import { GlobalApiCallsService } from "../global-api-calls.service";
import {
	FieldMeta,
	LoadTableRequest,
	LocalStoreService,
	TableName,
	TableRecord,
} from "../local-store/local-store.service";
import { NotificationLevel } from "../notification/notification.service";

export const NetworkErrorMessage = "Network connection error.";

const schemas: Readonly<Record<TableName, ReadonlyArray<FieldMeta>>> = {
	assets: [
		{ name: "assetId" },
		{ name: "assetCategory" },
		{ name: "assetJurisdiction" },
		{ name: "assetVersion" },
		{ name: "assetDivisions" },
		{ name: "assetName" },
		{ name: "assetType" },
	],
	bigQueryAssets: [{ name: "assetId" }, { name: "assetName" }],
	bigQueryDivisions: [{ name: "divisionId" }, { name: "divisionName" }],
	bigQueryInspectors: [{ name: "inspectorId" }, { name: "inspectorFirstName" }, { name: "inspectorLastName" }],
	divisions: [{ name: "divisionId" }, { name: "divisionName" }, { name: "divisionType" }],
	inspectors: [{ name: "inspectorId" }, { name: "inspectorFirstName" }, { name: "inspectorLastName" }],
} as const;

@Injectable({
	providedIn: "root",
})
export class DbTableLoaderService {
	@Select(AppState.getLoadTableRequest) tableToLoad$: Observable<LoadTableRequest>;
	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectUserDivisions) userDivisions$: Observable<Array<IDivision>>;
	@Select(AppState.selectUserHasAllDivisions) userHasAllDivisions$: Observable<boolean>;

	requests$ = new BehaviorSubject(false);
	requests: ReadonlyArray<LoadTableRequest> = [];

	companyId: string;
	divisionIds: ReadonlyArray<string>;
	hasAllDivisions: boolean;
	endPointEVIR: string;
	endPointCompanies: string;

	constructor(
		private globalApiCallsService: GlobalApiCallsService,
		private localStoreService: LocalStoreService,
		private store: Store,
	) {
		// initialize global db args
		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(selectedCompanyId => {
			this.init(selectedCompanyId);
		});

		// accumulate requests
		this.tableToLoad$.pipe(filter(isDefined)).subscribe((loadTableRequest: LoadTableRequest) => {
			if (typeof loadTableRequest.tableName === "string") {
				// otherwise in the init state
				this.store.dispatch(new ClearLoadTableRequest());
				this.requests = [
					...this.requests.filter(({ tableName }) => tableName !== loadTableRequest.tableName),
					loadTableRequest,
				];
				this.requests$.next(true);
			}
		});
	}

	init(companyId: string) {
		this.companyId = companyId;
		this.endPointEVIR = environment.environmentConstants.APP_ENDPOINT_EVIR;
		this.endPointCompanies = environment.environmentConstants.APP_ENDPOINT_CORE;

		// now that we have completed initialization, we can start processing requests
		this.requests$
			.pipe(filter(isDefined))
			.subscribe(() => this.requests.forEach(loadTableRequest => this.loadTable(loadTableRequest)));

		this.userHasAllDivisions$.pipe(filter(isDefined)).subscribe(hasAll => {
			this.hasAllDivisions = hasAll;
			this.userDivisions$.pipe(filter(isDefined)).subscribe(divisions => {
				this.divisionIds = divisions.map(({ id }) => id);
			});
		});
	}

	loadTable(loadTableRequest: LoadTableRequest) {
		if (loadTableRequest.tableName) {
			this.globalApiCallsService[TableName[loadTableRequest.tableName]]({
				companyId: this.companyId,
				divisionIds: this.divisionIds,
				hasAllDivisions: this.hasAllDivisions,
				endpointEVIR: this.endPointEVIR,
				endpointCompanies: this.endPointCompanies,
			})
				.pipe(
					timeout(timeoutErrorConstants.TIMEOUT_MS),
					catchError(error => {
						this.store.dispatch(
							new NotificationFromDbLoader({
								message: NetworkErrorMessage,
								level: NotificationLevel.Error,
							}),
						);
						console.error(error);
						return of();
					}),
					take(1),
				)
				.subscribe((dBrecords: Array<TableRecord>) => this.receiveDbRecords(loadTableRequest, dBrecords));
		}
	}

	receiveDbRecords(loadTableRequest: LoadTableRequest, dBrecords: Array<TableRecord>) {
		let context = this.localStoreService.startUpdate(
			loadTableRequest.tableName,
			schemas[loadTableRequest.tableName],
		);

		for (const dbRecord of dBrecords) {
			// assemble a local-store formatted record from the raw db record
			const record: TableRecord = { fields: [] };

			for (const fieldName of schemas[loadTableRequest.tableName].map(
				fieldDescription => fieldDescription.name,
			)) {
				record.fields.push({
					name: fieldName,
					value: dbRecord[fieldName],
				});
			}

			// update the local store, once the record has been assembled
			context = this.localStoreService.continueUpdate(context, [record]);
		}

		this.localStoreService.completeUpdate(context);
	}
}
