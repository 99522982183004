import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe: SplitAtFirstDelimiterPipe
 *
 * This pipe splits a given string into two parts at the first occurrence of a specified delimiter.
 * It is primarily used to separate a string into a prefix and the remaining part based on the delimiter.
 *
 * @example
 * Usage in template:
 * {{ 'example(text)' | splitAtFirstDelimiter }}
 * This will split the string 'example (text)' into ['example', '(text)'].
 *
 * @remarks
 * If the delimiter is not found in the string, the entire string is returned as the first element of the array.
 *
 * @param inputString The string to be split.
 * @returns An array of two strings: the first part of the string before the delimiter, and the remaining part of the string starting from the delimiter.
 * If the delimiter is not found, the entire string is returned as the first element of the array, and the second element is an empty string.
 */
@Pipe({
	name: "splitAtFirstDelimiter",
})
export class SplitAtFirstDelimiterPipe implements PipeTransform {
	transform(inputString: string): string[] {
		const index = inputString.indexOf("(");

		if (index === -1) {
			return [inputString.trim()];
		}

		const firstPart = inputString.substring(0, index).trim();
		const remainingPart = inputString.substring(index).trim();

		return [firstPart, remainingPart];
	}
}
