<div
	class="container get-table-filter"
	(click)="$event.stopPropagation()"
>
	<div class="search-input-wrapper">
		<app-search-input
			(inputChanged)="onSearchInputChanged($event)"
			[active]="showOverlayContainer"
			(hasFocus)="onHasFocus()"
			[resetInput]="resetInput"
			[placeholder]="placeholder"
		></app-search-input>
		<!-- trigger for menu -->
		<button
			class="open-btn"
			cdkOverlayOrigin
			#trigger="cdkOverlayOrigin"
		></button>
	</div>

	<!-- overlay container that contains tabs and results -->
	<ng-template
		cdkConnectedOverlay
		[cdkConnectedOverlayOrigin]="trigger"
		[cdkConnectedOverlayOpen]="showOverlayContainer"
	>
		<div
			class="tabs-results mat-elevation-z4"
			(click)="$event.stopPropagation()"
		>
			<app-simple-tabs
				(clicked)="onTabClicked($event)"
				[tabs]="tabs"
			></app-simple-tabs>

			<div class="matches">
				<!-- search status -->
				<div
					*ngIf="searchState === State.Searching"
					class="search-status"
				>
					{{ "searching" | i18next: { format: "capitalize" } }}...
				</div>
				<div
					*ngIf="searchState === State.SearchCompleted && viewRecords.length === 0"
					class="search-status no-results"
					data-testid="no-matches"
				>
					{{ "no results found" | i18next: { format: "capitalize" } }}
				</div>

				<!-- matching records -->
				<ng-container *ngIf="searchState === State.SearchCompleted">
					<div
						class="record"
						*ngFor="let viewRecord of viewRecords"
						(click)="onClickHighlightedText(viewRecord)"
						[ngClass]="{ 'record-disabled': viewRecord.selected }"
					>
						<app-highlighted-text
							[pattern]="pattern"
							[text]="viewRecord.displayValue"
							[selected]="viewRecord.selected"
							*ngIf="viewRecord.record"
						></app-highlighted-text>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-template>
</div>
