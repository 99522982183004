import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { currentLocale, translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { InspectionDefectViewModel } from "src/app/models/inspection-detail-view-models.models";
import { AggregateModalData } from "src/app/models/modal-data.models";
import { Media } from "src/app/models/openAPIAliases";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";
import { InspectionType } from "../../../models/inspection-type.models";
import { OpenDefectTableViewModel } from "../../../models/open-defect-table.models";
import { SeverityIconService } from "../../../services/severity-icon.service";
import { ClosedDefects } from "../asset-details.component";
import { AssetState, SetOpenWithComments } from "../state/asset-details.state";
import { ImageCarouselDialogComponent } from "./../../../components/image-carousel-dialog/image-carousel-dialog.component";

@Component({
	selector: "app-open-pending",
	templateUrl: "./open-pending.component.html",
	styleUrls: ["./open-pending.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [
		// expandable rows example: https://material.angular.io/components/table/examples
		// added void: https://github.com/angular/components/issues/11990
		trigger("detailExpand", [
			state("collapsed, void", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
			transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class OpenPendingComponent implements OnInit, OnDestroy {
	@Input() currentOpenDefectQuery = new URLSearchParams({
		page: "1",
		perPage: "5",
	});
	@Input() totalOpenDefects = 0;
	@Output() emitClosedDefects = new EventEmitter();
	@Output() openDefectPaginate = new EventEmitter<URLSearchParams>();

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AssetState.getOpenWithComments) openWithComments$: Observable<OpenDefectTableViewModel[]>;

	canAssignMechanic = false;
	companyId: string;
	config = {
		initialSelection: [],
		allowMultiSelect: true,
	};
	dataSource: MatTableDataSource<OpenDefectTableViewModel>;
	displayedColumns: string[] = [
		"select",
		"severity",
		"inspectionType",
		"zoneLabel",
		"componentLabel",
		"conditionLabel",
		"lastNoted",
		"firstNoted",
		"defectStatus",
		"chevron",
	];
	expandedElement: OpenDefectTableViewModel | null;
	noDefects = false;
	noDefectsString: string = translateAndFormat("there are no open defects to display", "capitalize");
	onDestroy$ = new Subject<void>();
	openWithComments: OpenDefectTableViewModel[] = [];
	pageIndex = 0;
	pageSize = 5;
	pageSizeOptions = [5, 10, 20];
	rotateState: string[] = [];
	selectionModel = new SelectionModel(this.config.allowMultiSelect, this.config.initialSelection); // Checkbox column

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		private store: Store,
		private severityIconService: SeverityIconService,
		private permissionsService: PermissionsService,
		public dialog: MatDialog,
	) {}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	ngOnInit() {
		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(companyId => {
			this.companyId = companyId;
		});

		this.permissionsService
			.getPermissions()
			.pipe(filter(isDefined))
			.subscribe(permissions => {
				this.canAssignMechanic = (permissions as { evir })?.evir?.mechanic?.assign !== undefined;
			});

		this.openWithComments$.pipe(filter(isDefined)).subscribe((openWithComments: OpenDefectTableViewModel[]) => {
			this.dataSource = new MatTableDataSource(openWithComments);
			this.openWithComments = openWithComments;

			// initialize all chevrons in view to down since no expansion should happen on init
			this.rotateState = new Array(this.pageSize).fill("down");

			if (openWithComments.length === 0) {
				this.noDefects = true;
			}
		});

		if (this.dataSource) {
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		}
	}

	// for aria-labels
	public checkboxLabel(row: OpenDefectTableViewModel): string {
		return `${this.selectionModel.isSelected(row) ? "deselect" : "select"} row ${row.zoneLabel} ${
			row.componentLabel
		} ${row.conditionLabel}`;
	}

	public getDividerClass(row: OpenDefectTableViewModel, i: number): string {
		if (this.selectionModel.isSelected(row) && this.rotateState[i] === "down") {
			return "highlight";
		}

		if (this.selectionModel.isSelected(row) && this.rotateState[i] === "up") {
			return "white-divider-highlight";
		}
	}

	public getSeverityIcon(severity: number): string {
		return this.severityIconService.getSeverityIcon(severity);
	}

	// updates the table by updating asset state
	public updateAssetState(repair: AggregateModalData): void {
		// update open table
		repair.oldData.forEach((oldDefect: OpenDefectTableViewModel) => {
			// if user clicked pending button and the defect's previous state was also pending, push the new comment to state
			if (repair.repairType === "Pending") {
				if (oldDefect.defectStatus === "Pending") {
					this.openWithComments.forEach((defect: OpenDefectTableViewModel) => {
						if (defect.defectId === oldDefect.defectId) {
							const newRepairs = [];

							newRepairs.unshift({
								comment: repair.newData.repairForm.comments,
								created: newDate(repair.newData.timestamp),
							});

							defect.repairs = [...newRepairs, ...defect.repairs];
						}
					});

					// if user clicked pending button and the defect's previous state was open, create new comments array and push to state
				} else {
					this.openWithComments.forEach((defect: OpenDefectTableViewModel) => {
						if (defect.defectId === oldDefect.defectId) {
							defect.repairs = [];

							defect.repairs.unshift({
								comment: repair.newData.repairForm.comments,
								created: newDate(repair.newData.timestamp),
							});
							defect.defectStatus = translateAndFormat("pending", "capitalize");
						}
					});
				}

				// if user clicked ignored/repaired buttons remove the defect from open defects state
			} else {
				this.openWithComments = this.openWithComments.filter(
					(defect: OpenDefectTableViewModel) => defect.defectId !== oldDefect.defectId,
				);
			}
		});

		// if the user closed the defect, emit all defects closed with resolution and comments to parent to send to sibling
		if (repair.repairType === "Repair not needed" || repair.repairType === "Repaired") {
			const closedDefect: ClosedDefects = {
				closedDefects: repair.oldData,
				comment: repair.newData.repairForm.comments || null,
				configId: repair.configId,
				resolution: repair.newData.repairForm.resolution
					? repair.newData.repairForm.resolution.resolutionValues[currentLocale()] ||
					  repair.newData.repairForm.resolution.resolutionValues["en-us"]
					: null,
			};

			this.emitClosedDefects.emit(closedDefect);
			this.resetAnimations();
		}

		if (this.openWithComments.length > 0) {
			this.store.dispatch(new SetOpenWithComments(this.openWithComments));
		} else {
			this.store.dispatch(new SetOpenWithComments([]));
			this.noDefects = true;
		}

		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.selectionModel.clear();
	}

	// when user clicks on chevron, show or hide hidden notes
	public showExpansion(element: OpenDefectTableViewModel): void {
		this.expandedElement = this.expandedElement === element ? null : element;
	}

	// trigger slide in slide out animation and set class to hide or show
	public expansionAnimation(element: OpenDefectTableViewModel, expandedElement: OpenDefectTableViewModel): string {
		return element === expandedElement ? "expanded" : "collapsed";
	}

	// rotate the chevron that corresponds with the index of clicked row
	// e.g. rotateState = ['down', 'down'], user clicks second row, rotateState now ['down', 'up']
	// triggers animation for clicked chevron
	public rotate(i: number): void {
		this.rotateState = this.rotateState.map((rotation: string, k: number) => {
			if (k === i) {
				return rotation === "down" ? "up" : "down";
			} else {
				return "down";
			}
		});
	}

	updateTable(currentQueryStrings: URLSearchParams): void {
		this.selectionModel.clear();

		this.openDefectPaginate.emit(currentQueryStrings);
	}

	onPageIndexChange(pageIndex: number) {
		this.pageIndex = pageIndex;
	}

	onPageSizeChange(pageSize: number) {
		this.pageSize = pageSize;
	}

	onQueryChange(query: URLSearchParams): void {
		this.updateTable(new URLSearchParams(Object.fromEntries([...this.currentOpenDefectQuery, ...query])));

		this.resetAnimations();
	}

	// when user sorts, repairs, or pages, close any expanded element and reset all chevrons to down position
	public resetAnimations(): void {
		this.expandedElement = null;
		this.rotateState = new Array(this.pageSize).fill("down");
		this.selectionModel.clear();
	}

	public handleImageClick(defect: InspectionDefectViewModel, i: number) {
		this.openImageCarouselDialog(defect.photos, i, translateAndFormat("defect photo", "title"));
	}

	public openImageCarouselDialog(media: Array<Media>, i: number, title: string): void {
		this.dialog.open(ImageCarouselDialogComponent, {
			data: {
				title,
				media,
				imageIndex: i,
			},
		});
	}

	public formatDate(date: Date) {
		return formatDate(date, "P");
	}

	isRejectedDefect(row: OpenDefectTableViewModel) {
		return row.inspectionType === InspectionType.rejected;
	}
}
