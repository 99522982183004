import { addDays, isSameDay } from "date-fns";

export interface DayEntry {
	date: Date;
	numberOfDays: number;
}

/**
 * Given a map of string => Date, where the string key is Date.toDateString(), serialize the
 * map into a compressed array where each array entry represents a contiguous sequence of days.
 *
 * @param daysMap Map of days
 * @returns An array of day compressions
 */
export function compressDays(daysMap: Record<string, Date>): DayEntry[] {
	// Convert map to array and sort
	const sortedDates = Object.values(daysMap).sort((a, b) => a.getTime() - b.getTime());

	const compressedEntries: DayEntry[] = [];
	let currentEntry: DayEntry | null = null;

	for (const date of sortedDates) {
		if (currentEntry && isSameDay(addDays(currentEntry.date, currentEntry.numberOfDays), date)) {
			currentEntry.numberOfDays++;
		} else {
			if (currentEntry) {
				compressedEntries.push(currentEntry);
			}
			currentEntry = { date: new Date(date), numberOfDays: 1 };
		}
	}

	// Add the last entry if it exists
	if (currentEntry) {
		compressedEntries.push(currentEntry);
	}

	return compressedEntries;
}
