import { commonUnits } from "./common-units";

export const supportedMetricUnits = [
	"m2",
	"m3",
	"cm",
	"m",
	"km",
	"ml",
	"l",
	"kph",
	"kpl",
	"kml",
	"pa",
	"kPa",
	"C",
	"g",
	"kg",
	"t",
	"bar",
	...commonUnits,
];
