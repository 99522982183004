import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { InspectionListPrintViewModel } from "src/app/models/inspection-print-view.models";
import { isDefined } from "src/utils/isDefined/isDefined";
import { InspectionHistoryState } from "../inspection-history/state/inspection-history.state";

@Component({
	selector: "app-inspections-print-view",
	templateUrl: "./inspections-print-view.component.html",
	styleUrls: ["./inspections-print-view.component.scss"],
})
export class InspectionsPrintViewComponent implements OnInit {
	companyId: string;
	previousPageTitle = "Inspection List";
	previousPageUrl = "/inspection-list";
	viewModels: InspectionListPrintViewModel[] = [];

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(InspectionHistoryState.getInspectionsPrintViewViewModels)
	inspectionsPrintViewViewModels$: Observable<InspectionListPrintViewModel[]>;

	constructor(private router: Router) {}

	ngOnInit() {
		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(selectedCompanyId => {
			if (this.companyId) {
				this.router.navigate(["/inspection-list"]);
			} else {
				this.companyId = selectedCompanyId;
				this.inspectionsPrintViewViewModels$.subscribe(viewModels => {
					this.viewModels = viewModels;
				});
			}
		});
	}

	clickedOnBack() {
		this.router.navigate([this.previousPageUrl]);
	}
}
