import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class SeverityIconService {
	public getSeverityIcon(severity: number): string {
		switch (severity) {
			case 63:
				return "assets/severity-minor.svg";
			case 127:
				return "assets/severity-minor.svg";
			case 255:
				return "assets/severity-major.svg";
			default:
				return "assets/severity-none.svg";
		}
	}
}
