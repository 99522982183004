import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AppState, ClearNotification } from "src/app/app.state";

export enum NotificationLevel {
	Error = "error",
}
const notificationClass = {
	[NotificationLevel.Error]: "app-snackbar-error",
};
export interface UserNotification {
	message: string;
	level: NotificationLevel;
}

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	@Select(AppState.getNotification) notification$: Observable<UserNotification>;

	constructor(public snackBar: MatSnackBar, private store: Store) {
		const comparer = (previousNotification: UserNotification, currentNotification: UserNotification): boolean =>
			previousNotification.message === currentNotification.message &&
			previousNotification.level === currentNotification.level;

		this.notification$.pipe(distinctUntilChanged(comparer)).subscribe((notification: UserNotification) => {
			this.handleNotification(notification);
		});
	}

	handleNotification(notification: UserNotification) {
		if (notification && notification.message && notification.level) {
			// otherwise in the init state
			this.store.dispatch(new ClearNotification());

			this.snackBar.open(notification.message, "", {
				duration: 4000,
				verticalPosition: "top",
				panelClass: notificationClass[notification.level],
			});
		}
	}
}
