import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
	selector: "app-action-button",
	templateUrl: "./action-button.component.html",
	styleUrls: ["./action-button.component.scss"],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class ActionButtonComponent {
	@Input() title: string;
	@Input() type: "filled" | "outlined" | "outlined-1" | "outlined-2" = "filled";
	@Input() disabled = false;
	@Input() format: "capitalize" | "uppercase" | "title" | "camelCase" = "capitalize";
	@Input() customStyle: string;

	@Output() buttonClicked = new EventEmitter();

	onButtonClick() {
		this.buttonClicked.emit();
	}
}
