<div class="evir-resources-panel">
	<div class="header-row">
		<h1 class="heading-1 page-title">
			{{ "EVIR Settings" | i18next: { format: "capitalize" } }}
		</h1>
	</div>

	<div class="panel-main-row">
		<!-- Default asset type  -->
		<div class="panel-col">
			<mat-card>
				<div class="layout asset-layout">
					<div class="heading-4 card-title">
						{{ "default asset type for EVIR Mobile" | i18next: { format: "capitalize" } }}
					</div>
					<div class="setting-description">
						{{
							"please choose which asset type should be the default for all future inspections"
								| i18next: { format: "capitalize" }
						}}
					</div>

					<div class="content">
						<mat-radio-group
							[value]="selectedAssetType"
							(change)="handleAssetTypeChange($event)"
							class="radio-group"
						>
							<mat-radio-button
								*ngFor="let type of assetTypes"
								[value]="type.value"
								class="radio-btns"
							>
								{{ type.label }}
							</mat-radio-button>
						</mat-radio-group>

						<div [innerHTML]="assetTypeImage"></div>
					</div>
				</div>
			</mat-card>
		</div>

		<!-- Inspection and image retention-->
		<div
			class="panel-col"
			*ngIf="showImageRetention"
		>
			<mat-card>
				<div class="layout">
					<div class="heading-4 card-title">
						{{ "inspection and image retention" | i18next: { format: "capitalize" } }}
					</div>
					<div class="setting-description">
						{{
							"amount of time inspections and images will be kept on file."
								| i18next: { format: "capitalize" }
						}}
					</div>
					<!-- select -->
					<div class="outer">
						<mat-form-field appearance="outline">
							<mat-label>
								{{ "retain for number of years" | i18next: { format: "capitalize" } }}
							</mat-label>
							<mat-select
								#retentionTimeChangeSelect
								(selectionChange)="handleRetentionTimeChange($event)"
								[value]="selectedValue"
							>
								<mat-option
									*ngFor="let option of options"
									[value]="option"
								>
									{{ option }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>
