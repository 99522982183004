import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SearchTable } from "../get-table-filter/get-table-filter.component";

export interface Tab {
	id: string; // must be unique
	defaultActive: boolean;
	label: string;
	data: SearchTable;
}

@Component({
	selector: "app-simple-tabs",
	templateUrl: "./simple-tabs.component.html",
	styleUrls: ["./simple-tabs.component.scss"],
})
export class SimpleTabsComponent implements OnInit {
	@Input()
	tabs: Array<Tab> = [];

	@Output()
	clicked = new EventEmitter<Tab>();

	activeTab: Tab;

	ngOnInit() {
		this.activeTab = this.tabs.find(tab => tab.defaultActive);
	}

	onClickTab(tab: Tab) {
		event.stopPropagation();

		this.activeTab = tab;

		this.clicked.emit(tab);
	}
}
