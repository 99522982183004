import { Component, OnInit } from "@angular/core";
import { ErrorState, HttpErrorModel, NavErrorState } from "./state/error.state";
import { AppState } from "src/app/app.state";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { PageInformation } from "../../services/previous-page.service";
import { distinctUntilChanged } from "rxjs/operators";
import { api } from "src/app/constants/support-constants";
import {
	httpPageNotFoundConstants,
	httpServerErrorConstants,
	httpMaintenanceConstants,
	noDivisionsConstants,
	noApplicationErrorConstants,
	noUserOrProfilesErrorConstants,
	inactiveUserErrorConstants,
	clientUnauthorizedErrorConstants,
	clientPageNotFoundConstants,
	defaultErrorConstants,
	navErrorConstants,
} from "../../constants/error-constants";
import { currentLocale, translateAndFormat } from "src/app/i18next";
import { environment } from "src/environments/environment";

export class HttpErrorTechnicalInformation {
	httpInformationLabel: string;
	httpInformationContent: string | number;
}

@Component({
	selector: "app-errors",
	templateUrl: "./errors.component.html",
	styleUrls: ["./errors.component.scss"],
})
export class ErrorsComponent implements OnInit {
	public pageInformation: PageInformation = null;

	// this data changes based on error status
	public errorHeader: string = null;
	public errorSubHeader: string = null;
	public contactSubheader: string = null;
	public actionButtonText: string = null;
	public actionButtonLink: string | void = null;

	public supportPhoneText: string = api.supportConstants.SUPPORT_PHONE_TEXT;
	public supportPhoneNumber: Record<string, string> =
		environment.brand["support-phone-number"][currentLocale() ?? "default"] ??
		environment.brand["support-phone-number"].default;
	public supportEmailText: string = api.supportConstants.SUPPORT_EMAIL_TEXT;
	public supportEmail: string = environment.brand["support-email"];

	public isHttpError = false;
	public supportArray: HttpErrorTechnicalInformation[] = [];

	@Select(ErrorState.getHttpErrors) httpErrors$: Observable<HttpErrorModel[]>;
	@Select(ErrorState.getNavErrorState) navState$: Observable<NavErrorState>;
	@Select(AppState.selectPageInformation) pageInformation$: Observable<PageInformation>;

	constructor(public router: Router) {}

	ngOnInit() {
		this.navState$.subscribe(navState => {
			if (navState.http) {
				this.isHttpError = true;

				this.httpErrors$
					.pipe(
						distinctUntilChanged(
							(previous, current) => JSON.stringify(previous) === JSON.stringify(current),
						),
					)
					.subscribe(httpErrors => {
						if (httpErrors) {
							httpErrors.forEach(error => {
								for (const errorInformation in error) {
									if (error[errorInformation] && error[errorInformation] !== "") {
										this.supportArray.push({
											httpInformationLabel:
												errorInformation.charAt(0).toUpperCase() + errorInformation.slice(1),
											httpInformationContent: JSON.stringify(error[errorInformation]),
										});
									}
								}
							});
						}
					});

				this.pageInformation$.subscribe((pageInformation: PageInformation) => {
					if (pageInformation) {
						this.pageInformation = pageInformation;
					}
				});

				switch (navState.type) {
					case navErrorConstants.NAV_ERROR_NOT_FOUND:
						this.buildPage(httpPageNotFoundConstants);
						break;
					case navErrorConstants.NAV_ERROR_SERVER_ERROR:
						this.buildPage(httpServerErrorConstants);
						break;
					case navErrorConstants.NAV_ERROR_MAINTENANCE:
						this.buildPage(httpMaintenanceConstants);
						break;
					case navErrorConstants.NAV_ERROR_NO_DIVISIONS:
						this.buildPage(noDivisionsConstants);
						break;
					default:
						this.buildPage(defaultErrorConstants);
						break;
				}
			} else {
				switch (navState.type) {
					case navErrorConstants.NAV_ERROR_NOT_FOUND:
						this.buildPage(clientPageNotFoundConstants);
						break;
					case navErrorConstants.NAV_ERROR_NO_USER:
						this.buildPage(noUserOrProfilesErrorConstants);
						break;
					case navErrorConstants.NAV_ERROR_NO_ACCESS:
						this.buildPage(clientUnauthorizedErrorConstants);
						break;
					case navErrorConstants.NAV_ERROR_NO_APPLICATION:
						this.buildPage(noApplicationErrorConstants);
						break;
					case navErrorConstants.NAV_ERROR_INACTIVE:
						this.buildPage(inactiveUserErrorConstants);
						break;
					default:
						this.buildPage(defaultErrorConstants);
				}
			}
		});
	}

	buildPage(constants) {
		this.errorHeader = constants.ERROR_HEADER;
		this.errorSubHeader = constants.ERROR_SUBHEADER;
		this.contactSubheader = constants.ERROR_CONTACT_US ? constants.ERROR_CONTACT_US : null;
		this.actionButtonText = constants.ERROR_ACTION_BUTTON_TEXT;

		if (constants.ERROR_ACTION_BUTTON_LINK) {
			this.actionButtonLink = constants.ERROR_ACTION_BUTTON_LINK;
		} else {
			if (this.actionButtonText === translateAndFormat("try again", "title")) {
				this.actionButtonLink = this.pageInformation ? this.pageInformation.currentPageUrl : "/inspection-list";
			}
		}
	}

	get mailTo() {
		return `mailto:${this.supportEmail}`;
	}

	routeHandler() {
		this.router.navigate([this.actionButtonLink]);
	}

	logoutHandler() {}
}
