import { translateAndFormat } from "src/app/i18next";

/**
 * Get Repair Status Title
 * @param repairStatus Repair status value
 * @returns Open, Pending or Closed depending on the repair status
 */
export const getRepairStatusTitle = (repairStatus: string) => {
	switch (repairStatus) {
		case "pending":
			return translateAndFormat("pending", "uppercase");
		case "ignored":
			return translateAndFormat("closed", "uppercase");
		case "repaired":
			return translateAndFormat("closed", "uppercase");
		default:
			return translateAndFormat("open", "uppercase");
	}
};
