import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { currentLocale } from "src/app/i18next";

export const CUSTOM_FORMATS = {
	parse: {
		dateInput: "MM/DD/YYYY",
	},
	display: {
		dateInput: "MM/DD/YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMM YYYY",
	},
};

@Injectable()
export class DateRangeAdapter extends NativeDateAdapter {
	monthNames = {
		"en-us": [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		],
		"es-es": [
			"Enero de",
			"Febrero de",
			"Marzo de",
			"Abril de",
			"Mayo de",
			"Junio de",
			"Julio de",
			"Agosto de",
			"Septiembre de",
			"Octubre de",
			"Noviembre de",
			"Diciembre de",
		],
		"en-gb": [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		],
		"fr-fr": [
			"Janvier",
			"Février",
			"Mars",
			"Avril",
			"Mai",
			"Juin",
			"Juillet",
			"Août",
			"Septembre",
			"Octobre",
			"Novembre",
			"Décembre",
		],
		"it-it": [
			"Gennaio",
			"Febbraio",
			"Marzo",
			"Aprile",
			"Maggio",
			"Giugno",
			"Luglio",
			"Agosto",
			"Settembre",
			"Ottobre",
			"Novembre",
			"Dicembre",
		],
		"de-de": [
			"Januar",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember",
		],
	};

	monthName = this.monthNames[currentLocale()];

	/**
	 * Format a given date into a string based on the provided format.
	 *
	 * @param date The date to be formatted.
	 * @param displayFormat The format to display the date as.
	 * @returns A string representing the formatted date.
	 */
	override format(date: Date, displayFormat: string): string {
		const day = this.padSingleDigit(date.getDate());
		const month = this.padSingleDigit(date.getMonth() + 1);
		const year = date.getFullYear();

		const formatMap = {
			"DD.MM.YYYY": `${day}.${month}.${year}`,
			"DD/MM/YYYY": `${day}/${month}/${year}`,
			"MM/DD/YYYY": `${month}/${day}/${year}`,
			"MMM YYYY": `${this.monthName[date.getMonth()]} ${year}`,
		};

		return formatMap[displayFormat] || date.toDateString();
	}

	/**
	 * Pad a given number with a leading zero if it is a single digit.
	 *
	 * @param number The number to pad.
	 * @returns The padded number as a string.
	 */
	private padSingleDigit(number: number) {
		return number <= 9 ? `0${number}` : number.toString();
	}
}
