import { TableRecord } from "src/app/services/local-store/local-store.service";

export function getDisplayValue(record: TableRecord, displayFieldNames: ReadonlyArray<string>): string {
	const displayFieldSet = new Set(displayFieldNames);

	return record.fields
		.reduce((displayValues, field) => {
			if (displayFieldSet.has(field.name)) {
				displayValues.push(field.value);
			}
			return displayValues;
		}, [])
		.join(" ");
}
