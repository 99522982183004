import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import i18next from "i18next";
import { translateAndFormat } from "src/app/i18next";

const customPaginatorIntl = new MatPaginatorIntl();

// This event modifies the labels and text displayed in the paginator
// see also: https://github.com/angular/components/blob/master/src/material/paginator/paginator-intl.ts
// see also: https://github.com/angular/components/issues/5573
i18next.on("initialized", () =>
	Object.assign(customPaginatorIntl, {
		itemsPerPageLabel: `${translateAndFormat("rows per page", "capitalize")}:`,
		previousPageLabel: translateAndFormat("previous page", "capitalize"),
		nextPageLabel: translateAndFormat("next page", "capitalize"),
	}),
);

@Component({
	selector: "app-paginator",
	templateUrl: "./paginator.component.html",
	styleUrls: ["./paginator.component.scss"],
	providers: [{ provide: MatPaginatorIntl, useValue: customPaginatorIntl }],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class PaginatorComponent implements OnInit {
	@Input() length: number;
	@Input() pageIndex: number;
	@Input() pageSize: number;
	@Input() pageSizeOptions: ReadonlyArray<number>;
	@Output() pageQueryChange: EventEmitter<URLSearchParams> = new EventEmitter();
	@Output() pageIndexChange: EventEmitter<number> = new EventEmitter();
	@Output() pageSizeChange: EventEmitter<number> = new EventEmitter();

	/** The previous page index */
	private previousPageIndex: number;

	ngOnInit() {
		this.previousPageIndex = this.pageIndex;
	}

	/** [startOnPage]-[lastOnPage] of [length] */
	public get startOnPage() {
		return this.pageIndex * this.pageSize + 1;
	}

	/** [startOnPage]-[lastOnPage] of [length] */
	public get lastOnPage() {
		const lastOnPage = (this.pageIndex + 1) * this.pageSize;

		return lastOnPage > this.length ? this.length : lastOnPage;
	}

	public get isLastPage() {
		return this.lastOnPage >= this.length;
	}

	onPageIndexChange(change: number) {
		this.previousPageIndex = this.pageIndex;
		this.pageIndex = this.pageIndex + change;
		this.pageIndexChange.emit(this.pageIndex);
		this.emitPageEvent();
	}

	onSelectionChange({ value }: MatSelectChange) {
		this.pageSizeChange.emit(value);
		this.pageIndexChange.emit(0); // Reset to first page on page size
		this.emitPageEvent();
	}

	emitPageEvent() {
		this.pageQueryChange.emit(
			new URLSearchParams({
				page: `${this.previousPageIndex <= this.pageIndex ? this.pageIndex + 1 : this.previousPageIndex}`,
				perPage: `${this.pageSize}`,
			}),
		);
	}
}
