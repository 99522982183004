/**
 * Resolves a path in an object.
 *
 * @example
 * ```typescript
 * const obj = { foo: { bar: { baz: 13 }, bax: 8 } };
 *
 * const path = ["foo", "bar", "baz"];
 * resolveObjectPath(path, obj); // 13
 * ```
 * @param path The path to resolve in the object.
 * @param object The object to resolve the path in.
 * @returns The value at the end of the path in the object.
 */
export const resolveObjectPath = <Output>(path: ReadonlyArray<string>, object: unknown) =>
	path.reduce((value, property) => value?.[property], object) as Output;
