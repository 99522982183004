<div
	class="wrapper"
	fxLayout="column"
>
	<div fxLayout="row">
		<!-- Add the code below to the zui-sidenav to run the nav with local links when needed -->
		<!-- [menu]="SIDENAV_MENU" -->
		<zui-sidenav
			class="sidenav-component"
			[sidenavParams]="SIDENAV_PARAMS"
			[footer]="sidenavFooter"
			(emitSelectedCompany)="switchToSelectedCompany($event)"
		>
			<div class="sidenav-content-container">
				<div>
					<div
						*ngIf="usingShadowEnvironment"
						class="shadow-cue"
					>
						Shadow Environment
					</div>
					<router-outlet></router-outlet>
				</div>

				<zui-footer-component class="footer"></zui-footer-component>
			</div>
		</zui-sidenav>
	</div>
</div>
