import { Injectable } from "@angular/core";
import { addUnitsToContent } from "src/utils/addUnitsToContent/addUnitsToContent";
import { getRepairStatusDetail } from "src/utils/getRepairStatusDetail/getRepairStatusDetail";
import { getRepairStatusTitle } from "src/utils/getRepairStatusTitle/getRepairStatusTitle";
import { getSeverity } from "src/utils/getSeverity/getSeverity";
import { getSeverityStatusIcon } from "src/utils/getSeverityStatusIcon/getSeverityStatusIcon";
import { getSeverityStatusIconJpg } from "src/utils/getSeverityStatusIconJpg/getSeverityStatusIconJpg";
import { newDate } from "src/utils/newDate/newDate";
import { sortStaticInfo } from "src/utils/sortStaticInfo/sortStaticInfo";
import { timeDifference } from "src/utils/timeDifference/timeDifference";
import { base64EncodedSeverityIcons as severityIcons } from "../constants/base-64-encoded-severity-icons";
import { formatWord, translate, translateAndFormat } from "../i18next";
import { formatDate } from "../i18next/formatDate";
import {
	InspectionDetailDatumViewModel,
	InspectionDetailFormDataPhotosViewModel,
	InspectionDetailStaticInfoSortedField,
	InspectionDetailStaticInfoViewModel,
} from "../models/inspection-detail-view-models.models";
import {
	AssetDetailsPrintViewModel,
	AssetPrintViewModel,
	DefectPrintViewModel,
	InspectionDetailsPrintViewModel,
	InspectionListPrintViewModel,
	InspectionPrintViewModel,
	RepairPrintViewModel,
	TelemetryPrintViewModel,
	TimeDetailsPrintViewModel,
	ZonePrintViewModel,
} from "../models/inspection-print-view.models";
import { PhotoViewModel } from "../models/open-defect-table.models";
import {
	Asset,
	ConfigComponent,
	InspectionAsset,
	InspectionComponent,
	InspectionDefect,
	InspectionDetailData,
	InspectionGet,
	InspectionReviewGet,
	InspectionZone,
	RepairResponse,
	Telemetry,
} from "../models/openAPIAliases";
import { PhotoViewModelService } from "../services/photo-view-model.service";
import { BooleanTransformService } from "./boolean-transform.service";
import { LanguageDictionaryService } from "./language-dictionary/language-dictionary.service";
import { LocaleService } from "./locale/locale.service";

export class InspectionRepairs {
	[key: string]: Array<RepairResponse>;
}

export interface ComponentName extends ConfigComponent {
	name: string;
}

@Injectable({
	providedIn: "root",
})
export class InspectionPrintViewModelService {
	constructor(
		private booleanTransformService: BooleanTransformService,
		private photoViewModelService: PhotoViewModelService,
		private languageDictionaryService: LanguageDictionaryService,
		private localeService: LocaleService,
	) {}

	getInspectionPrintViewModel(inspection: InspectionGet): InspectionListPrintViewModel {
		const inspectionAssets: AssetPrintViewModel[] = this.getInspectionAssets(
			inspection.inspectionAssets,
			inspection.inspectionRepairs,
		);

		return {
			configId: inspection.configId,
			inspectionAssets: inspectionAssets,
			inspectionInfo: this.getInspectionInfo(inspection),
			telemetry: this.getTelemetry(inspection),
		} as InspectionListPrintViewModel;
	}

	getInspectionInfo(inspection: InspectionGet): InspectionPrintViewModel {
		// static
		const thirdSignatureFirstName: string | null =
			inspection.inspectionReviews && inspection.inspectionReviews.length > 0
				? inspection.inspectionReviews[inspection.inspectionReviews.length - 1].inspectorFirstName
				: null;

		const thirdSignatureLastName: string | null =
			inspection.inspectionReviews && inspection.inspectionReviews.length > 0
				? inspection.inspectionReviews[inspection.inspectionReviews.length - 1].inspectorLastName
				: null;

		const isRental: string =
			inspection.inspectionAssets[0].asset && inspection.inspectionAssets[0].asset.assetIsRental
				? translateAndFormat("yes", "capitalize")
				: translateAndFormat("no", "capitalize");

		const thirdSignature: string | null =
			thirdSignatureFirstName && thirdSignatureLastName
				? `${thirdSignatureLastName}, ${thirdSignatureFirstName} ${translate(
						"has reviewed this EVIR and acknowledges the certification that all required repairs have been performed",
				  )}`
				: null;

		const inspectorDivisions: string | null =
			inspection.inspectorDivisions.length === 0
				? null
				: inspection.inspectorDivisions.map(division => division.divisionName).join(", ");

		let inspector = "";

		if (inspection.inspectorLastName && inspection.inspectorFirstName) {
			inspector = inspection.inspectorLastName + ", " + inspection.inspectorFirstName;
		} else if (inspection.inspectorLastName) {
			inspector = inspection.inspectorLastName;
		} else if (inspection.inspectorFirstName) {
			inspector = inspection.inspectorFirstName;
		} else {
			// keep the empty string. Log something about inspection not having a name
		}

		const inspectionDetailsPrintView: InspectionDetailsPrintViewModel = {
			inspectionType: this.languageDictionaryService.getTranslations(
				inspection.inspectionDetail.inspectionDetailName,
			),
			license: inspection.inspectionAssets[0].asset ? inspection.inspectionAssets[0].asset.assetLicense : null,
			jurisdiction: inspection.inspectionAssets[0].asset
				? inspection.inspectionAssets[0].asset.assetJurisdiction
				: null,
			vin: inspection.inspectionAssets[0].asset ? inspection.inspectionAssets[0].asset.assetVin : null,
			powerUnit: inspection.inspectionAssets[0].asset
				? inspection.inspectionAssets[0].asset.assetPowerUnitNumber
				: null,
			rental: isRental,
			thirdSignature: thirdSignature,
			userAgent: inspection.userAgent,
			companyName: inspection.companyName,
			languageChoice: inspection.languageChoice,
			inspector: inspector,
			inspectorDivisions: inspectorDivisions,
			geolocation: inspection.geolocation,
			maxSeverity: getSeverity(inspection.maxSeverity),
		};

		// dynamic
		let dynamicInspectionDetails: InspectionDetailDatumViewModel[] = [];

		if (inspection?.inspectionDetail?.inspectionDetailData) {
			const inspectionDetailDataNonPhotoRecords = inspection.inspectionDetail.inspectionDetailData?.filter(
				inspectionDetail => inspectionDetail.inspectionDetailDataType !== "photo",
			);

			dynamicInspectionDetails = inspectionDetailDataNonPhotoRecords.map((datum: InspectionDetailData) => {
				const inspectionDetailDatum: InspectionDetailDatumViewModel = {
					languageLabel: this.languageDictionaryService.getTranslations(datum.inspectionDetailDataName),
					inspectionDetailContent: this.booleanTransformService.booleanTransform(
						datum.inspectionDetailUnitConversionPair,
						datum.inspectionDetailContent,
					),
					inspectionDetailUnitConversionPair: datum.inspectionDetailUnitConversionPair,
				};
				/* The conversion pair received is inconsitent i.e., not an array or undefined will send an empty array to normalize
				 * if the single value is received along with selected unit, abling to display the unit level
				 */
				let defaultSelectedUnit;
				if (
					!Array.isArray(datum.inspectionDetailUnitConversionPair) ||
					(Array.isArray(inspectionDetailDatum.inspectionDetailUnitConversionPair) &&
						inspectionDetailDatum.inspectionDetailUnitConversionPair.length === 0)
				) {
					defaultSelectedUnit = datum?.inspectionDetailSelectedUnit;
					inspectionDetailDatum.inspectionDetailUnitConversionPair =
						defaultSelectedUnit === undefined
							? (inspectionDetailDatum.inspectionDetailUnitConversionPair = [])
							: defaultSelectedUnit;
				}
				inspectionDetailDatum.inspectionDetailContent = addUnitsToContent(
					datum,
					inspectionDetailDatum,
					this.localeService,
				);

				return inspectionDetailDatum;
			});
		}

		const start = newDate(inspection.inspectionInfo.find(info => info.action === "start").telemetry.timestamp);
		const end = newDate(inspection.inspectionInfo.find(info => info.action === "end").telemetry.timestamp);
		const upload = newDate(inspection.inspectionInfo.find(info => info.action === "upload").telemetry.timestamp);
		const certify = newDate(inspection.inspectionInfo.find(info => info.action === "certify").telemetry.timestamp);
		const timeDiff = timeDifference(end)(start);
		const duration = `${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;

		// time
		const timeInspectionDetails: TimeDetailsPrintViewModel = {
			start: formatDate(start, "PPPPpppp"),
			end: formatDate(end, "PPPPpppp"),
			upload: formatDate(upload, "PPPPpppp"),
			certify: formatDate(certify, "PPPPpppp"),
			duration: duration,
		};

		return {
			static: inspectionDetailsPrintView,
			dynamic: dynamicInspectionDetails,
			formDataPhotos: this.getFormDataPhotos(inspection),
			time: timeInspectionDetails,
		} as InspectionPrintViewModel;
	}

	getFormDataPhotos(inspection: InspectionGet): Array<InspectionDetailFormDataPhotosViewModel> {
		let formDataPhotos: Array<InspectionDetailFormDataPhotosViewModel> = [];
		const inspectionDetailDataRecords = inspection?.inspectionDetail?.inspectionDetailData?.filter(
			inspectionDetail => inspectionDetail.inspectionDetailDataType === "photo",
		);

		inspectionDetailDataRecords?.map((inspectionDetailData: InspectionDetailData) => {
			formDataPhotos = [
				...formDataPhotos,
				{
					images:
						inspectionDetailData.detailMedia?.length > 0
							? this.photoViewModelService.buildPhotoViewModel(inspectionDetailData.detailMedia)
							: undefined,
					defaultValue: "—",
					label: formatWord(
						this.languageDictionaryService.getTranslations(inspectionDetailData.inspectionDetailDataName),
						"capitalize",
					),
				},
			];
		});

		return formDataPhotos ?? [];
	}

	buildTelemetry(data: { telemetry: Telemetry }, type: string, value: string) {
		return {
			elevation: data.telemetry.elevation,
			horizontalAccuracy: data.telemetry.horizontalAccuracy,
			latitude: data.telemetry.latitude,
			longitude: data.telemetry.longitude,
			relevantValue: value,
			telemetryType: type,
			timestamp: new Date(data.telemetry.timestamp),
		} as TelemetryPrintViewModel;
	}

	getTelemetry(inspection: InspectionGet): TelemetryPrintViewModel[] {
		const telemetryInfo: TelemetryPrintViewModel[] = [];

		if (inspection.inspectionReviews && inspection.inspectionReviews.length > 0) {
			inspection.inspectionReviews.forEach((review: InspectionReviewGet) => {
				if (review.telemetry) {
					telemetryInfo.push(
						this.buildTelemetry(
							review,
							translateAndFormat("inspection review", "title"),
							review.inspectorLastName + ", " + review.inspectorFirstName,
						),
					);
				}
			});
		}

		if (inspection.inspectionInfo && inspection.inspectionInfo.length > 0) {
			inspection.inspectionInfo.forEach(info => {
				if (info.telemetry) {
					telemetryInfo.push(
						this.buildTelemetry(info, translateAndFormat("inspection info", "title"), info.action),
					);
				}
			});
		}

		if ((inspection?.inspectionDetail?.inspectionDetailData?.length ?? 0) > 0) {
			inspection.inspectionDetail.inspectionDetailData.forEach(
				({ telemetry, inspectionDetailDataName }: InspectionDetailData) => {
					if (telemetry) {
						telemetryInfo.push(
							this.buildTelemetry(
								{ telemetry },
								translateAndFormat("inspection detail data", "title"),
								this.languageDictionaryService.getTranslations(inspectionDetailDataName),
							),
						);
					}
				},
			);
		}

		if (inspection.inspectionAssets && inspection.inspectionAssets.length > 0) {
			inspection.inspectionAssets.forEach((asset: InspectionAsset) => {
				if (asset.inspectionZones && asset.inspectionZones.length > 0) {
					asset.inspectionZones.forEach(({ telemetry, zoneName }: InspectionZone) => {
						if (telemetry) {
							const assetName =
								asset && asset.asset && asset.asset.assetName ? asset.asset.assetName : "";
							telemetryInfo.push(
								this.buildTelemetry(
									{ telemetry },
									`${translate("inspection zone for asset")}: ${assetName}`,
									this.languageDictionaryService.getTranslations(zoneName),
								),
							);
						}
					});
				}
			});
		}

		return telemetryInfo;
	}

	getInspectionAssets(
		inspectionAssets: InspectionAsset[],
		inspectionRepairs: InspectionRepairs,
	): AssetPrintViewModel[] {
		const inspectionAssetViewModels: AssetPrintViewModel[] = inspectionAssets.map(
			(inspectionAsset: InspectionAsset) => {
				const inspectionZones: ZonePrintViewModel[] | null = !inspectionAsset.inspectionZones
					? null
					: inspectionAsset.inspectionZones.map((inspectionZone: InspectionZone) => {
							const componentsViewString: string = inspectionZone.inspectionComponents
								.map((inspectionComponent: InspectionComponent) =>
									this.languageDictionaryService.getTranslations(inspectionComponent.componentName),
								)
								.join(", ");

							const timestamp: string | null = inspectionZone.telemetry
								? formatDate(newDate(inspectionZone.telemetry.timestamp), "Ppp")
								: null;

							return {
								languageLabel: this.languageDictionaryService.getTranslations(inspectionZone?.zoneName),
								timestamp: timestamp,
								verificationType: formatWord(
									inspectionZone.verificationType, // TODO: Update this line once we have the langKey of verificationType: this.languageDictionaryService.getTranslation(inspectionZone.verificationType),
									"title",
								),
								components: componentsViewString,
								inspectionDefectsYN:
									this.getDefects(
										inspectionZone.inspectionComponents,
										inspectionRepairs,
										inspectionZone,
									).length > 0
										? translateAndFormat("y", "uppercase")
										: translateAndFormat("n", "uppercase"),
							} as ZonePrintViewModel;
					  });

				// defects
				let inspectionDefects: DefectPrintViewModel[] = [];

				if (inspectionAsset.inspectionZones) {
					inspectionDefects = inspectionAsset.inspectionZones.reduce((accumulator, inspectionZone) => {
						if (inspectionZone.inspectionComponents) {
							accumulator.push(
								...this.getDefects(
									inspectionZone.inspectionComponents,
									inspectionRepairs,
									inspectionZone,
								),
							);
						}

						return accumulator;
					}, []);
				}

				// asset details
				const asset: Asset = inspectionAsset.asset;
				const assetDetails: AssetDetailsPrintViewModel[] = [];

				if (asset) {
					const assetDetailsToAdd: AssetDetailsPrintViewModel[] = [
						{
							label: translateAndFormat("license", "capitalize"),
							value: asset.assetLicense,
						},
						{
							label: translateAndFormat("jurisdiction", "capitalize"),
							value: asset.assetJurisdiction,
						},
						{
							label: translateAndFormat("power unit number", "title"),
							value: asset.assetPowerUnitNumber,
						},
						{
							label: translateAndFormat("version", "capitalize"),
							value: asset.assetVersion,
						},
						{
							label: translateAndFormat("make", "capitalize"),
							value: asset.assetMake,
						},
						{
							label: translateAndFormat("model", "capitalize"),
							value: asset.assetModel,
						},
						{
							label: translateAndFormat("model year", "title"),
							value: asset.assetModelYear,
						},
						{
							label: translateAndFormat("serial number", "title"),
							value: asset.assetSerialNumber,
						},
						{
							label: translateAndFormat("vin", "uppercase"),
							value: asset.assetVin,
						},
						{
							label: translateAndFormat("carrier DOT", "capitalize"),
							value: asset.assetCarrierDot,
						},
						{
							label: translateAndFormat("engine serial number", "title"),
							value: asset.assetEngineSerialNumber,
						},
						{ label: translate("OEM Pin"), value: asset.assetOemPin },
						{
							label: translateAndFormat("rental DOT", "capitalize"),
							value: asset.assetRentalDot,
						},
						{
							label: translateAndFormat("rental", "capitalize"),
							value: asset.assetIsRental,
						},
					];

					for (const detail of assetDetailsToAdd) {
						if (detail.value) {
							assetDetails.push(detail);
						}
					}
				}

				const assetDivision: string = asset && asset.assetDivision ? asset.assetDivision.divisionName : "";

				return {
					zoneLayoutName: this.languageDictionaryService.getTranslations(inspectionAsset.zoneLayoutName),
					assetName: asset && asset.assetName ? asset.assetName : "",
					assetCategory: asset && asset.assetCategory ? asset.assetCategory : "",
					assetDetails: assetDetails,
					assetDivision: assetDivision,
					inspectionZones: inspectionZones,
					inspectionDefects: inspectionDefects,
				} as AssetPrintViewModel;
			},
		);

		return inspectionAssetViewModels;
	}

	getDefects(
		inspectionComponents: Array<InspectionComponent>,
		inspectionRepairs: InspectionRepairs,
		inspectionZone: InspectionZone,
	): DefectPrintViewModel[] {
		const containsNoDefects = inspectionComponents.every(
			({ inspectionDefects }) =>
				inspectionDefects === undefined || inspectionDefects === null || inspectionDefects?.length === 0,
		);

		if (containsNoDefects) {
			return [];
		}

		return inspectionComponents.flatMap(({ inspectionDefects, componentName }) =>
			inspectionDefects?.map((inspectionDefect: InspectionDefect) => {
				const repair: RepairPrintViewModel = this.getViewModelRepairUpdate(inspectionDefect, inspectionRepairs);

				// sanitize images
				const photos: PhotoViewModel[] = inspectionDefect.defectMedia
					? this.photoViewModelService.buildPhotoViewModel(inspectionDefect.defectMedia)
					: [];

				return {
					componentLabel: this.languageDictionaryService.getTranslations(componentName),
					conditionLabel: this.languageDictionaryService.getTranslations(inspectionDefect.conditionName),
					defectId: inspectionDefect.defectId,
					severity: getSeverity(inspectionDefect.severity),
					severityIcon: getSeverityStatusIcon(inspectionDefect.severity),
					severityIconJpg: getSeverityStatusIconJpg(inspectionDefect.severity),
					repair: repair,
					photos: photos,
					zoneName: this.languageDictionaryService.getTranslations(inspectionZone?.zoneName),
					repairComment: repair ? repair.comment : null,
					repairStatus: repair ? repair.statusTitle : null,
					repairDate: repair ? repair.created : null,
					repairTechnician: repair ? repair.mechanicFullName : null,
					repairResolution: repair ? repair.resolution : null,
					repairWorkOrder: repair ? repair.workOrder : null,
				} as DefectPrintViewModel;
			}),
		);
	}

	getViewModelRepairUpdate(defect: InspectionDefect, inspectionRepairs: InspectionRepairs): RepairPrintViewModel {
		if (!inspectionRepairs) {
			return null;
		}
		if (!inspectionRepairs[defect.defectId]) {
			return null;
		}
		if (inspectionRepairs[defect.defectId].length === 0) {
			return null;
		}

		const repairs: RepairResponse[] = inspectionRepairs[defect.defectId];
		const mostRecentRepairUpdate: RepairResponse = this.getMostRecentRepairStatus(repairs);

		// if the status is pending, show the same severity icon as the initial defect, else show green circle
		const severityIcon: string =
			mostRecentRepairUpdate.repairStatus === "pending"
				? getSeverityStatusIcon(defect.severity)
				: "assets/circle_check.svg";

		// if the status is pending, show the same severity icon as the initial defect, else show green circle
		const severityIconJpg: string =
			mostRecentRepairUpdate.repairStatus === "pending"
				? getSeverityStatusIconJpg(defect.severity)
				: severityIcons.circleCheckJpg;

		return {
			statusTitle: getRepairStatusTitle(mostRecentRepairUpdate.repairStatus),
			statusDetail: getRepairStatusDetail(mostRecentRepairUpdate.repairStatus),
			severityIcon: severityIcon,
			severityIconJpg: severityIconJpg,
			created: mostRecentRepairUpdate.created,
			resolution: mostRecentRepairUpdate.resolution,
			workOrder: mostRecentRepairUpdate.workOrder,
			mechanicId: mostRecentRepairUpdate.mechanicId,
			comment: mostRecentRepairUpdate.comment,
			mechanicFirstName: mostRecentRepairUpdate.mechanicFirstName,
			mechanicLastName: mostRecentRepairUpdate.mechanicLastName,
			mechanicFullName:
				defect.legacyDefectId && mostRecentRepairUpdate.repairStatus === "pending"
					? "—"
					: `${mostRecentRepairUpdate.mechanicLastName}, ${mostRecentRepairUpdate.mechanicFirstName}`,
		} as RepairPrintViewModel;
	}

	// todo: use a normal array sort override?
	// returns most recent repair comments, resolution, and mechanic
	public getMostRecentRepairStatus(repairStatus: RepairResponse[]): RepairResponse {
		// get all repair creation times as Dates in Dates array
		const dates: Date[] = repairStatus.map((repair: RepairResponse) => newDate(repair.created));
		const mostRecentDate = dates.sort((dateA, dateB) => dateB.getTime() - dateA.getTime())[0];
		// send the repair array and most recent Date from Dates array
		return this.getMostRecentRepairObject(repairStatus, mostRecentDate);
	}

	// match the most recent Date to the the created Date in the repairs array and return most recent object
	public getMostRecentRepairObject(repairStatus: RepairResponse[], mostRecentDate: Date): RepairResponse {
		const mostRecentRepairObject: RepairResponse = repairStatus.find((repair: RepairResponse) => {
			return newDate(repair.created).getTime() === mostRecentDate.getTime();
		});

		return mostRecentRepairObject;
	}

	public getSortedStaticInfo(
		staticInfo: InspectionDetailStaticInfoViewModel,
	): Array<InspectionDetailStaticInfoSortedField> {
		return sortStaticInfo(staticInfo, ["location", "license", "jurisdiction", "3rd Signature"]);
	}
}
