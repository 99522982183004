import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-severity-zone-icon",
	templateUrl: "./severity-zone-icon.component.html",
	styleUrls: ["./severity-zone-icon.component.scss"],
})
export class SeverityZoneIconComponent {
	@Input() severity: "minor" | "major" | "none" | "uninspected";
	brandZonar = environment.brand.name === "zonar";
}
