<div class="single-inspection-list-view">
	<div *ngIf="viewModelError; then errorMessage; else content"></div>

	<!-- ERROR MESSAGE -->
	<ng-template #errorMessage>
		<div class="error-message">
			{{ "there was an issue with this inspection's data:" | i18next: { format: "capitalize" } }}
			<br />
			<br />
			{{ viewModelErrorDetails }}
			<br />
			<br />
			{{ "please contact your account administrator" | i18next: { format: "capitalize" } }}
		</div>
	</ng-template>

	<!-- INSPECTION DETAILS -->
	<ng-template #content>
		<div class="inspection-data">
			<div class="inspection-data-toggle">
				<h2 class="section-title">{{ "inspection details" | i18next: { format: "capitalize" } }}</h2>
				<div class="inspection-details-toggle">
					<span>{{ (rotateState === "up" ? "less" : "more") | i18next: { format: "capitalize" } }}</span>
					<mat-icon
						class="pointer"
						[@rotatedState]="rotateState"
						(click)="rotate()"
					>
						expand_more
					</mat-icon>
				</div>
			</div>

			<!-- INSPECTION INFORMATION ACCORDION -->
			<div
				class="asset-detail-table"
				[@detailExpand]="expansionAnimation()"
			>
				<table class="detail-table">
					<ng-container *ngFor="let info of staticInfo">
						<tr class="detail-table-tr">
							<td class="detail-table-field">{{ info.detailItemField }}</td>
							<td class="detail-table-data">
								{{ info.detailItemContent }}
							</td>
						</tr>
					</ng-container>
					<ng-container *ngFor="let detail of viewModel.inspectionDetailData">
						<tr class="detail-table-tr">
							<td class="detail-table-field">{{ detail.languageLabel }}</td>
							<td class="detail-table-data">
								{{ detail.inspectionDetailContent }}
							</td>
						</tr>
					</ng-container>
					<ng-container *ngFor="let photo of formDataPhotos">
						<ng-container *ngIf="photo.images">
							<tr class="detail-table-tr form-data-photos">
								<td class="detail-table-field">{{ photo.label }}</td>
							</tr>
							<tr class="detail-table-tr">
								<td
									class="detail-table-field"
									colspan="2"
								>
									<div class="photo-row">
										<ng-container *ngFor="let image of photo.images; let i = index">
											<img
												id="form-data-photos-image"
												class="thumbnail photo"
												[src]="image.sanitizedImage"
												(click)="handleInspectionImageClick(photo, i)"
											/>
										</ng-container>
									</div>
								</td>
							</tr>
						</ng-container>
						<tr
							class="detail-table-tr"
							*ngIf="!photo.images"
						>
							<td class="detail-table-field">{{ photo.label }}</td>
							<td class="detail-table-data">{{ photo.defaultValue }}</td>
						</tr>
					</ng-container>
				</table>
			</div>
		</div>

		<!-- ASSET INSPECTION -->
		<ng-container *ngFor="let asset of viewModel.inspectionAssets; let assetIndex = index">
			<h2 class="asset-name">{{ asset.languageLabel }}</h2>

			<!-- ZONE NO DEFECT CARD -->
			<ng-container *ngIf="!asset.hasDefects && defectsOnly">
				<div class="no-zone-defect-card">
					<span>
						{{
							"there currently are no defects associated with this asset."
								| i18next: { format: "capitalize" }
						}}
					</span>
					<app-severity-zone-icon [severity]="'none'"></app-severity-zone-icon>
				</div>
			</ng-container>

			<!-- ZONE CARD -->
			<ng-container *ngFor="let zone of asset.inspectionZones; let zoneIndex = index">
				<div
					class="zone-card"
					*ngIf="showZoneCard(zone)"
				>
					<div
						class="zone-data"
						[ngClass]="{
							'no-zone-defects': !zone.inspectionDefects
						}"
					>
						<h3 class="zone-name">{{ zone.languageLabel }}</h3>
						<app-severity-zone-icon [severity]="zone.severity"></app-severity-zone-icon>
					</div>

					<!--ZONE DEFECTS-->
					<div
						class="zone-defects"
						*ngIf="zone.inspectionDefects"
					>
						<div *ngFor="let defect of zone.inspectionDefects">
							<ng-container *ngIf="defect">
								<mat-divider class="horizontal-divider"></mat-divider>
								<div class="component-defect">
									<div class="component-label">
										<span>{{ defect.componentLabel }} > {{ defect.conditionLabel }}</span>
										<!-- SEVERITY TRANSITION ICONS -->
										<ng-container
											*ngIf="defect.repair && defect.repair.statusTitle === closedStatus"
										>
											<div class="defect-status-change">
												<img
													class="component-severity-icon"
													[src]="defect.severityIcon"
												/>
												<img
													class="arrow-icon"
													src="/assets/arrow.svg"
												/>
												<img
													class="component-severity-icon"
													src="/assets/severity-none.svg"
												/>
											</div>
										</ng-container>
										<!-- SEVERITY ICON -->
										<ng-container
											*ngIf="!defect.repair || defect.repair.statusTitle !== closedStatus"
										>
											<img
												class="component-severity-icon"
												[src]="defect.severityIcon"
											/>
										</ng-container>
									</div>
									<span
										*ngIf="defect.defectComment"
										class="defect-comment"
									>
										"{{ defect.defectComment }}"
									</span>
									<span *ngIf="zone.time && zone.verificationType !== 'Uninspected'">
										{{ "inspected at" | i18next: { format: "capitalize" } }} {{ zone.time }}
									</span>
								</div>

								<!-- DEFECT DETAILS -->
								<table
									*ngIf="defect.repair"
									class="detail-table defect-detail-table"
								>
									<ng-container *ngFor="let repair of defect.repair.renderMap">
										<tr class="detail-table-tr">
											<td class="detail-table-field">
												{{ repair.label }}
											</td>
											<td class="detail-table-data">
												{{ repair.value }}
											</td>
										</tr>
									</ng-container>
								</table>
								<table
									*ngIf="!defect.repair"
									class="detail-table defect-detail-table"
								>
									<ng-container *ngFor="let repair of renderMap">
										<tr class="detail-table-tr">
											<td class="detail-table-field">
												{{ repair.label }}
											</td>
											<td class="detail-table-data">
												{{ repair.value }}
											</td>
										</tr>
									</ng-container>
									<tr class="detail-table-tr">
										<td class="detail-table-field">
											{{ photoLabel }}
										</td>
										<td class="detail-table-data">
											{{ defect.photos && defect.photos?.length > 0 ? "" : "—" }}
										</td>
									</tr>
								</table>
								<ng-container *ngIf="defect.photos && defect.photos?.length > 0">
									<div class="photo-row">
										<ng-container *ngFor="let photo of defect.photos; let photoIndex = index">
											<img
												id="defect-thumbnail"
												class="photo"
												[src]="photo.sanitizedImage"
												(click)="handleImageClick(defect, photoIndex)"
											/>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>

					<!--ZONE COMPONENTS-->
					<mat-accordion *ngIf="zone.componentsWithoutDefects?.length > 0">
						<mat-expansion-panel
							class="zone-expansion-panel"
							[ngClass]="{
								'zone-verification': !zone.inspectionDefects
							}"
							(click)="handleZoneClick(assetIndex, zoneIndex)"
							(opened)="panelOpenState = true"
							(closed)="panelOpenState = false"
						>
							<mat-expansion-panel-header>
								<mat-panel-title *ngIf="!zone.inspectionDefects">
									<span
										class="zone-inspected-time"
										*ngIf="zone.time"
									>
										{{ "inspected at" | i18next: { format: "capitalize" } }} {{ zone.time }}
									</span>
									<span *ngIf="!zone.time">{{ zone.verificationType }}</span>
									{{ zoneDetailsLabel[assetIndex][zoneIndex] | i18next: { format: "capitalize" } }}
								</mat-panel-title>
								<mat-panel-title *ngIf="zone.inspectionDefects">
									{{ zoneDetailsLabel[assetIndex][zoneIndex] | i18next: { format: "capitalize" } }}
								</mat-panel-title>
							</mat-expansion-panel-header>
							<ng-container *ngIf="zone.componentsWithoutDefects?.length > 0">
								<div
									class="component-info"
									*ngFor="let component of zone.componentsWithoutDefects"
								>
									<span class="component-name">{{ component }}</span>
									<mat-icon
										*ngIf="zone.verificationType === 'Uninspected'"
										class="component-status"
									>
										remove_circle_outline
									</mat-icon>
									<img
										*ngIf="zone.verificationType !== 'Uninspected'"
										class="component-status"
										src="/assets/severity-none.svg"
									/>
								</div>
							</ng-container>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</ng-container>
		</ng-container>
	</ng-template>
</div>
