import { environment } from "src/environments/environment";

/**
 * Get the UUID of the Zonar tag component based on the current environment.
 * @returns The UUID of the Zonar tag component.
 */
export const getZonarTagComponentUUID = () => {
	switch (environment.environment) {
		case "development":
			return "15c814c0-08d3-4f9c-a478-1712e5fe2ead";
		case "qa":
			return "9fca7cc2-b456-4b57-874e-db7603b5e79f";
		case "production":
			return "40aaba9f-87b7-469b-8142-58e267ae18a0";
	}
};
