<table
	mat-table
	matSort
	cdkOverlayOrigin
	[matSortActive]="sortActive"
	[matSortDirection]="sortDirection"
	multiTemplateDataRows
	[dataSource]="getDataSource()"
	class="missing-inspections-table"
	(matSortChange)="sortChange.emit($event)"
	matSortDisableClear="true"
>
	<!-- Checkbox Column -->
	<ng-container matColumnDef="select">
		<th
			mat-header-cell
			*matHeaderCellDef
			class="select-all-checkbox"
		>
			<mat-checkbox
				(change)="$event ? masterToggle() : null"
				[checked]="selectionModel.hasValue() && isAllSelected()"
				[indeterminate]="selectionModel.hasValue() && !isAllSelected()"
				[aria-label]="checkboxLabel()"
			></mat-checkbox>
		</th>

		<td
			mat-cell
			*matCellDef="let element"
		>
			<mat-checkbox
				#matCheckbox
				(click)="handleCheckboxClick($event, element)"
				(change)="handleCheckboxChange($event, element)"
				[checked]="selectionModel.isSelected(element)"
				[aria-label]="checkboxLabel(element)"
			></mat-checkbox>
		</td>
	</ng-container>

	<!-- dynamic column definitions -->
	<ng-container
		*ngFor="let column of tableColumns; let i = index; let last = last"
		[matColumnDef]="column.id"
	>
		<!-- header cell  -->
		<!-- sortable? -->
		<ng-container *ngIf="column.sortable; else notSortable">
			<th
				mat-sort-header
				mat-header-cell
				*matHeaderCellDef
			>
				<!-- select or regular  -->
				<ng-container *ngIf="column.type !== 'select' || column.type !== 'button'; else select">
					{{ column.name | i18next: { format: "uppercase" } }}
				</ng-container>

				<ng-template #select>
					<mat-checkbox></mat-checkbox>
				</ng-template>
			</th>
		</ng-container>

		<ng-template #notSortable>
			<th
				mat-header-cell
				*matHeaderCellDef
			>
				<!-- select or regular  -->
				<ng-container *ngIf="column.type !== 'select' || column.type !== 'button'; else select">
					{{ column.name | i18next: { format: "uppercase" } }}
				</ng-container>

				<ng-template #select>
					<mat-checkbox></mat-checkbox>
				</ng-template>
			</th>
		</ng-template>

		<!-- data cell  -->
		<td
			mat-cell
			*matCellDef="let record"
		>
			<ng-container
				#value
				*ngIf="column.type === 'multiple-values'; then multipleValues; else checkRouterLinkData"
			></ng-container>

			<!-- mutliple value  -->
			<ng-template #multipleValues>
				<span *ngIf="resolvePath(column.data, record) as parts">
					<span>{{ parts[0] }}&nbsp;</span>
					<span class="other-parts">{{ parts[1] }}</span>
				</span>
			</ng-template>

			<ng-template #checkRouterLinkData>
				<ng-container *ngIf="column.routerLinkData; then routerLinkData; else noRouterLinkData"></ng-container>
			</ng-template>

			<!-- has routerLink data ?  -->
			<ng-template #routerLinkData>
				<a routerLink="{{ column.routerLinkData.path }}/{{ resolvePath(column.routerLinkData.idExp, record) }}">
					{{ resolvePath(column.data, record) }}
				</a>
			</ng-template>

			<ng-template #noRouterLinkData>
				{{ resolvePath(column.data, record) }}
			</ng-template>
		</td>

		<!-- extra data  -->
		<ng-container
			*ngIf="extraDataCol"
			matColumnDef="expanded-detail"
		>
			<td
				mat-cell
				*matCellDef="let record; let i = dataIndex"
				[attr.colspan]="displayedColumns.length"
			>
				<div
					class="expanded-row"
					[@detailExpand]="expansionAnimation(element, i)"
				>
					<div class="missed-dates-info">
						<div class="missed-dates-header">
							{{ extraDataCol.name | i18next: { format: "uppercase" } }}
						</div>
						<div class="extra-data">
							<span
								*ngFor="let data of record[extraDataCol.extraData]"
								class="missed-dates-info-row"
							>
								{{ data }}
							</span>
						</div>
					</div>
				</div>
			</td>
		</ng-container>
	</ng-container>

	<!-- header row  -->
	<tr
		mat-header-row
		*matHeaderRowDef="displayedColumns"
	></tr>

	<!-- data rows  -->
	<tr
		mat-row
		*matRowDef="let row; columns: displayedColumns; let i = dataIndex"
		class="primary-row"
		[ngClass]="{ highlight: selectionModel.isSelected(row), 'remove-background': hovering }"
	></tr>

	<!-- extra data row  -->
	<ng-container *ngIf="extraDataCol">
		<tr
			mat-row
			*matRowDef="let row; columns: ['expanded-detail']; let i = dataIndex"
			[ngClass]="getDividerClass(row, i)"
			class="detail-row"
		></tr>
	</ng-container>

	<!-- Click to expand extra column -->
	<ng-container matColumnDef="chevron">
		<th
			mat-header-cell
			*matHeaderCellDef
			class="last-column"
		></th>
		<td
			mat-cell
			*matCellDef="let element; let i = dataIndex"
			class="last-column"
		>
			<mat-icon
				class="pointer"
				*ngIf="extraDataCol"
				[@rotatedState]="rotateState[i]"
				(click)="rotate(i)"
			>
				expand_more
			</mat-icon>
		</td>
	</ng-container>

	<!-- Button column -->
	<ng-container matColumnDef="button">
		<th
			mat-header-cell
			*matHeaderCellDef
			class="last-column"
		></th>
		<td
			mat-cell
			*matCellDef="let element"
			class="last-column"
		>
			<div>
				<!-- has button -->
				<ng-container *ngIf="buttonsCol.type === 'button'">
					<button
						mat-button
						(mouseenter)="hovering = true"
						(mouseleave)="hovering = false"
						class="mat-stroked-button outlined"
						(click)="navigateToRouterLink(buttonsCol.routerLinkData, element)"
						title="{{ buttonsCol.data }}"
					>
						{{ buttonsCol.data | i18next: { format: "capitalize" } }}
					</button>
				</ng-container>
			</div>
		</td>
	</ng-container>
</table>

<!-- manage filter columns - this is removed until we are actually ready to implement this -->
<span
	class="ic-manage-filters"
	*ngIf="false"
>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM8 18H4V6H8V18ZM14 18H10V6H14V18ZM20 18H16V6H20V18Z"
			fill="#5b6770"
		/>
	</svg>
</span>
