import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatCalendar, MatDatepickerIntl } from "@angular/material/datepicker";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { formatWord } from "src/app/i18next";

@Component({
	selector: "app-custom-calendar-header",
	templateUrl: "./custom-calendar-header.component.html",
	styleUrls: ["./custom-calendar-header.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCalendarHeaderComponent<D> implements OnDestroy {
	private _destroyed = new Subject<void>();
	private _yearsPerPage = 24;

	constructor(
		private _intl: MatDatepickerIntl,
		private _calendar: MatCalendar<D>,
		private _dateAdapter: DateAdapter<D>,
		@Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
		cdr: ChangeDetectorRef,
	) {
		_calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.complete();
	}

	get periodLabel() {
		return this._dateAdapter
			.format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
			.toLocaleUpperCase();
	}
	get selectedDate() {
		return formatWord(
			this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel),
			"capitalize",
		);
	}

	/** Whether the previous period button is enabled. */
	previousEnabled(): boolean {
		if (!this._calendar.minDate) {
			return true;
		}
		return !this._calendar.minDate || !this._isSameView(this._calendar.activeDate, this._calendar.minDate);
	}

	/** Whether the next period button is enabled. */
	nextEnabled(): boolean {
		return !this._calendar.maxDate || !this._isSameView(this._calendar.activeDate, this._calendar.maxDate);
	}

	/** Whether the two dates represent the same view in the current view mode (month or year). */
	private _isSameView(date1: D, date2: D): boolean {
		if (this._calendar.currentView == "month") {
			return (
				this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) &&
				this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2)
			);
		}
		if (this._calendar.currentView == "year") {
			return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2);
		}
		// Otherwise we are in 'multi-year' view.
		return this.isSameMultiYearView(
			this._dateAdapter,
			date1,
			date2,
			this._calendar.minDate,
			this._calendar.maxDate,
		);
	}

	isSameMultiYearView<D>(
		dateAdapter: DateAdapter<D>,
		date1: D,
		date2: D,
		minDate: D | null,
		maxDate: D | null,
	): boolean {
		const year1 = dateAdapter.getYear(date1);
		const year2 = dateAdapter.getYear(date2);
		const startingYear = this.getStartingYear(dateAdapter, minDate, maxDate);
		return (
			Math.floor((year1 - startingYear) / this._yearsPerPage) ===
			Math.floor((year2 - startingYear) / this._yearsPerPage)
		);
	}

	/**
	 * We pick a "starting" year such that either the maximum year would be at the end
	 * or the minimum year would be at the beginning of a page.
	 */
	getStartingYear<D>(dateAdapter: DateAdapter<D>, minDate: D | null, maxDate: D | null): number {
		let startingYear = 0;
		if (maxDate) {
			const maxYear = dateAdapter.getYear(maxDate);
			startingYear = maxYear - this._yearsPerPage + 1;
		} else if (minDate) {
			startingYear = dateAdapter.getYear(minDate);
		}
		return startingYear;
	}

	/** Handles user clicks on the previous button. */
	previousClicked(): void {
		this._calendar.activeDate =
			this._calendar.currentView == "month"
				? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
				: this._dateAdapter.addCalendarYears(
						this._calendar.activeDate,
						this._calendar.currentView == "year" ? -1 : -this._yearsPerPage,
				  );
	}

	/** Handles user clicks on the next button. */
	nextClicked(): void {
		this._calendar.activeDate =
			this._calendar.currentView == "month"
				? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
				: this._dateAdapter.addCalendarYears(
						this._calendar.activeDate,
						this._calendar.currentView == "year" ? 1 : this._yearsPerPage,
				  );
	}

	/** The label for the previous button. */
	get prevButtonLabel(): string {
		return {
			month: this._intl.prevMonthLabel,
			year: this._intl.prevYearLabel,
			"multi-year": this._intl.prevMultiYearLabel,
		}[this._calendar.currentView];
	}

	/** The label for the next button. */
	get nextButtonLabel(): string {
		return {
			month: this._intl.nextMonthLabel,
			year: this._intl.nextYearLabel,
			"multi-year": this._intl.nextMultiYearLabel,
		}[this._calendar.currentView];
	}
}
