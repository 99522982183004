export enum DateRange {
	custom = "custom",
	today = "today",
	previous7 = "previous-7-days",
	previous14 = "previous-14-days",
	previous30 = "previous-30-days",
	previous365 = "previous-365-days",
}

export interface DateRangeFilterState {
	selectedDateRange: DateRange;
	dateRangeBeginDate: Date;
	dateRangeEndDate: Date;
	potentialDateRangeBeginDate: Date;
	potentialDateRangeEndDate: Date;
	potentialFilterButtonText: string;
	currentFilter: DateRange;
	filterButtonText: string;
}

export interface CustomDateRangeFilterState {
	currentFilter: DateRange;
	dateRangeEndDate: Date;
	dateRangeStartDate: Date;
	exceptionsDates: Date[];
	exceptionsDayOfWeek: number[];
	filterButtonText: string;
	potentialDateInputEndDate: Date;
	potentialDateInputStartDate: Date;
	potentialFilterButtonText: string;
	selectedDateRange: DateRange;
}
