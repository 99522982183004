import { Injectable } from "@angular/core";
import { currentLocale } from "src/app/i18next";

// the purpose of this service is soley to facilitate testability with respect to locale
@Injectable({
	providedIn: "root",
})
export class LocaleService {
	getCurrentLocale() {
		return currentLocale();
	}

	usesMetricSystem(): boolean {
		// only US, Liberia and MyanMar use Imperial
		return (
			this.getCurrentLocale().toLowerCase() !== "en-us" &&
			this.getCurrentLocale().toLowerCase() !== "en-lr" &&
			this.getCurrentLocale().toLowerCase() !== "my-mm"
		);
	}
}
