import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { Store } from "@ngxs/store";
import { navErrorConstants } from "src/app/constants/error-constants";
import { SetClientError, SetHttpError, SetNavState } from "../state/error.state";

@Injectable({
	providedIn: "root",
})
export class ErrorService implements ErrorHandler {
	constructor(
		// inject services via injector due to dynamic error service and circular dependencies: https://stackoverflow.com/questions/46558847/injection-via-the-constructor-parameters-vs-the-injector-service
		private injector: Injector,
		private auth: AuthService,
	) {}

	handleError(error): void {
		const store = this.injector.get(Store);
		const router = this.injector.get(Router);

		if (error instanceof HttpErrorResponse) {
			if (error.status === 401 && !error.url.includes("geocoder")) {
				this.auth.logout({ returnTo: window.location.origin });
			} else {
				console.error(
					`Something went wrong with the HTTP request. Status: ${error.status}. Error: ${
						typeof error.error === "object" ? JSON.stringify(error.error) : error.error
					}`,
				);

				store.dispatch(new SetHttpError(error));

				if (this.isFatal(error.url)) {
					this.removeOverlays();

					switch (error.status) {
						case 404:
							store.dispatch(
								new SetNavState({ http: true, type: navErrorConstants.NAV_ERROR_NOT_FOUND }),
							);
							router.navigate(["page-not-found"]);
							break;
						case 500:
							store.dispatch(
								new SetNavState({ http: true, type: navErrorConstants.NAV_ERROR_SERVER_ERROR }),
							);
							router.navigate(["server-error"]);
							break;
						case 503:
							store.dispatch(
								new SetNavState({ http: true, type: navErrorConstants.NAV_ERROR_MAINTENANCE }),
							);
							router.navigate(["server-error"]);
							break;
						default:
							store.dispatch(new SetNavState({ http: true, type: navErrorConstants.NAV_ERROR_UNKNOWN }));
							router.navigate(["server-error"]);
							break;
					}
				}
			}
		} else {
			if (
				(typeof error === "string" && error.toLowerCase().includes("login required")) ||
				(typeof error === "object" && error.message.toLowerCase()?.includes("login required"))
			) {
				this.auth.logout({ returnTo: window.location.origin });
			}

			console.error(`Something went wrong on the client-side. Error: "${error}"`);
			store.dispatch(new SetClientError(error.message));
		}
	}

	isFatal(url: string): boolean {
		if (url.includes("constants")) {
			return true;
		} // our constants endpoint failed
		if (url.includes("users")) {
			return true;
		} // our initial user endpoint failed
		if (url.includes("applications")) {
			return true;
		} // our app endpoint failed
		if (url.includes("userprofiles?embed=roles")) {
			return true;
		} // user's userprofiles endpoint failed
		if (url.includes("userpolicies")) {
			return true;
		} // user's userpolicies endpoint failed
		if (url.includes("companies") && !url.includes("divisions")) {
			return true;
		} // company endpoint failed

		// for testing
		// if(url.includes('test')) { return true }

		return false;
	}

	removeOverlays(): void {
		const cypress = localStorage.getItem("cypress");
		const overlays = document.getElementsByClassName("cdk-overlay-container");

		if (overlays && !cypress) {
			while (overlays.length > 0) {
				overlays[0].parentNode.removeChild(overlays[0]);
			}
		}
	}
}
