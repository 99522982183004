<div class="inspection-history-page">
	<div
		class="header-row"
		fxFlexLayout="row"
		fxLayoutAlign="space-between center"
	>
		<div class="heading-1">
			{{ "inspection history" | i18next: { format: "capitalize" } }}
			<div *ngIf="inspectionListCount > 1; then plural; else singular"></div>
			<ng-template #singular>
				<span class="inspection-history-record-count">
					({{ inspectionListCount }} {{ "record" | i18next }})
				</span>
			</ng-template>
			<ng-template #plural>
				<span class="inspection-history-record-count">
					({{ inspectionListCount | localizedNumber }} {{ "records" | i18next }})
				</span>
			</ng-template>
		</div>

		<app-export
			[disabled]="this.selectionModel.selected.length === 0"
			[showDetailedExport]="detailedPDF"
			(csvExportReport)="exportCSV()"
			(detailedPDFExportReport)="openPdfExportDialog()"
			(pdfExportReport)="exportPDF()"
		></app-export>
	</div>

	<app-filter-card
		(filterQueryObject)="handleFilterValues($event)"
		[context]="'inspection-history'"
		[enabledFilters]="enabledFilters"
	></app-filter-card>

	<div class="inspection-history-table-wrapper">
		<table
			mat-table
			matSort
			cdkOverlayOrigin
			[matSortActive]="sortActive"
			[matSortDirection]="sortDirection"
			multiTemplateDataRows
			[dataSource]="getDataSource()"
			class="inspection-history-table"
			(matSortChange)="onMatSortChange($event)"
			matSortDisableClear="true"
		>
			<!-- Checkbox Column -->
			<ng-container matColumnDef="select">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="select-all-checkbox"
				>
					<mat-checkbox
						(change)="$event ? masterToggle() : null"
						[checked]="selectionModel.hasValue() && isAllSelected()"
						[indeterminate]="selectionModel.hasValue() && !isAllSelected()"
						[aria-label]="checkboxLabel()"
					></mat-checkbox>
				</th>

				<td
					mat-cell
					*matCellDef="let inspection"
				>
					<mat-checkbox
						#matCheckbox
						(click)="handleCheckboxClick($event, inspection)"
						(change)="handleCheckboxChange($event, inspection)"
						[checked]="selectionModel.isSelected(inspection)"
						[aria-label]="checkboxLabel(inspection)"
					></mat-checkbox>
				</td>
			</ng-container>

			<!-- Severity Column -->
			<ng-container matColumnDef="max-severity">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "severity" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					<img [src]="getSeverityIcon(element.maxSeverity)" />
				</td>
			</ng-container>

			<!-- Date Column -->
			<ng-container matColumnDef="start-date">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "date" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element.startDate }}
				</td>
			</ng-container>

			<!-- Time Column -->
			<ng-container matColumnDef="start-time">
				<th
					mat-header-cell
					*matHeaderCellDef
				>
					{{ "time" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element.startTime }}
				</td>
			</ng-container>

			<!-- Asset Number Column -->
			<ng-container matColumnDef="asset-number">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "asset number" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element; let i = dataIndex"
				>
					<a routerLink="/asset-details/{{ element.assetViewModel.firstAssetId }}">
						{{ element.assetViewModel.firstAssetName }}
					</a>
					<mat-icon
						class="table-row-icon"
						*ngIf="element.assetViewModel.firstAssetCategory === 'user'"
						matTooltip="User Created Asset"
					>
						outlined_flag
					</mat-icon>
				</td>
			</ng-container>

			<!-- Asset Type Column -->
			<ng-container matColumnDef="asset-type">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "asset type" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element; let i = dataIndex"
				>
					{{ element.assetViewModel.firstAssetType }}
				</td>
			</ng-container>

			<!-- Inspection Name Column -->
			<ng-container matColumnDef="inspection-name">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "insp name" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					<a routerLink="/company/{{ companyId }}/inspection/{{ element.inspectionId }}">
						{{ element.inspectionName }}
					</a>
					<mat-icon
						*ngIf="element.incompleteInspection"
						class="table-row-icon"
						matTooltip="Incomplete Inspection"
					>
						remove_circle_outline
					</mat-icon>
				</td>
			</ng-container>

			<!-- Inspection Type Column -->
			<ng-container matColumnDef="inspection-type">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "insp type" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element.inspectionType }}
				</td>
			</ng-container>

			<!-- Inspector Column -->
			<ng-container matColumnDef="inspector-name">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header
				>
					{{ "inspector" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element.inspector }}
				</td>
			</ng-container>

			<!-- Home Location Column -->
			<ng-container matColumnDef="home-location">
				<th
					mat-header-cell
					*matHeaderCellDef
				>
					{{ "asset location" | i18next: { format: "uppercase" } }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element.homeLocation }}
				</td>
			</ng-container>

			<!-- Click to expand hidden column -->
			<ng-container matColumnDef="chevron">
				<th
					mat-header-cell
					*matHeaderCellDef
				></th>
				<td
					mat-cell
					*matCellDef="let element; let i = dataIndex"
				>
					<mat-icon
						class="pointer"
						*ngIf="element.assetViewModel.multipleAssets"
						[@rotatedState]="rotateState[i]"
						(click)="rotate(i)"
					>
						expand_more
					</mat-icon>
				</td>
			</ng-container>

			<ng-container matColumnDef="expanded-detail">
				<td
					mat-cell
					*matCellDef="let element; let i = dataIndex"
					[attr.colspan]="displayedColumns.length"
				>
					<div
						class="expanded-row"
						[@detailExpand]="expansionAnimation(element, i)"
					>
						<div class="additional-asset-info">
							<div class="additional-asset-header">
								{{ "additional asset information" | i18next: { format: "uppercase" } }}
							</div>

							<div
								*ngFor="let asset of element.assetViewModel.assetsExceptFirst"
								class="additional-asset-info-row"
								fxLayout="row"
							>
								<div fxFlex="15">
									<a routerLink="/asset-details/{{ asset.asset.assetId }}">
										{{ asset.asset.assetName }}
									</a>
									<mat-icon
										class="table-row-icon"
										*ngIf="asset.asset.assetCategory === 'user'"
									>
										outlined_flag
									</mat-icon>
								</div>
								<div fxFlex="60">
									{{ asset.zoneLayoutName.languageLabel }}
								</div>
							</div>
						</div>
					</div>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"
			></tr>

			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				[ngClass]="{ highlight: selectionModel.isSelected(row) }"
			></tr>

			<tr
				mat-row
				*matRowDef="let row; columns: ['expanded-detail']; let i = dataIndex"
				class="detail-row"
				[ngClass]="getDividerClass(row, i)"
			></tr>
		</table>

		<mat-card
			*ngIf="isInitiallyLoading"
			class="initial-loading-card"
			fxLayout="column"
			fxLayoutAlign="center center"
		></mat-card>

		<app-no-results-card
			*ngIf="inspectionListCount === 0 && !isInitiallyLoading"
			[message]="modifySearchString"
		></app-no-results-card>

		<app-paginator
			*ngIf="inspectionListCount !== 0 && !isInitiallyLoading"
			[length]="inspectionListCount"
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			(pageIndexChange)="onPageIndexChange($event)"
			(pageQueryChange)="onQueryChange($event)"
			(pageSizeChange)="onPageSizeChange($event)"
		></app-paginator>
	</div>
</div>
