import { addDays, endOfDay } from "date-fns";
import { DayEntry } from "./compress-days";

/**
 * Given a compressed days array, transform each compressed array into a {startTime, endTime} descriptor
 * that can be used in the JSON payload of a request to an api
 *
 * @param compressedDays an array of compressed days
 * @returns An array of day range descriptiopns
 */
export function getCompressedDaysDesc(compressedDays: DayEntry[]): { startTime: Date; endTime: Date }[] {
	return compressedDays.map(dayEntry => {
		const startTime = new Date(dayEntry.date);
		const endTime = endOfDay(addDays(startTime, dayEntry.numberOfDays - 1));
		return { startTime, endTime };
	});
}
