import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import {
	GpsMissingInspectionList,
	MissingInspectionsAssetsResponse,
	MissingInspectionsInspectorsResponse,
} from "src/app/models/openAPIAliases";
import { MissingInspectionsService } from "src/app/services/missing-inspections/missing-inspections.service";
import { MissingInspectionsReportType } from "../missing-inspections.component";

export class MissingInspectionStateModel {
	missingInspectionsListCount: number;
	missingInspectionsList:
		| MissingInspectionsAssetsResponse
		| MissingInspectionsInspectorsResponse
		| GpsMissingInspectionList;
	update: number;
}

const missingInspectionStateDefaults: MissingInspectionStateModel = {
	missingInspectionsListCount: 0,
	missingInspectionsList: [],
	update: 0,
};

export class GetMissingInspectionsList {
	static readonly type = "[Missing Inspection List View] Get Missing Inspections";
	constructor(
		public dates: Array<{ startTime: Date; endTime?: Date }>,
		public reportFocus: MissingInspectionsReportType,
		public companyId: string,
		public query: URLSearchParams,
	) {}
}

@Injectable()
@State<MissingInspectionStateModel>({
	name: "missingInspectionList",
	defaults: missingInspectionStateDefaults,
})
export class MissingInspectionsState {
	constructor(private missingInspectionsService: MissingInspectionsService) {}

	@Selector()
	static getMissingInspectionsListCount(state: MissingInspectionStateModel) {
		return state.missingInspectionsListCount;
	}

	@Selector()
	static getMissingInspectionsList(state: MissingInspectionStateModel) {
		return state.missingInspectionsList;
	}

	@Selector()
	static missingInspectionsUpdate(state: MissingInspectionStateModel) {
		return state.update;
	}

	@Action(GetMissingInspectionsList)
	getMissingInspectionsList(
		{ patchState, getState }: StateContext<MissingInspectionStateModel>,
		{ dates, reportFocus, companyId, query }: GetMissingInspectionsList,
	) {
		return this.missingInspectionsService.getMissingInspections(dates, reportFocus, companyId, query).pipe(
			tap(
				(
					response: HttpResponse<
						| MissingInspectionsAssetsResponse
						| MissingInspectionsInspectorsResponse
						| GpsMissingInspectionList
					>,
				) => {
					patchState({
						missingInspectionsList: response.body,
						missingInspectionsListCount: parseInt(response.headers.get("x-total-count"), 10),
						update: getState().update + 1,
					});
				},
			),
		);
	}
}
