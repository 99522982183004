<div
	class="info-dialog"
	cdkOverlayOrigin
>
	<div mat-dialog-title>
		<h1 class="dialog-title">
			{{ "how we identify missing inspections" | i18next: { format: "capitalize" } }}
		</h1>
	</div>

	<div
		mat-dialog-content
		class="dialog-content"
	>
		<div *ngIf="hasTCU; else noTCU">
			<p>
				{{
					"These reports help identify assets without inspections, assets that move beyond the specified threshold without an inspection, and inspectors who haven’t submitted their inspections."
						| i18next: { format: "capitalize" }
				}}
			</p>
		</div>
		<ng-template #noTCU>
			<div>
				<p>
					{{
						"These reports help identify assets without inspections, and inspectors who haven’t submitted inspections."
							| i18next: { format: "capitalize" }
					}}
				</p>
			</div>
			<strong>{{ "assets" | i18next: { format: "capitalize" } }}:</strong>
		</ng-template>
		<ng-container *ngIf="hasTCU">
			<strong>{{ "assets (with GPS)" | i18next: { format: "capitalize" } }}:</strong>
			<p class="middle-content-1">
				{{
					"This tab tracks missed inspections for assets with associated GPS data. It displays assets that moved over 10 miles, but lack inspections within the specified date range. When filtered by inspection type, it lists assets lacking inspections of that type."
						| i18next: { format: "capitalize" }
				}}
			</p>
			<strong>{{ "assets (all)" | i18next: { format: "capitalize" } }}:</strong>
		</ng-container>
		<p class="middle-content-2">
			{{
				"This tab tracks missed inspections for all the assets in the fleet irrespective of if the asset performed a trip. It identifies assets without inspections in the specified date range. When the report is filtered by inspection type, it lists assets lacking inspections of that type."
					| i18next: { format: "capitalize" }
			}}
		</p>
		<strong>{{ "inspectors" | i18next: { format: "capitalize" } }}:</strong>
		<p class="last-content">
			{{
				"This tab tracks inspectors who have not completed any inspections within the date range specified. If the results are filtered on specific type of inspection, the report would show all the inspectors, who have not performed that type of inspection."
					| i18next: { format: "capitalize" }
			}}
		</p>
	</div>

	<div
		mat-dialog-actions
		class="dialog-action"
	>
		<app-action-button
			title="ok"
			[customStyle]="'min-width: 56px !important; padding: 0px !important'"
			[format]="'uppercase'"
			(buttonClicked)="closeDialog()"
			cdkFocusInitial
		></app-action-button>
	</div>
</div>
