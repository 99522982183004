<button
	mat-button
	class="action-button action-button-primary export-button"
	#menuOption="matMenuTrigger"
	[matMenuTriggerFor]="menu"
	[disabled]="disabled"
	(menuOpened)="(menuOption.menuOpen)"
	(menuClosed)="(menuOption.menuOpen)"
>
	<span class="export-button-text">{{ "export selected" | i18next: { format: "capitalize" } }}</span>
	<span class="divider"></span>
	<mat-icon>{{ menuOption.menuOpen ? "arrow_drop_up" : "arrow_drop_down" }}</mat-icon>
</button>
<mat-menu
	xPosition="before"
	class="menu"
	#menu="matMenu"
>
	<button
		mat-menu-item
		class="menu-export-button"
		(click)="exportCSV()"
	>
		{{ "export CSV" | i18next: { format: "capitalize" } }}
	</button>
	<button
		mat-menu-item
		class="menu-export-button"
		(click)="exportPDF()"
	>
		{{ "export PDF" | i18next: { format: "capitalize" } }}
	</button>
	<button
		*ngIf="showDetailedExport"
		mat-menu-item
		class="menu-export-button"
		(click)="exportDetailedPDF()"
	>
		{{ "export detailed PDF" | i18next: { format: "title" } }}
	</button>
</mat-menu>
