import { DAY, HOUR, MINUTE, SECOND } from "src/app/constants/time-constants";

/**
 * Returns the difference between two dates in days, hours, minutes and seconds.
 */
export const timeDifference = (date1: Date) => (date2: Date) => {
	const diff = Math.abs(date2.getTime() - date1.getTime());
	const days = Math.floor(diff / DAY);
	const hours = Math.floor((diff - days * DAY) / HOUR);
	const minutes = Math.floor((diff - days * DAY - hours * HOUR) / MINUTE);
	const seconds = Math.floor((diff - days * DAY - hours * HOUR - minutes * MINUTE) / SECOND);

	return { days: days, hours: hours, minutes: minutes, seconds: seconds };
};
