import { translateAndFormat } from "src/app/i18next";

/**
 * Get Resolution Type text
 * @param repairStatus repair status value
 * @returns Pending, Repair not needed, Repaired, or empty string depending on the repair status
 */
export const getResolutionType = (repairStatus: string) => {
	switch (repairStatus) {
		case "pending":
			return translateAndFormat("pending", "capitalize");
		case "ignored":
			return translateAndFormat("repair not needed", "capitalize");
		case "repaired":
			return translateAndFormat("repaired", "capitalize");
		default:
			return "";
	}
};
