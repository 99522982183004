<div
	mat-dialog-title
	fxLayout="row"
	fxLayoutAlign="space-between start"
>
	<div>
		{{ "export inspections" | i18next: { format: "title" } }}
	</div>
	<img
		id="clear"
		class="pointer"
		src="assets/ic_clear.svg"
		(click)="closeDialog('')"
	/>
</div>

<div
	mat-dialog-content
	[innerHTML]="
		'do you want to export all (totalInspectionCount) or just the (selectedInspectionCount) selected?'
			| i18next
				: {
						format: 'capitalize',
						totalInspectionCount: totalInspectionCount | localizedNumber,
						selectedInspectionCount: selectedInspectionCount,
						postProcess: ['markdownPostProcessor']
				  }
	"
></div>

<br />
<br />

<div
	mat-dialog-actions
	align="end"
>
	<app-action-button
		title="export selected only"
		(buttonClicked)="closeDialog('export-selected')"
		[style.marginRight.px]="8"
		cdkFocusInitial
	></app-action-button>

	<app-action-button
		title="export all"
		(buttonClicked)="closeDialog('export-all')"
		[format]="'title'"
	></app-action-button>
</div>
