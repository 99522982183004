<span
	#dateRangeFilter
	class="date-range-filter"
>
	<button
		mat-button
		[matMenuTriggerFor]="dateRangeMenu"
		class="filter-button"
		[ngClass]="filterButtonClass"
		data-testid="filter-button"
		(menuOpened)="handleMenuOpen()"
	>
		<span
			class="filter-button-label filter-button-label-date-range"
			[ngClass]="filterButtonLabelClass"
		>
			{{ "date range" | i18next: { format: "capitalize" } }}
		</span>
		<span class="date-range-button-text">{{ filterButtonText }}</span>
		<mat-icon>{{ buttonIconValue }}</mat-icon>
	</button>

	<mat-menu
		#dateRangeMenu
		xPosition="after"
		class="mat-menu-panel-alt"
		(closed)="handleMenuClose()"
	>
		<div
			class="containerX"
			(click)="$event.stopPropagation()"
		>
			<div
				fxLayout="row"
				class="date-range-controls-box"
			>
				<div fxFlex>
					<div class="date-range-radio-group-container">
						<form novalidate>
							<mat-radio-group
								class="date-range-radio-group"
								[(ngModel)]="state.selectedDateRange"
								name="dateRange"
								[attr.aria-label]="'date range' | i18next: { format: 'title' }"
								(change)="matRadioChange($event)"
								(click)="matRadioClick($event)"
								*ngIf="state"
							>
								<mat-radio-button
									id="date-range-filter-radio-button-today"
									class="date-range-radio-button"
									[value]="dateRange.today"
								>
									{{ "today" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-7"
									class="date-range-radio-button"
									[value]="dateRange.previous7"
								>
									{{ "previous 7 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-14"
									class="date-range-radio-button"
									[value]="dateRange.previous14"
								>
									{{ "previous 14 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-30"
									class="date-range-radio-button"
									[value]="dateRange.previous30"
								>
									{{ "previous 30 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-365"
									class="date-range-radio-button"
									[value]="dateRange.previous365"
								>
									{{ "previous 365 days" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
								<mat-radio-button
									id="date-range-filter-radio-button-custom"
									class="date-range-radio-button"
									[value]="dateRange.custom"
								>
									{{ "custom" | i18next: { format: "capitalize" } }}
								</mat-radio-button>
								<br />
							</mat-radio-group>
						</form>
					</div>
				</div>

				<div fxFlex>
					<div class="sat-calenar-with-label-container">
						<div class="sat-calendar-label-container">
							<label>{{ "date range" | i18next: { format: "capitalize" } }}</label>
						</div>

						<div class="sat-calendar-container">
							<div class="inlineCalendarContainer">
								<sat-calendar
									#calendar
									[beginDate]="potentialDateRangeBeginDate"
									[endDate]="potentialDateRangeEndDate"
									[rangeMode]="true"
									(dateRangesChange)="inlineRangeChange()"
									(click)="inlineRangeClick($event)"
								></sat-calendar>
							</div>
						</div>

						<div class="filter-buttons-container">
							<app-action-button
								type="outlined-1"
								title="cancel"
								(buttonClicked)="filterCancel()"
								[style.marginRight.px]="16"
							></app-action-button>

							<app-action-button
								title="apply"
								(buttonClicked)="filterSubmit()"
							></app-action-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-menu>
</span>
