<div class="no-results-container">
	<div
		class="no-results-card"
		fxLayout="column"
		fxLayoutAlign="center start"
	>
		<div class="no-results-title">
			{{ "no results found" | i18next: { format: "capitalize" } }}
		</div>
		<div>
			{{ message }}
		</div>
	</div>
</div>
