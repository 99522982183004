<div
	class="error-page"
	fxLayout="row"
	fxLayoutAlign="space-between start"
>
	<div
		class="page-text"
		fxLayout="column"
		fxLayoutGap="16px"
	>
		<div class="error-header">
			{{ errorHeader }}
		</div>
		<div class="error-subheader">
			{{ errorSubHeader }}
		</div>

		<div
			*ngIf="contactSubheader"
			class="error-contact-text"
		>
			{{ contactSubheader }}
		</div>

		<div
			fxLayout="row"
			fxFlexFill
		>
			<button
				mat-button
				class="action-button action-button-error"
				(click)="actionButtonLink ? routeHandler() : logoutHandler()"
			>
				{{ actionButtonText }}
			</button>
		</div>

		<!-- support contact information -->
		<div
			*ngIf="contactSubheader"
			class="support-links"
			fxLayout="column"
			fxLayoutGap="20px"
		>
			<div>{{ supportPhoneText }}: {{ supportPhoneNumber }}</div>
			<div>
				{{ supportEmailText }}:
				<a [href]="mailTo">{{ supportEmail }}</a>
			</div>
		</div>

		<!-- support technical information -->
		<ng-container *ngIf="isHttpError">
			<div
				*ngFor="let technicalDetail of supportArray"
				class="technical-information"
				fxLayout="column"
				fxLayoutGap="10px"
			>
				<div>{{ technicalDetail.httpInformationLabel }}: {{ technicalDetail.httpInformationContent }}</div>
			</div>
		</ng-container>
	</div>

	<div
		class="logo"
		fxFlex
	>
		<a href="https://www.zonarsystems.com/">
			<img src="assets/brand_logo.svg" />
		</a>
	</div>
</div>
