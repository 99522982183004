import { ICompany } from "@zonar-ui/auth/lib/models/company.model";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { isDefined } from "../isDefined/isDefined";

/**
 *  Get the current company context from the PermissionsService and filter the duplicate values
 * @param getCurrentCompanyContext getCurrentCompanyContext from PermissionsService
 * @returns An Observable of the current company context that contains the loginMode and id
 */
export const getCompanyContext = <Company extends Partial<ICompany>>(getCurrentCompanyContext: Observable<Company>) =>
	getCurrentCompanyContext.pipe(
		filter<Company | undefined>(company => isDefined(company) && Object.keys(company).length > 1),
		distinctUntilChanged<Company>(({ id: previous }, { id: current }) => previous === current),
	);
