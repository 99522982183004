import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import { tap } from "rxjs/operators";
import { isEmptyObject } from "src/utils/isEmptyObject/isEmptyObject";
import { SetNavState } from "./components/errors/state/error.state";
import { SearchTable } from "./components/get-table-filter/get-table-filter.component";
import { navErrorConstants } from "./constants/error-constants";
import { Application } from "./models/core-api.models";
import {
	Asset,
	ConfigGet,
	EvirDivisionsGetInner,
	InspectionNames,
	InspectionTypes,
	InspectionTypesResponse,
	InspectorsInner,
	LangDictGet,
	SettingsGet,
	ZoneLayouts,
} from "./models/openAPIAliases";
import { GlobalApiCallsService } from "./services/global-api-calls.service";
import { LoadTableRequest } from "./services/local-store/local-store.service";
import { UserNotification } from "./services/notification/notification.service";
import { PageInformation } from "./services/previous-page.service";
import { SettingsService } from "./services/settings.service";

export interface AppStateModel {
	application: Application;
	assets: Array<Asset>;
	assetTypeImage: SafeHtml;
	assetTypes: ZoneLayouts;
	companyDivisions: Array<IDivision>;
	companyHasTCU: boolean;
	config: ConfigGet;
	divisions: Array<EvirDivisionsGetInner>;
	inspectionNames: InspectionNames;
	inspectionTypes: InspectionTypes;
	inspectors: InspectorsInner[];
	languageDictionary: LangDictGet;
	loadTableRequest: LoadTableRequest;
	notification: UserNotification;
	pageInformation: PageInformation;
	searchTable: ReadonlyArray<SearchTable>;
	selectedCompanyId: string;
	settings: SettingsGet;
	user: IUser;
	userDivisions: Array<IDivision>;
	userHasAllDivisions: boolean;
}

export class SetUserDivisions {
	static readonly type = "[App] Set User Divisions";
	constructor(public divisions: Array<IDivision>) {}
}
export class SetUserHasAllDivisions {
	static readonly type = "[App] User Has All Divisions";
	constructor(public hasAll: boolean) {}
}

export class SetSearchTable {
	static readonly type = "[App] Set Search Table";
	constructor(public searchTable: ReadonlyArray<SearchTable>) {}
}

export class SetUserObject {
	static readonly type = "[App] Set User Object";
	constructor(public user: IUser) {}
}

export class SetSelectedDivision {
	static readonly type = "[App] Set Selected Division";
	constructor(public divisionId: string) {}
}

export class SetCompanyHasTCU {
	static readonly type = "[App] Set if Company has TCU";
	constructor(public companyHasTCU: boolean) {}
}

export class GetApplicationObject {
	static readonly type = "[App] Get Application Object";
	constructor(public applicationId: string, public endpoint: string) {}
}

export class GetLanguageDictionary {
	static readonly type = "[App] Get Language Dictionary";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetInspectionTypes {
	static readonly type = "[App] Get Inspection Types Object";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetAssetTypes {
	static readonly type = "[App] Get Asset Types Object";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetAssets {
	static readonly type = "[App] Get Assets";
	constructor(
		public companyId: string,
		public endpoint: string,
		public hasAllDivisions: boolean,
		public divisionIds?: Array<string>,
	) {}
}

export class GetInspectors {
	static readonly type = "[App] Get Inspectors";
	constructor(
		public companyId: string,
		public endpoint: string,
		public hasAllDivisions: boolean,
		public divisionIds?: Array<string>,
	) {}
}

export class GetDivisions {
	static readonly type = "[App] Get Divisions";
	constructor(
		public companyId: string,
		public endpointCompanies: string,
		public hasAllDivisions: boolean,
		public divisionIds: Array<string>,
	) {}
}

export class SetCompanyDivisions {
	static readonly type = "[App] Set Company Divisions";
	constructor(public companyDivisions: Array<IDivision>) {}
}

export class GetSettings {
	static readonly type = "[App] GET Settings";
	constructor(public companyId: string, public endpoint: string) {}
}

export class PatchSettings {
	static readonly type = "[App] PATCH Settings";
	constructor(public payload: unknown, public endpoint: string) {}
}

export class GetConfig {
	static readonly type = "[App] GET Config";
	constructor(public companyId: string, public endpoint: string) {}
}

export class GetAssetTypeImage {
	static readonly type = "[App] GET Asset Type Image";
	constructor(public companyId: string, public assetViewId: string, public endpoint: string) {}
}

export class SetPageInformation {
	static readonly type = "[App] Set Page Information";
	constructor(public pageInformation: PageInformation) {}
}

export class RequestLoadTable {
	static readonly type = "[LocalStoreService] Request Table Load";
	constructor(public loadTableRequest: LoadTableRequest) {}
}
export class ClearLoadTableRequest {
	static readonly type = "[DbLoaderService] Clear the current Table Load Request";
}
export class NotificationFromDbLoader {
	static readonly type = "[DbLoaderService] Send a Message to the User";
	constructor(public notification: UserNotification) {}
}
export class ClearNotification {
	static readonly type = "[NotificationService] Clear the pending notification";
}

export class SetSelectedCompany {
	static readonly type = "[App] Set Selected Company Id";
	constructor(public companyId: string) {}
}

@Injectable()
@State<AppStateModel>({
	name: "app",
})
export class AppState {
	constructor(
		private globalApiCallsService: GlobalApiCallsService,
		private settingsService: SettingsService,
		private router: Router,
		private store: Store,
	) {}

	@Selector()
	static getAppState(state: AppStateModel) {
		return state;
	}

	@Selector()
	static selectUser(state: AppStateModel) {
		return state.user;
	}

	@Selector()
	static selectUserHasAllDivisions(state: AppStateModel) {
		return state.userHasAllDivisions;
	}

	@Selector()
	static selectUserDivisions(state: AppStateModel) {
		return state.userDivisions;
	}

	// might not be needed, because defectlist is getting into the state another way
	@Selector()
	static selectApplication(state: AppStateModel) {
		return state.application;
	}

	@Selector()
	static selectLanguageDictionary(state: AppStateModel) {
		return state.languageDictionary;
	}

	@Selector()
	static selectInspectionTypes(state: AppStateModel) {
		return state.inspectionTypes;
	}

	@Selector()
	static selectInspectionNames(state: AppStateModel) {
		return state.inspectionNames;
	}

	@Selector()
	static selectAssetTypes(state: AppStateModel) {
		return state.assetTypes;
	}

	@Selector()
	static selectAssets(state: AppStateModel) {
		return state.assets;
	}

	@Selector()
	static selectInspectors(state: AppStateModel) {
		return state.inspectors;
	}

	@Selector()
	static selectDivisions(state: AppStateModel) {
		return state.divisions;
	}

	@Selector()
	static selectSettings(state: AppStateModel) {
		return state.settings;
	}

	@Selector()
	static selectConfig(state: AppStateModel) {
		return state.config;
	}

	@Selector()
	static selectAssetTypeImage(state: AppStateModel) {
		return state.assetTypeImage;
	}

	@Selector()
	static selectPageInformation(state: AppStateModel) {
		return state.pageInformation;
	}

	@Selector()
	static selectCompanyHasTCU(state: AppStateModel) {
		return state.companyHasTCU;
	}

	@Selector()
	static getLoadTableRequest(state: AppStateModel) {
		return { ...state.loadTableRequest };
	}

	@Selector()
	static getNotification(state: AppStateModel) {
		return { ...state.notification };
	}

	@Selector()
	static getSearchTables(state: AppStateModel) {
		return state.searchTable;
	}

	@Selector()
	static getCompanyDivisions(state: AppStateModel) {
		return state.companyDivisions;
	}

	@Selector()
	static getSelectedCompanyId(state: AppStateModel) {
		return state.selectedCompanyId;
	}

	@Action(SetUserHasAllDivisions)
	setUserHasAllDivisions({ patchState }: StateContext<AppStateModel>, { hasAll }: SetUserHasAllDivisions) {
		patchState({
			userHasAllDivisions: hasAll,
		});
	}

	@Action(SetUserDivisions)
	setUserDivisions({ patchState }: StateContext<AppStateModel>, { divisions }: SetUserDivisions) {
		patchState({
			userDivisions: divisions,
		});
	}

	@Action(SetSearchTable)
	setSearchTables({ patchState }: StateContext<AppStateModel>, { searchTable }: SetSearchTable) {
		patchState({
			searchTable: searchTable,
		});
	}

	@Action(SetUserObject)
	setUserObject({ patchState }: StateContext<AppStateModel>, action: SetUserObject) {
		patchState({ user: action.user });
	}

	@Action(SetCompanyHasTCU)
	setCompanyHasTCU({ patchState }: StateContext<AppStateModel>, { companyHasTCU }: SetCompanyHasTCU) {
		patchState({
			companyHasTCU: companyHasTCU,
		});
	}

	@Action(GetApplicationObject)
	getApplicationObject({ patchState }: StateContext<AppStateModel>, action: GetApplicationObject) {
		return this.globalApiCallsService.getApplication(action.applicationId, action.endpoint).pipe(
			tap((result: Application) => {
				// if there's no application object
				if (isEmptyObject(result)) {
					this.store.dispatch(
						new SetNavState({
							http: false,
							type: navErrorConstants.NAV_ERROR_NO_APPLICATION,
						}),
					);
					this.router.navigate(["server-error"]);
				}

				patchState({
					application: result,
				});
			}),
		);
	}

	@Action(GetLanguageDictionary)
	getLanguageDictionary({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetLanguageDictionary) {
		return this.globalApiCallsService.getLanguageDictionary(companyId, endpoint).pipe(
			tap(langDic => {
				patchState({
					languageDictionary: langDic,
				});
			}),
		);
	}

	@Action(GetInspectionTypes)
	getInspectionTypes({ patchState }: StateContext<AppStateModel>, action: GetInspectionTypes) {
		return this.globalApiCallsService.getInspectionTypes(action.companyId, action.endpoint).pipe(
			tap((result: InspectionTypesResponse) => {
				patchState({
					inspectionTypes: result[0], // The inspection types will be at the zeroth index
					inspectionNames: result[1], // The inspection names will be at the first index
				});
			}),
		);
	}

	@Action(GetAssetTypes)
	getAssetTypes({ patchState }: StateContext<AppStateModel>, action: GetAssetTypes) {
		return this.globalApiCallsService.getAssetTypes(action.companyId, action.endpoint).pipe(
			tap((result: ZoneLayouts) => {
				patchState({
					assetTypes: result,
				});
			}),
		);
	}

	@Action(GetAssets)
	getAssets({ patchState }: StateContext<AppStateModel>, action: GetAssets) {
		return this.globalApiCallsService
			.getAssets(action.companyId, action.endpoint, action.hasAllDivisions, action.divisionIds)
			.pipe(
				tap((result: Asset[]) => {
					patchState({
						assets: result,
					});
				}),
			);
	}

	@Action(GetInspectors)
	getInspectors({ patchState }: StateContext<AppStateModel>, action: GetInspectors) {
		return this.globalApiCallsService
			.getInspectors(action.companyId, action.endpoint, action.hasAllDivisions, action.divisionIds)
			.pipe(
				tap((result: InspectorsInner[]) => {
					patchState({
						inspectors: result,
					});
				}),
			);
	}

	@Action(GetDivisions)
	getDivisions(
		{ patchState }: StateContext<AppStateModel>,
		{ companyId, endpointCompanies, hasAllDivisions, divisionIds }: GetDivisions,
	) {
		return this.globalApiCallsService
			.getDivisionsLoader({ companyId, endpointCompanies, hasAllDivisions, divisionIds })
			.pipe(
				tap(result => {
					patchState({
						divisions: result,
					});
				}),
			);
	}

	@Action(SetCompanyDivisions)
	setCompanyDivisions({ patchState }: StateContext<AppStateModel>, action: SetCompanyDivisions) {
		patchState({
			companyDivisions: action.companyDivisions,
		});
	}

	@Action(GetSettings)
	getSettings({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetSettings) {
		return this.settingsService.getSettings(companyId, endpoint).pipe(
			tap((response: SettingsGet[]) => {
				patchState({
					settings: response[0], // Eric said the first response in the list will be the one we want
				});
			}),
		);
	}

	@Action(PatchSettings)
	patchSettings({ patchState }: StateContext<AppStateModel>, { payload, endpoint }: PatchSettings) {
		// one of the settings payloads in api-payloads.models.ts
		return this.settingsService.patchSettings(payload, endpoint).pipe(
			tap((response: SettingsGet) => {
				patchState({
					settings: response,
				});
			}),
		);
	}

	@Action(GetConfig)
	getConfig({ patchState }: StateContext<AppStateModel>, { companyId, endpoint }: GetConfig) {
		return this.globalApiCallsService.getConfig(companyId, endpoint).pipe(
			tap(([config]: ConfigGet[]) => {
				// We're assuming the first config in the array is the most up-to-date, Eric confirmed
				patchState({ config: config });
			}),
		);
	}

	@Action(GetAssetTypeImage)
	getAssetTypeImage(
		{ patchState }: StateContext<AppStateModel>,
		{ companyId, assetViewId, endpoint }: GetAssetTypeImage,
	) {
		return this.settingsService.getAssetTypeImage(companyId, assetViewId, endpoint).pipe(
			tap(response => {
				// using any type for image
				patchState({
					assetTypeImage: response,
				});
			}),
		);
	}

	@Action(SetPageInformation)
	setPageInformation({ patchState }: StateContext<AppStateModel>, { pageInformation }: SetPageInformation) {
		patchState({
			pageInformation: pageInformation,
		});
	}

	@Action(RequestLoadTable)
	requestDbTableLoad({ patchState }: StateContext<AppStateModel>, { loadTableRequest }: RequestLoadTable) {
		patchState({
			loadTableRequest: loadTableRequest,
		});
	}

	@Action(ClearLoadTableRequest)
	clearRequestDbTableLoad({ patchState }: StateContext<AppStateModel>) {
		patchState({
			loadTableRequest: null,
		});
	}

	@Action(NotificationFromDbLoader)
	notifyUserFromDbLoader({ patchState }: StateContext<AppStateModel>, { notification }: NotificationFromDbLoader) {
		patchState({
			notification: notification,
		});
	}

	@Action(ClearNotification)
	clearNotification({ patchState }: StateContext<AppStateModel>) {
		patchState({
			notification: null,
		});
	}

	@Action(SetSelectedCompany)
	setSelectedCompany({ patchState }: StateContext<AppStateModel>, { companyId }: SetSelectedCompany) {
		patchState({
			selectedCompanyId: companyId,
		});
	}
}
