import { Component, Input } from "@angular/core";

export type ColumnType = "select" | "single-value" | "multiple-values" | "button";

@Component({
	selector: "app-column",
	templateUrl: "./column.component.html",
	styleUrls: ["./column.component.scss"],
})
export class ColumnComponent {
	@Input()
	id: string;

	@Input()
	type: ColumnType;

	@Input()
	name: string;

	@Input()
	sortable = false;

	@Input()
	data: string;

	@Input()
	view: string;

	@Input()
	extraData: string | null = null;

	@Input()
	routerLinkData: string;

	@Input()
	customStyle: string;
}
