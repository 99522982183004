import { translateAndFormat, translate } from "../i18next";
import i18next from "i18next";

export const navErrorConstants = {
	NAV_ERROR_NOT_FOUND: "not found",
	NAV_ERROR_NO_DIVISIONS: "no divisions",
	NAV_ERROR_SERVER_ERROR: "server error",
	NAV_ERROR_MAINTENANCE: "maintenance",
	NAV_ERROR_NO_USER: "no user",
	NAV_ERROR_NO_ACCESS: "no access",
	NAV_ERROR_NO_APPLICATION: "no application",
	NAV_ERROR_INACTIVE: "inactive",
	NAV_ERROR_UNKNOWN: "unknown",
};

export const timeoutErrorConstants = {
	TIMEOUT_MS: 20000,
	TIMEOUT_HEADER: "",
	TIMEOUT_MESSAGE: "",
};

export const httpPageNotFoundConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

export const httpServerErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

export const httpMaintenanceConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

export const noDivisionsConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};
// no application was returned from api
export const noApplicationErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

// no user or userprofiles were returned from api
export const noUserOrProfilesErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

// user profile is inactive
export const inactiveUserErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

// client doesn't have permissions to view certain pages
export const clientUnauthorizedErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
	ERROR_ACTION_BUTTON_LINK: "/inspection-list",
};

// client types in garbage route
export const clientPageNotFoundConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_ACTION_BUTTON_TEXT: "",
	ERROR_ACTION_BUTTON_LINK: "/inspection-list",
};

export const defaultErrorConstants = {
	ERROR_HEADER: "",
	ERROR_SUBHEADER: "",
	ERROR_CONTACT_US: "",
	ERROR_ACTION_BUTTON_TEXT: "",
};

i18next.on("initialized", () => {
	timeoutErrorConstants.TIMEOUT_HEADER = translateAndFormat("timeout error", "title");
	timeoutErrorConstants.TIMEOUT_MESSAGE = `${translateAndFormat(
		"your request has timed out",
		"capitalize",
	)} ${translateAndFormat(
		"please request a smaller set of data or check your internet connection and try again",
		"capitalize",
	)}`;

	httpPageNotFoundConstants.ERROR_HEADER = translateAndFormat("we'd be there for you if we could", "capitalize");
	httpPageNotFoundConstants.ERROR_SUBHEADER = translateAndFormat(
		"sorry, but it looks like we couldn’t find the page you were looking for",
		"capitalize",
	);
	httpPageNotFoundConstants.ERROR_CONTACT_US = translateAndFormat(
		"if the problem persists, please contact support through phone or email with the technical information provided below",
		"capitalize",
	);
	httpPageNotFoundConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("try again", "title");

	httpServerErrorConstants.ERROR_HEADER = translateAndFormat("sorry, this looks to be our fault", "capitalize");
	httpServerErrorConstants.ERROR_SUBHEADER = `${translateAndFormat(
		"for some reason we’ve encountered a technical glitch",
		"capitalize",
	)} ${translateAndFormat("please try again in a few minutes", "capitalize")}`;
	httpServerErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"if the problem persists, please contact support through phone or email with the technical information provided below",
		"capitalize",
	);
	httpServerErrorConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("try again", "title");

	httpMaintenanceConstants.ERROR_HEADER = translateAndFormat("sorry, we're down for maintenance", "capitalize");
	httpMaintenanceConstants.ERROR_SUBHEADER = translateAndFormat("please try again in a few minutes", "capitalize");
	httpMaintenanceConstants.ERROR_CONTACT_US = translateAndFormat(
		"if the problem persists, please contact support through phone or email with the technical information provided below",
		"capitalize",
	);
	httpMaintenanceConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("try again", "title");

	noDivisionsConstants.ERROR_HEADER = translateAndFormat(
		"your account has not been assigned any divisions",
		"capitalize",
	);
	noDivisionsConstants.ERROR_SUBHEADER = translateAndFormat("please contact your administrator", "capitalize");
	noDivisionsConstants.ERROR_CONTACT_US = translateAndFormat(
		"if the problem persists, please contact support through phone or email",
		"capitalize",
	);

	// no application was returned from api
	noApplicationErrorConstants.ERROR_HEADER = translateAndFormat("we'd be there for you if we could", "capitalize");
	noApplicationErrorConstants.ERROR_SUBHEADER = translateAndFormat(
		"the application you have requested no longer exists",
		"capitalize",
	);
	noApplicationErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"please contact support through phone or email provided below",
		"capitalize",
	);
	noApplicationErrorConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("logout", "capitalize");

	// no user or userprofiles were returned from api
	noUserOrProfilesErrorConstants.ERROR_HEADER = translateAndFormat("we would, if we could", "capitalize");
	noUserOrProfilesErrorConstants.ERROR_SUBHEADER = `${translateAndFormat(
		"sorry, but we couldn't find your user or profile information",
		"capitalize",
	)} ${translateAndFormat("you’ll need to request access from your Zonar admin", "capitalize")}`;
	noUserOrProfilesErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"please contact support through phone or provided email below",
		"capitalize",
	);
	noUserOrProfilesErrorConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("logout", "capitalize");

	// user profile is inactive
	inactiveUserErrorConstants.ERROR_HEADER = translateAndFormat("we would, if we could", "capitalize");
	inactiveUserErrorConstants.ERROR_SUBHEADER = `${translateAndFormat(
		"sorry, but your account is inactive",
		"capitalize",
	)} ${translateAndFormat("you’ll need to request access from your Zonar admin", "capitalize")}`;
	inactiveUserErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"please contact support through phone or email provided below",
		"capitalize",
	);
	inactiveUserErrorConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("logout", "capitalize");

	// client doesn't have permissions to view certain pages
	clientUnauthorizedErrorConstants.ERROR_HEADER = translateAndFormat("we would, if we could", "capitalize");
	clientUnauthorizedErrorConstants.ERROR_SUBHEADER = `${translateAndFormat(
		"sorry, but it looks like you don’t have permission to access this page",
		"capitalize",
	)} ${translateAndFormat("you’ll need to request access from your Zonar admin", "capitalize")}`;
	clientUnauthorizedErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"please contact support through phone or email provided below",
		"capitalize",
	);
	clientUnauthorizedErrorConstants.ERROR_ACTION_BUTTON_TEXT = translate("go to inspection history");

	// client types in garbage route
	clientPageNotFoundConstants.ERROR_HEADER = translateAndFormat("we'd be there for you if we could", "capitalize");
	clientPageNotFoundConstants.ERROR_SUBHEADER = translateAndFormat(
		"sorry, but it looks like we couldn’t find the page you were looking for",
		"capitalize",
	);
	clientPageNotFoundConstants.ERROR_ACTION_BUTTON_TEXT = translate("go to inspection history");

	defaultErrorConstants.ERROR_HEADER = translateAndFormat("we would, if we could", "capitalize");
	defaultErrorConstants.ERROR_SUBHEADER = translateAndFormat("something went wrong", "capitalize");
	defaultErrorConstants.ERROR_CONTACT_US = translateAndFormat(
		"if the problem persists, please contact support through phone or email with the technical information provided below",
		"capitalize",
	);
	defaultErrorConstants.ERROR_ACTION_BUTTON_TEXT = translateAndFormat("try again", "title");
});
