import { defaultInterpolationFormat, I18NextModule } from "angular-i18next";
import { InitOptions } from "i18next";

export const getSupportedLng = ({
	locales = ["en-US", "en-GB", "fr-FR", "it-IT", "de-DE", "es-ES"],
	language = locales[0],
}: {
	locales?: ReadonlyArray<string>;
	language?: string;
}) =>
	locales.includes(language)
		? language
		: locales.find(locale => locale.split("-")[0] === language.split("-")[0]) ?? locales[0];

/*
 * Platform and Environment providers/directives/pipes
 */
export const i18nextOptions: InitOptions = {
	// debug: true,
	fallbackLng: false,
	load: "currentOnly",
	returnEmptyString: false,
	interpolation: {
		format: I18NextModule.interpolationFormat((value: string, format: string, lng: string) => {
			if (format === "title") {
				return value
					.split(" ")
					.map(word => word.charAt(0).toUpperCase() + word.slice(1))
					.join(" ");
			} else if (format === "camelCase") {
				return value
					.split(" ")
					.map((word, index) =>
						index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1),
					)
					.join("");
			} else {
				return defaultInterpolationFormat(value, format, lng);
			}
		}),
	},
	// backend plugin options
	backend: {
		loadPath: ([language]) => `locales/${getSupportedLng({ language })}.json`,
	},
	// lang detection plugin options
	detection: {
		// order and from where user language should be detected
		order: ["navigator", "localStorage"],
		// cache user language on
		caches: ["localStorage"],
	},
};
