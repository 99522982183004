import { ITranslationService } from "angular-i18next";
import I18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import MarkdownIt from "markdown-it";
import { i18nextOptions } from "./i18nextOptions";

const markdown = new MarkdownIt();

const markdownPostProcessor = {
	name: "markdownPostProcessor",
	process: (value: string) => {
		return markdown.renderInline(value);
	},
	type: "postProcessor",
} as const;

/**
 * Generic i18next setup (for Angular service and i18next).
 */
export const setupI18Next = (
	i18next: ITranslationService | typeof I18next, // Adding `typeof I18next` type for tests
) => i18next.use(HttpApi).use(LanguageDetector).use(markdownPostProcessor).init(i18nextOptions);
