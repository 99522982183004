export function toZuluTimeStr(dateParts: string[]) {
	if (dateParts.length !== 2) {
		throw new Error("Invalid input format. Expected an array with 2 strings.");
	}

	// Extract the date and time parts from the input array
	const [dateStr, timeStr] = dateParts;
	// Remove parentheses from time string
	const cleanTimeStr = timeStr.replace(/[()]/g, "");

	// Combine date and time strings into one, assuming the date is in MM/DD/YYYY format
	const dateTimeStr = `${dateStr} ${cleanTimeStr}`;

	// Create a Date object
	const dateObj = new Date(dateTimeStr);

	// Convert to Zulu time
	const zuluTimeStr = dateObj.toISOString();

	const encodedZuluTimeStr = encodeURIComponent(zuluTimeStr);

	return encodedZuluTimeStr;
}
