<span
	#defectTypeFilter
	class="defect-type-filter"
>
	<button
		mat-button
		class="filter-button defect-type-filter-button"
		[matMenuTriggerFor]="defectTypeMenu"
		[ngClass]="filterButtonClass"
		(menuOpened)="handleMenuOpen()"
	>
		<span class="filter-button-label filter-button-label-defect-type filter-button-label-not-focused">
			{{ "status" | i18next: { format: "capitalize" } }}
		</span>
		<span
			class="filter-button-text defect-type-filter-button-text filter-button-text-value-selected"
			[ngClass]="filterButtonTextClass"
		>
			{{ selectedDefectType | i18next: { format: "capitalize" } }}
		</span>
		<mat-icon class="icon">{{ buttonIconValue }}</mat-icon>
	</button>
	<mat-menu
		#defectTypeMenu="matMenu"
		xPosition="after"
		class="defect-type-menu"
		(closed)="handleMenuClose()"
	>
		<button
			mat-menu-item
			class="defect-type-option-button"
			[ngClass]="{ 'defect-type-option-selected': selectedDefectType === 'open' }"
			(click)="filterBy('open')"
		>
			{{ "open" | i18next: { format: "capitalize" } }}
		</button>
		<button
			mat-menu-item
			class="defect-type-option-button"
			[ngClass]="{ 'defect-type-option-selected': selectedDefectType === 'closed' }"
			(click)="filterBy('closed')"
		>
			{{ "closed" | i18next: { format: "capitalize" } }}
		</button>
	</mat-menu>
</span>
