<div class="image-dialog">
	<div
		mat-dialog-title
		fxLayout="row"
		fxLayoutAlign="space-between start"
	>
		<div>
			{{ modalTitle }}
		</div>
		<img
			id="clear"
			class="pointer"
			src="assets/ic_clear.svg"
			(click)="closeDialog()"
		/>
	</div>
	<div
		(window:keydown)="handleKeypress($event)"
		mat-dialog-content
	>
		<div *ngIf="currentImages[imageIndex]; then showImage; else showSpinner"></div>

		<ng-template #showImage>
			<div id="carousel-image-wrapper">
				<img
					class="image"
					[alt]="modalTitle"
					[src]="currentImages[imageIndex].src"
					(error)="currentImages[imageIndex].src = currentImages[imageIndex].fallback"
				/>
			</div>
		</ng-template>

		<ng-template #showSpinner>
			<div
				class="spinner-card"
				fxLayoutAlign="center center"
			>
				<mat-spinner [diameter]="200"></mat-spinner>
			</div>
		</ng-template>
	</div>
	<div
		*ngIf="!isOnlyImage && currentImages[imageIndex]"
		class="arrow-left"
		(click)="previousImage()"
	>
		<mat-icon class="left-icon">keyboard_arrow_left</mat-icon>
	</div>
	<div
		*ngIf="!isOnlyImage && currentImages[imageIndex]"
		class="arrow-right"
		(click)="nextImage()"
	>
		<mat-icon class="right-icon">keyboard_arrow_right</mat-icon>
	</div>
</div>
