import { Component, Input } from "@angular/core";

@Component({
	selector: "app-character-counter",
	templateUrl: "./character-counter.component.html",
	styleUrls: ["./character-counter.component.scss"],
})
export class CharacterCounterComponent {
	@Input() currentLength: number;
	@Input() maxLength: number;
}
