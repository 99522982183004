import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-pdf-export-dialog",
	templateUrl: "./pdf-export-dialog.component.html",
	styleUrls: ["./pdf-export-dialog.component.scss"],
})
export class PdfExportDialogComponent implements OnInit {
	totalInspectionCount = 0;
	selectedInspectionCount = 0;

	constructor(
		public dialogRef: MatDialogRef<PdfExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { selectedInspectionCount: number; totalInspectionCount: number },
	) {}

	ngOnInit() {
		this.totalInspectionCount = this.data.totalInspectionCount;
		this.selectedInspectionCount = this.data.selectedInspectionCount;
	}

	closeDialog(result: string): void {
		this.dialogRef.close(result);
	}
}
