import { environment } from "src/environments/environment";

export const MockSidenavMenuConfiguration: Array<unknown> = [
	{
		text: "Inspection history",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/inspection-list",
		routerLink: "/inspection-list",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "Missing Inspections",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/missing-inspections",
		routerLink: "/missing-inspections",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "Inspection Defects",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/defects",
		routerLink: "/defects",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
	{
		text: "EVIR Settings",
		icon: "",
		appId: environment.environmentConstants.APP_APPLICATION_ID,
		route: "/settings",
		routerLink: "/settings",
		isExpanded: false,
		routeLevel: 1,
		children: [],
	},
];
