<div class="container">
	<div
		class="tab"
		*ngFor="let tab of tabs"
		(click)="onClickTab(tab, $event)"
		[ngClass]="{
			active: activeTab && tab.id === activeTab.id
		}"
		[attr.data-testid]="'tab-' + tab.id"
	>
		{{ tab.label }}
	</div>
</div>
