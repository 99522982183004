import { translateAndFormat } from "src/app/i18next";
import {
	InspectionDetailStaticInfoSortedField,
	InspectionDetailStaticInfoViewModel,
} from "src/app/models/inspection-detail-view-models.models";

/**
 * Sort Static Information
 * @param staticInfo Object with the static information to be sorted
 * @param sortingOrder An array of field names in the order they should be sorted
 * @returns An array of objects with the field name and the field content sorted by the sortingOrder array
 */
export const sortStaticInfo = (
	staticInfo: InspectionDetailStaticInfoViewModel,
	sortingOrder: Array<string>,
): Array<InspectionDetailStaticInfoSortedField> => {
	return sortingOrder
		.filter(detailItemField => staticInfo[detailItemField] && staticInfo[detailItemField] !== "")
		.map(detailItemField => ({
			detailItemField: translateAndFormat(detailItemField, "uppercase"),
			detailItemContent: staticInfo[detailItemField],
		}));
};
