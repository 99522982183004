import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { environment } from "src/environments/environment";
import { isDefined } from "src/utils/isDefined/isDefined";
import { ImageModalData } from "../../models/modal-data.models";
import { Media, SignedUrl } from "../../models/openAPIAliases";

@Component({
	selector: "app-image-carousel-dialog",
	templateUrl: "./image-carousel-dialog.component.html",
	styleUrls: ["./image-carousel-dialog.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ImageCarouselDialogComponent implements OnInit {
	media: Media[] = null;
	currentImages: Array<{ fallback: `data:image/${string}`; src: string }> = [];
	imageIndex = 0;
	isOnlyImage = false;
	imageNotFoundText = translateAndFormat("image not found", "title");
	modalTitle = translateAndFormat("defect photo", "capitalize");

	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ImageModalData,
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<ImageCarouselDialogComponent>,
		public store: Store,
	) {}

	xmlToError(xml: string) {
		const xmlDocument = new DOMParser().parseFromString(xml, "application/xml");

		return new (class extends Error {
			name = xmlDocument.querySelector("Code")?.textContent;
			message = `${xmlDocument.querySelector("Message")?.textContent}: ${
				xmlDocument.querySelector("Details")?.textContent
			}`;
		})();
	}

	ngOnInit() {
		this.modalTitle = this.data.title ?? this.modalTitle;
		this.media = this.data.media;
		this.imageIndex = this.data.imageIndex;
		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(
			async id => (
				(this.currentImages = await Promise.all(
					this.media.map(({ mediaId, mediaType, thumbnail }) =>
						this.http
							.get<SignedUrl>(
								`${
									environment.environmentConstants.APP_ENDPOINT_EVIR
								}/evir-media/${mediaId.toLowerCase()}?${new URLSearchParams({
									companyId: id,
									mediaType,
								})}`,
							)
							.toPromise()
							.then(({ signedUrl }) =>
								fetch(signedUrl).then(response =>
									response.ok
										? response
												.blob()
												.then(
													blob =>
														this.sanitizer.bypassSecurityTrustUrl(
															URL.createObjectURL(blob),
														) as string,
												)
										: response.text().then(xml => Promise.reject(this.xmlToError(xml))),
								),
							)
							.then(src => ({ fallback: `data:image/${mediaType};base64,${thumbnail}`, src } as const))
							.catch(error => {
								const fallback = `data:image/${mediaType};base64,${thumbnail}` as const;
								console.error(error);

								return { fallback, src: fallback } as const;
							}),
					),
				)),
				(this.isOnlyImage = this.currentImages.length === 1)
			),
		);
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	previousImage(): void {
		this.imageIndex = this.imageIndex === 0 ? this.currentImages.length - 1 : this.imageIndex - 1;
	}

	nextImage(): void {
		// if on the last image, wrap to the first
		this.imageIndex =
			this.imageIndex === this.currentImages.length - 1 ? (this.imageIndex = 0) : this.imageIndex + 1;
	}

	handleKeypress(event: KeyboardEvent): void {
		if (!this.isOnlyImage) {
			switch (event.key) {
				case "ArrowLeft":
					this.previousImage();
					break;

				case "ArrowRight":
					this.nextImage();
					break;
			}
		}
	}
}
