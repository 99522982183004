import { State, Action, StateContext, Selector } from "@ngxs/store";
import { HttpErrorResponse } from "@angular/common/http";
import { patch, append } from "@ngxs/store/operators";
import { AnalyticsService } from "src/app/services/analytics.service";
import { Injectable } from "@angular/core";

export class HttpErrorModel {
	/** core api and evir api error models differ */
	error: unknown;
	status: number;
	url: string;
}

export class NavErrorState {
	http: boolean;
	type: string;
}

export class ErrorStateModel {
	httpErrors: HttpErrorModel[];
	clientErrors: string[];
	navState: NavErrorState;
}

export const errorStateDefaults = {
	httpErrors: [],
	clientErrors: [],
	navState: { http: false, type: "not found" },
};

export class SetHttpError {
	static readonly type = "[Error] Set Http Error";
	constructor(public httpError: HttpErrorResponse) {}
}

export class SetClientError {
	static readonly type = "[Error] Set Client Error";
	constructor(public clientError: unknown) {}
}

export class SetNavState {
	static readonly type = "[Error] Set Nav Error";
	constructor(public navErrorState: NavErrorState) {}
}
@Injectable()
@State<ErrorStateModel>({
	name: "error",
	defaults: errorStateDefaults,
})
export class ErrorState {
	constructor(public analyticsService: AnalyticsService) {}

	@Selector()
	static getHttpErrors(state: ErrorStateModel): HttpErrorModel[] {
		return state.httpErrors;
	}

	@Selector()
	static getClientErrors(state: ErrorStateModel): string[] {
		return state.clientErrors;
	}

	@Selector()
	static getAllErrors(state: ErrorStateModel): ErrorStateModel {
		return state;
	}

	@Selector()
	static getNavErrorState(state: ErrorStateModel): NavErrorState {
		return state.navState;
	}

	@Action(SetHttpError)
	setHttpError({ setState, getState }: StateContext<ErrorStateModel>, { httpError }: SetHttpError) {
		const newHttpError: HttpErrorModel = {
			error: httpError.error,
			url: httpError.url,
			status: httpError.status,
		};

		const state = getState();
		const isDuplicateError = state.httpErrors.some(error => error.url === newHttpError.url);

		if (!isDuplicateError) {
			this.analyticsService.sendGaException(JSON.stringify(newHttpError));

			setState(
				patch({
					httpErrors: append([newHttpError]),
				}),
			);
		}
	}

	@Action(SetClientError)
	setClientError({ setState, getState }: StateContext<ErrorStateModel>, { clientError }: SetClientError) {
		const state = getState();
		const isDuplicateError = state.clientErrors.some(error => error === clientError);

		if (!isDuplicateError) {
			this.analyticsService.sendGaException(clientError);

			setState(
				patch({
					clientErrors: append([clientError as string]),
				}),
			);
		}
	}

	@Action(SetNavState)
	setNavErrorState({ patchState }: StateContext<ErrorStateModel>, { navErrorState }: SetNavState) {
		patchState({
			navState: navErrorState,
		});
	}
}
