import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-export",
	templateUrl: "./export.component.html",
	styleUrls: ["./export.component.scss"],
})
export class ExportComponent {
	@Input() disabled: boolean;
	@Input() showDetailedExport = false;
	@Output() csvExportReport = new EventEmitter();
	@Output() detailedPDFExportReport? = new EventEmitter();
	@Output() pdfExportReport = new EventEmitter();

	public exportCSV() {
		this.csvExportReport.emit();
	}

	public exportDetailedPDF() {
		this.detailedPDFExportReport.emit();
	}

	public exportPDF() {
		this.pdfExportReport.emit();
	}
}
