import { HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { mergeMap, toArray } from "rxjs/operators";
import { RequestService } from "src/app/services/request/request.service";
import { Id, InspectionGet } from "../../../models/openAPIAliases";
import { LoggerService } from "./../../../services/logger.service";
@Injectable({
	providedIn: "root",
})
export class InspectionHistoryService {
	private httpOptions = {
		observe: "response",
	};

	constructor(public logger: LoggerService, public requestService: RequestService) {}

	public getInspectionList(
		companyId: string,
		endpoint: string,
		queryStrings: URLSearchParams,
	): Observable<HttpEvent<InspectionGet[]>> {
		const url = new URL(endpoint);
		url.pathname = `${url.pathname}/inspections`;
		queryStrings.forEach((value, key) => url.searchParams.set(key, value));
		url.searchParams.set("companyId", companyId);

		this.logger.log(`Sending inspections request with query strings: ?companyId=${companyId}${queryStrings}`);
		return this.requestService.get<HttpEvent<InspectionGet[]>>({
			url: url.toString(),
			httpOptions: this.httpOptions,
		});
	}

	public getSingleInspections(inspectionIds: ReadonlyArray<Id>, companyId: Id, endpoint: string) {
		// Maximum number of request being subscribed to concurrently.
		const maxRequestSize = 100;
		return from(inspectionIds).pipe(
			mergeMap((inspectionId: Id) => {
				return this.requestService.get<HttpResponse<InspectionGet>>({
					url: `${endpoint}/inspections/${inspectionId}?companyId=${companyId}`,
					httpOptions: this.httpOptions,
				});
			}, maxRequestSize),
			toArray(),
		);
	}
}
