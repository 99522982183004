export const environment = {
	production: false,
	environment: "qa",
	shadow: false,
	region: "NA",
	brand: {
		copyright: "Zonar Systems",
		"footer-logo-filename": "zonar-footer-logo.svg",
		name: "zonar",
		"support-email": "customercare@zonarsystems.com",
		"support-phone-number": {
			"en-us": "877-843-3847",
			default: "877-843-3847",
		},
	},
	analytics: {
		google: true,
		pendo: true,
	},
	localeConfig: {
		links: {
			enabled: false,
			default: "",
		},
	},
	datadog: {
		applicationId: "cfe1252a-5130-46cc-9e04-1f65c6166cbc",
		clientToken: "pub19548a370a50698453080068542cd4ce",
		site: "us5.datadoghq.com",
	},
	pendo: {
		url: "https://cdn.pendo.io/agent/static/",
		key: "47e63a49-4477-47c2-4ea6-0f032e1785d5",
	},
	environmentConstants: {
		APP_APPLICATION_ID: "37eeab58-1ba9-45a4-b019-776b48a0a5f3",
		APP_ENDPOINT_CORE_BASE: "https://api-qa.zonarsystems.net/core",
		APP_ENDPOINT_CORE: "https://api-qa.zonarsystems.net/core/v1",
		APP_ENDPOINT_EVIR_BASE: "https://api-qa.zonarsystems.net/evir",
		APP_ENDPOINT_EVIR: "https://api-qa.zonarsystems.net/evir/v1",
		APP_ROLE_ASSIGN_MECHANIC: "a631cd4b-b443-4102-a4be-07b79cc6c712",
		APP_ROLE_REPAIR_DEFECT_MECHANIC: "e068e1a3-193a-45c2-be2f-fc41d0b88fb3",
		APP_URL: "https://evir-web.staging.zonarsystems.net",
		ZONAR_MAP_URL: "https://maps.qa.zonarsystems.net/assets/",
	},
	auth0: {
		AUDIENCE: "http://apiqa.zonarsystems.net/",
		CLIENT_ID: "5u1DLJ5PN92kZRArNLC6YXroUvEFRLSw",
		DOMAIN: "qa-login.zonarsystems.net",
	},
	i18n: {
		supportedLanguages: ["en-US", "en-GB", "de-DE", "fr-FR", "es-ES", "it-IT"],
		defaultLanguage: "en-US",
	},
};
