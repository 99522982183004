import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestService } from "src/app/services/request/request.service";
import { Defect } from "../../../models/openAPIAliases";

@Injectable({
	providedIn: "root",
})
export class DefectListService {
	private httpOptions = {
		observe: "response",
	};

	constructor(public requestService: RequestService) {}

	public getDefectList(companyId: string, query: URLSearchParams, endpoint: string): Observable<HttpEvent<Defect[]>> {
		const url = new URL(endpoint);
		url.pathname = `${url.pathname}/defects`;
		query.forEach((value, key) => url.searchParams.set(key, value));
		url.searchParams.set("companyId", companyId);

		return this.requestService.get<HttpEvent<Defect[]>>({
			url: url.toString(),
			httpOptions: this.httpOptions,
		});
	}
}
