<!-- Major Icon -->
<div
	*ngIf="severity === 'major'"
	class="major"
></div>
<!-- Minor Icon -->
<div
	*ngIf="severity === 'minor'"
	class="minor"
></div>
<!-- None Icon -->
<ng-container *ngIf="severity === 'none'">
	<ng-container *ngIf="brandZonar">
		<svg
			width="37px"
			height="32px"
			viewBox="0 0 32 32"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="Severity/Large/None"
					transform="translate(-4.000000, -4.000000)"
				>
					<g id="ic_warning-copy-5">
						<g
							id="Group"
							transform="translate(4.000000, 4.000000)"
						>
							<circle
								id="Oval-5-Copy-2"
								fill="#1D811E"
								cx="16"
								cy="16"
								r="16"
							></circle>
							<polygon
								id="Shape"
								fill="#FFFFFF"
								points="12.7202956 19.8120805 8.45309835 15.7695749 7 17.1364653 12.7202956 22.5555556 25 10.9224459 23.5571347 9.55555556"
							></polygon>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</ng-container>
	<ng-container *ngIf="!brandZonar">
		<svg
			width="37px"
			height="32px"
			viewBox="0 0 32 32"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="Severity/Large/None"
					transform="translate(-4.000000, -4.000000)"
				>
					<g id="ic_warning-copy-5">
						<g
							id="Group"
							transform="translate(4.000000, 4.000000)"
						>
							<circle
								id="Oval-5-Copy-2"
								fill="#2DB928"
								cx="16"
								cy="16"
								r="16"
							></circle>
							<polygon
								id="Shape"
								fill="#FFFFFF"
								points="12.7202956 19.8120805 8.45309835 15.7695749 7 17.1364653 12.7202956 22.5555556 25 10.9224459 23.5571347 9.55555556"
							></polygon>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</ng-container>
</ng-container>
<!-- No Inspected -->
<mat-icon
	*ngIf="severity === 'uninspected'"
	class="uninspected"
>
	remove_circle_outline
</mat-icon>
