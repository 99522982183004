import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const debounceMs = 500;

@Component({
	selector: "app-search-input",
	templateUrl: "./search-input.component.html",
	styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild("input", { static: true })
	input: ElementRef;

	@Input()
	active = false;

	@Input()
	resetInput: boolean;

	@Input()
	debounceMs = 500;

	@Input()
	placeholder: string;

	@Output()
	hasFocus = new EventEmitter();

	@Output()
	inputChanged = new EventEmitter();

	subscriptions = new Subscription();

	inputChanged$ = new Subject<string>();

	displayClearBtn = false;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.resetInput && changes.resetInput.currentValue) {
			this.reset();
		}
	}

	ngOnInit() {
		this.subscriptions.add(
			this.inputChanged$.pipe(debounceTime(debounceMs)).subscribe(() => {
				this.inputChanged.emit(this.input.nativeElement.value);

				this.displayClearBtn = this.input.nativeElement.value.trim().length > 0;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	onFocus() {
		this.active = true;
		this.hasFocus.emit();
	}

	onInput() {
		this.inputChanged$.next();
	}

	reset() {
		this.input.nativeElement.value = "";
		this.displayClearBtn = false;
		this.inputChanged.emit("");
	}

	onClearClicked() {
		this.reset();
	}
}
