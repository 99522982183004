import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";

export type LogInfo = {
	sessionId: string;
};

export interface LogStateModel {
	logInfo: LogInfo;
}

export class SetLogInfo {
	static readonly type = "[Log] Set info for the logger";
	constructor(public logInfo: LogInfo) {}
}
@Injectable()
@State<LogStateModel>({
	name: "log",
})
export class LogState {
	@Selector()
	static selectLogInfo(state: LogStateModel) {
		return state.logInfo;
	}

	@Action(SetLogInfo)
	setLogInfo({ patchState }: StateContext<LogStateModel>, action: SetLogInfo) {
		patchState({
			logInfo: action.logInfo,
		});
	}
}
