<div class="open-pending-card">
	<app-repair-buttons
		[defectSelected]="selectionModel.hasValue()"
		[selection]="selectionModel.selected"
		[canAssignMechanic]="canAssignMechanic"
		(updateState)="updateAssetState($event)"
		class="title-buttons"
	></app-repair-buttons>

	<table
		mat-table
		multiTemplateDataRows
		matSort
		id="asset-open-defect-table"
		[dataSource]="dataSource"
		matSortActive="severity"
		matSortDirection="desc"
		matSortDisableClear="true"
		(matSortChange)="resetAnimations()"
	>
		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let row"
			>
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? selectionModel.toggle(row) : null"
					[checked]="selectionModel.isSelected(row)"
					[aria-label]="checkboxLabel(row)"
					[ngClass]="{ 'rejected-checkbox': isRejectedDefect(row) }"
					[disabled]="isRejectedDefect(row)"
				></mat-checkbox>
			</td>
		</ng-container>

		<!-- Severity Column -->
		<ng-container matColumnDef="severity">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "severity" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				<img [src]="getSeverityIcon(defect.severity)" />
			</td>
		</ng-container>

		<!-- Inspection Type Column -->
		<ng-container matColumnDef="inspectionType">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "inspection" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				<a routerLink="/company/{{ companyId }}/inspection/{{ defect.inspectionId }}">
					{{ defect.inspectionType }}
				</a>
			</td>
		</ng-container>

		<!-- Zone Column -->
		<ng-container matColumnDef="zoneLabel">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "zone" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ defect.zoneLabel }}
			</td>
		</ng-container>

		<!-- Component Column -->
		<ng-container matColumnDef="componentLabel">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "component" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ defect.componentLabel }}
			</td>
		</ng-container>

		<!-- Condition Column -->
		<ng-container matColumnDef="conditionLabel">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "condition" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ defect.conditionLabel }}
			</td>
		</ng-container>

		<!-- Last Inspection Column -->
		<ng-container matColumnDef="lastNoted">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "last inspection" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ formatDate(defect.lastNoted) }}
			</td>
		</ng-container>

		<!-- First Inspection Column -->
		<ng-container matColumnDef="firstNoted">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "first inspection" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ formatDate(defect.firstNoted) }}
			</td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="defectStatus">
			<th
				mat-header-cell
				*matHeaderCellDef
				mat-sort-header
			>
				{{ "status" | i18next: { format: "uppercase" } }}
			</th>
			<td
				mat-cell
				*matCellDef="let defect"
			>
				{{ defect.defectStatus }}
			</td>
		</ng-container>

		<!-- Click to expand hidden column -->
		<ng-container matColumnDef="chevron">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let defect; let i = dataIndex"
			>
				<mat-icon
					id="expand-photo-repair"
					*ngIf="defect.repairs || defect.photos"
					[@rotatedState]="rotateState[i]"
					(click)="showExpansion(defect); rotate(i)"
				>
					expand_more
				</mat-icon>
			</td>
		</ng-container>

		<!-- Expandable notes row -->
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let defect"
				[attr.colspan]="displayedColumns.length"
			>
				<div
					class="expanded-row"
					[@detailExpand]="expansionAnimation(defect, expandedElement)"
					fxLayout="row"
				>
					<!-- if there are repairs, loop through to display each comment and timestamp -->
					<div
						*ngIf="defect.repairs"
						class="expanded-row-content"
						fxLayout="column"
						fxFlex="50"
					>
						<div class="content-header">{{ "notes" | i18next: { format: "uppercase" } }}</div>
						<div
							*ngFor="let repair of defect.repairs"
							class="content-margin pending-notes"
							fxLayout="row"
						>
							<div fxFlex="25">
								{{ formatDate(repair.created) }}
							</div>
							<div fxFlex="65">
								{{ repair.comment }}
							</div>
						</div>
					</div>

					<!-- if there are photos of the defect, loop through to display each photo -->
					<div
						*ngIf="defect.photos"
						class="expanded-row-content"
						fxLayout="column"
						fxFlex="50"
					>
						<div class="content-header">{{ "defect photos" | i18next: { format: "uppercase" } }}</div>
						<div
							class="image-content-margin"
							fxLayout="row wrap"
						>
							<div *ngFor="let photo of defect.photos; let i = index">
								<img
									id="defect-thumbnail"
									class="thumbnail"
									[src]="photo.sanitizedImage"
									(click)="handleImageClick(defect, i)"
								/>
							</div>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="displayedColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let defect; columns: displayedColumns; let i = dataIndex"
			[ngClass]="getDividerClass(defect, i)"
		></tr>

		<tr
			mat-row
			*matRowDef="let row; columns: ['expandedDetail']; let i = dataIndex"
			class="detail-row"
			[ngClass]="getDividerClass(row, i)"
		></tr>
	</table>

	<app-no-results-card
		*ngIf="noDefects"
		[message]="noDefectsString"
	></app-no-results-card>

	<div [hidden]="noDefects">
		<app-paginator
			[length]="totalOpenDefects"
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			(pageIndexChange)="onPageIndexChange($event)"
			(pageQueryChange)="onQueryChange($event)"
			(pageSizeChange)="onPageSizeChange($event)"
		></app-paginator>
	</div>
</div>
