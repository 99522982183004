import { APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import { I18NEXT_SERVICE } from "angular-i18next";
import { appInit } from "./appInit";
import { localeIdFactory } from "./localeIdFactory";

export const I18N_PROVIDERS = [
	{
		provide: APP_INITIALIZER,
		useFactory: appInit,
		deps: [I18NEXT_SERVICE],
		multi: true,
	},
	{
		provide: LOCALE_ID,
		deps: [I18NEXT_SERVICE],
		useFactory: localeIdFactory,
	},
];
