import { Pipe, PipeTransform } from "@angular/core";
import { currentLocale } from "../i18next";

@Pipe({
	name: "localizedNumber",
})
export class LocalizedNumberPipe implements PipeTransform {
	transform(value: any, format: string = "1.0-0"): string {
		if (isNaN(value)) {
			return "Invalid number";
		}

		try {
			const formatOptions = this.parseFormat(format);
			const formatter = new Intl.NumberFormat(currentLocale(), {
				minimumIntegerDigits: formatOptions.minimumIntegerDigits,
				minimumFractionDigits: formatOptions.minFractionDigits,
				maximumFractionDigits: formatOptions.maxFractionDigits,
			});

			return formatter.format(value);
		} catch (error) {
			console.error("Error in localizedNumber pipe:", error);
			return value.toString();
		}
	}

	private parseFormat(format: string) {
		const [integerPart, fractionalPart] = format.split(".");
		const [minFractionDigits, maxFractionDigits] = fractionalPart.split("-").map(Number);

		return {
			minimumIntegerDigits: integerPart ? parseInt(integerPart, 10) : 1,
			minFractionDigits: minFractionDigits || 0,
			maxFractionDigits: maxFractionDigits || 0,
		};
	}
}
