import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatRadioChange } from "@angular/material/radio";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { Subscription } from "rxjs";
import { SatCalendar } from "saturn-datepicker";
import { translateAndFormat } from "src/app/i18next";
import { DateRange, DateRangeFilterState } from "src/app/models/date-range.models";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { newDate } from "src/utils/newDate/newDate";
import { DateRangeFilterChange } from "../../models/emitter-events.models";

@Component({
	selector: "app-date-range-filter",
	templateUrl: "./date-range-filter.component.html",
	styleUrls: ["./date-range-filter.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DateRangeFilterComponent implements OnInit, OnDestroy {
	state: DateRangeFilterState;
	stateKey: string;

	dateRange = DateRange;

	currentFilter: DateRange = DateRange.previous7;
	filterButtonText: string = translateAndFormat("prev 7 days", "capitalize");
	potentialFilterButtonText: string = translateAndFormat("prev 7 days", "capitalize");
	buttonIconValue = "arrow_drop_down";
	filterButtonClass = "filter-button-not-focused";
	filterButtonLabelClass = "filter-button-label-not-focused";

	@ViewChild("calendar", { static: true }) calendar: SatCalendar<Date>;
	@ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;

	dateRangeBeginDate: Date = newDate();
	dateRangeEndDate: Date = newDate();

	potentialDateRangeBeginDate: Date = newDate();
	potentialDateRangeEndDate: Date = newDate();

	@Input() initStartDate: Date = startOfDay(subDays(newDate(), 7));
	@Input() initEndDate: Date = newDate();
	@Input() context: string;

	@Output() submitDateRangeFilter = new EventEmitter<DateRangeFilterChange>();
	@Output() radioButtonChange = new EventEmitter<string>();

	defaultState: DateRangeFilterState = {
		selectedDateRange: DateRange.previous7,
		dateRangeBeginDate: this.initStartDate,
		dateRangeEndDate: this.initEndDate,
		potentialDateRangeBeginDate: this.initStartDate,
		potentialDateRangeEndDate: this.initEndDate,
		potentialFilterButtonText: translateAndFormat("prev 7 days", "capitalize"),
		currentFilter: DateRange.previous7,
		filterButtonText: translateAndFormat("prev 7 days", "capitalize"),
	};
	subscriptions = new Subscription();
	// we could also provide events for calendarClicked and cancel

	constructor(public myElement: ElementRef, public memCacheService: MemCacheService) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	initState() {
		this.state = this.getState();
		if (!this.state) {
			this.state = {
				...this.defaultState,
			};
		}

		this.dateRangeBeginDate = this.state.dateRangeBeginDate;
		this.dateRangeEndDate = this.state.dateRangeEndDate;
		this.potentialDateRangeBeginDate = this.state.potentialDateRangeBeginDate;
		this.potentialDateRangeEndDate = this.state.potentialDateRangeEndDate;
		this.filterButtonText = this.state.filterButtonText;
		this.currentFilter = this.state.currentFilter;
		this.potentialFilterButtonText = this.state.potentialFilterButtonText;
		this.state.selectedDateRange = this.state.currentFilter;

		// update calendar
		if (this.calendar) {
			this.calendar.beginDate = this.potentialDateRangeBeginDate;
			this.calendar.endDate = this.potentialDateRangeEndDate;
		}
	}

	ngOnInit() {
		this.stateKey = `date-range-filter: ${this.context}`;

		// listen for clear event
		this.subscriptions.add(
			this.memCacheService.cleared$().subscribe(() => {
				this.memCacheService.setValue(this.stateKey, null);

				this.initState();

				this.filterSubmit();
			}),
		);

		// initialize from persisted state
		this.initState();
	}

	flushState() {
		this.memCacheService.setValue<DateRangeFilterState>(this.stateKey, {
			...this.state,
			dateRangeBeginDate: this.dateRangeBeginDate,
			dateRangeEndDate: this.dateRangeEndDate,
			potentialDateRangeBeginDate: this.potentialDateRangeBeginDate,
			potentialDateRangeEndDate: this.potentialDateRangeEndDate,
			potentialFilterButtonText: this.potentialFilterButtonText,
			currentFilter: this.currentFilter,
			filterButtonText: this.filterButtonText,
		});
	}

	getState() {
		return this.memCacheService.getValue<DateRangeFilterState>(this.stateKey);
	}

	// custom range selected
	inlineRangeChange() {
		this.potentialDateRangeBeginDate = this.calendar.beginDate;
		this.potentialDateRangeEndDate = endOfDay(this.calendar.endDate);
		this.currentFilter = DateRange.custom;
	}

	// when the calendar is clicked
	inlineRangeClick(event: MouseEvent) {
		// get ready to change the button value
		this.potentialFilterButtonText = translateAndFormat("custom", "capitalize");

		// move the radio button selection to 'custom'
		this.state.selectedDateRange = DateRange.custom;

		// and prevent the menu from collapsing
		event.stopPropagation();
	}

	matRadioChange(event: MatRadioChange) {
		switch (event.value) {
			case DateRange.today:
				this.potentialDateRangeBeginDate = startOfDay(newDate());
				this.potentialDateRangeEndDate = newDate();
				this.radioButtonChange.emit("today");
				this.currentFilter = DateRange.today;
				this.potentialFilterButtonText = translateAndFormat("today", "capitalize");
				break;

			case DateRange.previous7:
				this.potentialDateRangeBeginDate = startOfDay(subDays(newDate(), 7));
				this.potentialDateRangeEndDate = newDate();
				this.radioButtonChange.emit(DateRange.previous7);
				this.currentFilter = DateRange.previous7;
				this.potentialFilterButtonText = translateAndFormat("prev 7 days", "capitalize");
				break;

			case DateRange.previous14:
				this.potentialDateRangeBeginDate = startOfDay(subDays(newDate(), 14));
				this.potentialDateRangeEndDate = newDate();
				this.radioButtonChange.emit(DateRange.previous14);
				this.currentFilter = DateRange.previous14;
				this.potentialFilterButtonText = translateAndFormat("prev 14 days", "capitalize");
				break;

			case DateRange.previous30:
				this.potentialDateRangeBeginDate = startOfDay(subDays(newDate(), 30));
				this.potentialDateRangeEndDate = newDate();
				this.radioButtonChange.emit(DateRange.previous30);
				this.currentFilter = DateRange.previous30;
				this.potentialFilterButtonText = translateAndFormat("prev 30 days", "capitalize");
				break;

			case DateRange.previous365:
				this.potentialDateRangeBeginDate = startOfDay(subDays(newDate(), 365));
				this.potentialDateRangeEndDate = newDate();
				this.radioButtonChange.emit(DateRange.previous365);
				this.currentFilter = DateRange.previous365;
				this.potentialFilterButtonText = translateAndFormat("prev 365 days", "capitalize");
				break;

			case DateRange.custom:
				this.radioButtonChange.emit(DateRange.custom);
				this.currentFilter = DateRange.custom;
				this.potentialFilterButtonText = translateAndFormat("custom", "capitalize");
				break;
		}

		this.state.selectedDateRange = this.currentFilter;
	}

	matRadioClick(event: MouseEvent): void {
		event.stopPropagation(); // otherwise the menu will collapse
	}

	filterSubmit(): void {
		this.memCacheService.setValue("resetState", true);
		const calendarBeginDate = this.calendar.beginDate;
		const calendarEndDate = this.calendar.endDate;

		const dateEvent: DateRangeFilterChange = {
			beginDate: calendarBeginDate,
			endDate: calendarEndDate,
			currentFilter: this.currentFilter,
		};

		this.submitDateRangeFilter.emit(dateEvent);
		this.matMenuTrigger.closeMenu();

		// don't apply the change to the control until the user clicks 'apply'
		this.filterButtonText = this.potentialFilterButtonText;
		this.dateRangeBeginDate = this.potentialDateRangeBeginDate;
		this.dateRangeEndDate = this.potentialDateRangeEndDate;

		this.flushState();
	}

	filterCancel() {
		this.matMenuTrigger.closeMenu();
	}

	handleMenuClose(): void {
		this.buttonIconValue = "arrow_drop_down";
		this.filterButtonClass = "filter-button-not-focused";
		this.filterButtonLabelClass = "filter-button-label-not-focused";
	}

	handleMenuOpen(): void {
		this.buttonIconValue = "arrow_drop_up";
		this.filterButtonClass = "filter-button-focused";
		this.filterButtonLabelClass = "filter-button-label-focused";

		// reset the calendar
		this.potentialDateRangeBeginDate = this.dateRangeBeginDate;
		this.potentialDateRangeEndDate = this.dateRangeEndDate;

		// (re) initialize from persisted state
		this.initState();
	}
}
