import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { InspectionDetailStaticInfoViewModel } from "src/app/models/inspection-detail-view-models.models";
import { Id, InspectionGet } from "../../../models/openAPIAliases";
import { InspectionService } from "../service/inspection.service";
import { InspectionStateService } from "./inspection-state.service";

export interface InspectionDetailStateModel {
	staticInfo: InspectionDetailStaticInfoViewModel;
	inspection?: InspectionGet;
}

const inspectionStateDefaults: InspectionDetailStateModel = {
	staticInfo: null,
	inspection: null,
};

export class GetInspection {
	static readonly type = "[Inspection] GetInspection";
	constructor(public payload: string, public companyId: Id, public endpoint: string) {}
}

export class ClearInspection {
	static readonly type = "[Inspection] ClearInspection";
}

@Injectable()
@State<InspectionDetailStateModel>({
	name: "inspection",
	defaults: inspectionStateDefaults,
})
export class InspectionState {
	constructor(private inspectionService: InspectionService, private inspectionStateService: InspectionStateService) {}

	@Selector()
	static getStaticInfo(state: InspectionDetailStateModel) {
		return state.staticInfo;
	}

	@Selector()
	static getInspection(state: InspectionDetailStateModel) {
		return state.inspection;
	}

	@Action(GetInspection)
	getInspection(
		{ patchState }: StateContext<InspectionDetailStateModel>,
		{ payload, companyId, endpoint }: GetInspection,
	) {
		return this.inspectionService.getInspection(payload, companyId, endpoint).pipe(
			tap((result: InspectionGet) => {
				const { staticInfo: staticInfo, inspection } = this.inspectionStateService.populateState(result);
				patchState({
					staticInfo: staticInfo,
					inspection: inspection,
				});
			}),
		);
	}

	@Action(ClearInspection)
	clearInspection({ setState }: StateContext<InspectionDetailStateModel>) {
		return setState(inspectionStateDefaults);
	}
}
