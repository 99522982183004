import { DAY } from "src/app/constants/time-constants";

/**
 * Get an Array with all the dates between a date range.
 *
 * @param startDate Initial Date of the range.
 * @param endDate Final Date of the range.
 * @param gap Time in milliseconds between each date.
 * @returns An array with all the dates between the initial and final date.
 */
export const getDateRangeFromDates = ({
	endDate,
	gap = DAY,
	startDate,
}: {
	endDate?: Date;
	gap?: number;
	startDate: Date;
}) =>
	endDate === undefined
		? [startDate]
		: [
				startDate,
				...Array.from(
					{ length: Math.ceil(Math.abs(endDate.getTime() - startDate.getTime()) / gap) - 2 },
					(_, index) => new Date(startDate.getTime() + gap * (index + 1)),
				),
				endDate,
		  ];
