import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";
import { OverlayComponent } from "../../../components/overlay/overlay.component";
import { OVERLAY_OPTIONS } from "../overlay-injection-token";

@Injectable({
	providedIn: "root",
})
export class OverlayService {
	/** Reference to currently open overlay. */
	public overlayInstance: OverlayRef;
	private closePending = 0;

	constructor(private injector: Injector, public overlay: Overlay) {}

	public open(): void {
		this.closePending++;

		if (this.closePending === 1) {
			// need to create an overlay
			this.overlayInstance = this.overlay.create({
				positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
				hasBackdrop: true,
			});

			this.overlayInstance.attach(
				new ComponentPortal(
					OverlayComponent,
					null,
					new PortalInjector(this.injector, new WeakMap([[OVERLAY_OPTIONS, {}]])),
				),
			);
		}
	}

	public close(): void {
		this.closePending--;

		if (!this.closePending) {
			this.overlayInstance?.dispose();
		}
	}
}
