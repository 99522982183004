import format from "date-fns/format";
import { currentLocale } from "./currentLocale";
import { locales } from "./date-fns-locales";

/**
 * Format a date based on the locale
 *
 * Possible localized date-fns formats
 * Long localized date
 *    P - 04/29/1453
 *    PP - Apr 29, 1453
 *    PPP - April 29th, 1453
 *    PPPP - Friday, April 29th, 1453
 * Long localized time
 *    p - 12:00 AM
 *    pp - 12:00:00 AM
 *    ppp - 12:00:00 AM GMT+2
 *    pppp - 12:00:00 AM GMT+02:00
 * Combination of date and time
 *    Pp - 04/29/1453, 12:00 AM
 *    PPpp - Apr 29, 1453, 12:00:00 AM
 *    PPPppp - April 29th, 1453 at 2:00:00 AM GMT+2
 *    PPPPpppp - Friday, April 29th, 1453 at 12:00:00 AM GMT+02:00
 * @param date Date to format
 * @param formatStr date-fns format type (ex. 'P' - 04/29/1453, 'PP' - Apr 29, 1453)
 */
export const formatDate = (date: Date, formatStr = "P") =>
	format(date, formatStr, {
		locale: locales[currentLocale()],
	});

/**
 * Formats a Date object into a string representation based on the current locale.
 *
 * @param date - The Date object to be formatted.
 * @returns A string representing the formatted date and time.
 *
 * This function formats the date and time based on a specific format pattern ("M/d/yyyy (H:mm:ss a)").
 * The formatting considers the current locale settings, which affects how the date and time are represented.
 */

export const formatDateTime = (date: Date) =>
	format(date, "M/d/yyyy (H:mm:ss a)", { locale: locales[currentLocale()] });
