import de from "date-fns/locale/de";
import enGB from "date-fns/locale/en-GB";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";

export const locales = {
	"en-us": enUS,
	"es-es": es,
	"en-gb": enGB,
	"fr-fr": fr,
	"it-it": it,
	"de-de": de,
};
