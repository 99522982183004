/**
 * Export the different date patterns depending on the locale
 */
export const dateFnsPatterns = {
	// P: Long localized date (for example: 04/29/1453)
	P: {
		"en-us": "MM/DD/YYYY",
		"es-es": "DD/MM/YYYY",
		"en-gb": "DD/MM/YYYY",
		"fr-fr": "DD/MM/YYYY",
		"it-it": "DD/MM/YYYY",
		"de-de": "DD.MM.YYYY",
	},
};
