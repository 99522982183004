<div class="filter-card">
	<mat-card
		[ngClass]="filterCardClass"
		fxLayout="column"
		fxLayoutAlign="start start"
		fxLayoutGap="14px"
	>
		<div
			class="mat-card-item"
			fxLayout="row"
			fxLayoutAlign="start center"
			fxLayoutGap="14px"
		>
			<div
				ngClass="mat-card-left-item"
				fxLayout="row"
				fxLayoutAlign="start start"
				fxLayoutGap="14px"
			>
				<app-get-table-filter
					#getTableFilter
					*ngIf="enabledFilters.searchBar"
					(chip)="handleTableFilterChips($event)"
				></app-get-table-filter>

				<div
					class="filter-divider"
					*ngIf="enabledFilters.searchBar"
				></div>

				<div
					fxLayout="row-gap wrap"
					fxLayoutAlign="start center"
					fxLayoutGap="14px"
					class="filter-right"
				>
					<app-date-range-filter
						*ngIf="enabledFilters.dateRange"
						(submitDateRangeFilter)="handleDateRangeFilterEvent($event)"
						[initStartDate]="currentStartDate"
						[initEndDate]="currentEndDate"
						[context]="context"
					></app-date-range-filter>

					<app-custom-date-range-filter
						*ngIf="enabledFilters.customDateRange"
						(submitDateRangeFilter)="handleCustomDateRangeFilterEvent($event)"
						[initStartDate]="currentStartDate"
						[initEndDate]="currentEndDate"
						[context]="context"
					></app-custom-date-range-filter>

					<app-multi-select-dropdown
						*ngIf="enabledFilters.assetId"
						(submitDropdownMultiSelect)="handleAssetIdFilterEvent($event)"
						[context]="assetIdContext"
						[dropdownLabel]="assetIdLabel"
						[options]="assetIds"
					></app-multi-select-dropdown>

					<app-multi-select-dropdown
						*ngIf="enabledFilters.assetType"
						(submitDropdownMultiSelect)="handleAssetTypeFilterEvent($event)"
						[context]="assetTypeContext"
						[dropdownLabel]="assetTypeLabel"
						[options]="assetTypes"
					></app-multi-select-dropdown>

					<app-multi-select-dropdown
						*ngIf="enabledFilters.inspector"
						(submitDropdownMultiSelect)="handleInspectorFilterEvent($event)"
						[context]="inspectorContext"
						[dropdownLabel]="inspectorLabel"
						[options]="inspectors"
					></app-multi-select-dropdown>

					<app-defect-type-filter
						*ngIf="enabledFilters.defectType"
						(changeDefectFilter)="handleDefectTypeFilterEvent($event)"
						[options]="defectTypes"
						[context]="context"
					></app-defect-type-filter>

					<div
						*ngIf="enabledFilters.inspectionName"
						fxLayout="row"
						fxLayoutAlign="start center"
						fxLayoutGap="14px"
					>
						<app-multi-select-dropdown
							*ngIf="enabledFilters.inspectionName"
							(submitDropdownMultiSelect)="handleInspectionNameFilterEvent($event)"
							[context]="inspectionNameContext"
							[dropdownLabel]="inspectionNameLabel"
							[options]="inspectionNames"
						></app-multi-select-dropdown>
					</div>

					<div
						*ngIf="enabledFilters.inspectionType"
						fxLayout="row"
						fxLayoutAlign="start center"
						fxLayoutGap="14px"
					>
						<app-multi-select-dropdown
							*ngIf="enabledFilters.inspectionType"
							(submitDropdownMultiSelect)="handleInspectionTypeFilterEvent($event)"
							[context]="inspectionTypeContext"
							[dropdownLabel]="inspectionTypeLabel"
							[options]="inspectionTypes"
						></app-multi-select-dropdown>
					</div>

					<app-multi-select-dropdown
						*ngIf="enabledFilters.homeLocation"
						(submitDropdownMultiSelect)="handleHomeLocationFilterEvent($event)"
						[context]="homeLocationContext"
						[dropdownLabel]="homeLocationLabel"
						[options]="homeLocations"
					></app-multi-select-dropdown>

					<div
						fxLayout="row"
						fxLayoutAlign="start center"
						fxLayoutGap="14px"
					>
						<app-multi-select-dropdown
							*ngIf="enabledFilters.severity"
							(submitDropdownMultiSelect)="handleSeverityFilterEvent($event)"
							[context]="severityContext"
							[dropdownLabel]="severityLabel"
							[options]="severities"
						></app-multi-select-dropdown>
					</div>
				</div>
			</div>

			<div
				*ngIf="enabledFilters.resetFilterButton && isZonarTheme"
				fxLayout="row"
				fxLayoutAlign="end end"
				fxLayoutGap="14px"
			>
				<button
					mat-button
					class="reset-filters-button"
					(click)="clearAllState()"
					[disabled]="!filtersActive"
				>
					<span class="reset-filters-text">{{ "reset filters" | i18next: { format: "capitalize" } }}</span>
				</button>
			</div>
		</div>
		<mat-chip-list
			#chipList
			[attr.aria-label]="'table filter chips' | i18next: { format: 'title' }"
			*ngIf="tableFilterChips.length > 0"
		>
			<mat-chip
				*ngFor="let chip of tableFilterChips"
				(removed)="handleChipRemove(chip)"
			>
				{{ chip.displayValue }} &nbsp;
				<span class="chip-group-name">({{ chip.tableDisplayValue }})</span>
				<mat-icon matChipRemove>cancel</mat-icon>
			</mat-chip>
		</mat-chip-list>
	</mat-card>
</div>
