import { environment } from "src/environments/environment";

/**
 * Get the UUID of the Zonar other component based on the current environment.
 * @returns The UUID of the Zonar other component.
 */
export const getZonarOtherUUID = () => {
	switch (environment.environment) {
		case "development":
			return "2ac58125-3def-4f3e-b917-f05eab9f6225";
		case "qa":
			return "f0a59aa0-49ea-4cbd-b2a0-a92a0e6635de";
		case "production":
			return "c1504250-8cf8-4391-988b-da2ddeac5b70";
	}
};
