import { TableFilterChip } from "../get-table-filter.component";
import { tableRecordsEqual } from "./table-records-equal";

/**
 * Compare two TableFilterChips for equality
 */
export function tableFilterChipsEqual(chip1: TableFilterChip, chip2: TableFilterChip) {
	return (
		chip1.tableName === chip2.tableName &&
		chip1.displayValue === chip2.displayValue &&
		tableRecordsEqual(chip1.record, chip2.record)
	);
}
