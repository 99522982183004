import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { translateAndFormat } from "src/app/i18next";
import { DefectTypeOption } from "src/app/models/emitter-events.models";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";

export interface DefectTypeFilterState {
	filterButtonText: string;
	buttonIconValue: string;
	defectTypeOption: DefectTypeOption;
}

export type DefectTypeOptionFilterType = "open" | "closed";

@Component({
	selector: "app-defect-type-filter",
	templateUrl: "./defect-type-filter.component.html",
	styleUrls: ["./defect-type-filter.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DefectFilterComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() context: string;
	@Input() options: ReadonlyArray<string> = ["open", "closed"];
	@Output() changeDefectFilter = new EventEmitter<DefectTypeOption>();

	buttonIconValue = "arrow_drop_down";
	defaultState: DefectTypeFilterState = {
		filterButtonText: translateAndFormat("status", "title"),
		buttonIconValue: "arrow_drop_down",
		defectTypeOption: { value: "open" },
	};
	filterButtonClass = "filter-button-not-focused";
	filterButtonText: string = translateAndFormat("status", "title");
	filterButtonTextClass = "filter-button-text-value-not-selected";
	filterButtonTextMaxCharacters = 10;
	selectedDefectType: DefectTypeOptionFilterType = "open";
	showLabel = false;
	state: DefectTypeFilterState;
	stateKey: string;
	subscriptions = new Subscription();

	constructor(private memCacheService: MemCacheService) {}

	ngOnInit() {
		this.subscriptions.add(
			this.memCacheService.cleared$().subscribe(() => {
				this.memCacheService.setValue(this.stateKey, null);
				this.captureSelection();

				this.changeDefectFilter.emit({
					value: "open",
				});
			}),
		);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.captureSelection();
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	filterBy(defectType: DefectTypeOptionFilterType) {
		this.selectedDefectType = defectType;
		this.changeDefectFilter.emit({ value: defectType });

		this.flushState();
	}

	handleMenuClose(): void {
		this.buttonIconValue = "arrow_drop_down";
		this.filterButtonClass = "filter-button-not-focused";
	}

	handleMenuOpen(): void {
		this.memCacheService.setValue("resetState", true);
		this.buttonIconValue = "arrow_drop_up";
		this.filterButtonClass = "filter-button-focused";
	}

	initState() {
		this.stateKey = `defect-status-filter: ${this.context}`;

		this.state = this.getState();

		if (!this.state) {
			this.state = {
				...this.defaultState,
			};
		}
	}

	flushState() {
		this.memCacheService.setValue<DefectTypeFilterState>(this.stateKey, {
			filterButtonText: this.filterButtonText,
			buttonIconValue: this.buttonIconValue,
			defectTypeOption: { value: this.selectedDefectType },
		});
	}

	private getState() {
		return this.memCacheService.getValue<DefectTypeFilterState>(this.stateKey);
	}

	private captureSelection() {
		this.initState();

		this.selectedDefectType = this.state.defectTypeOption.value;
		this.filterButtonText = this.state.filterButtonText;
	}
}
