import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PhotoViewModel } from "../models/open-defect-table.models";
import { Media } from "../models/openAPIAliases";

@Injectable({
	providedIn: "root",
})
export class PhotoViewModelService {
	constructor(private sanitizer: DomSanitizer) {}

	public buildPhotoViewModel(images: Media[]): PhotoViewModel[] {
		return images.map((image: Media) => {
			return {
				mediaId: image.mediaId,
				mediaType: image.mediaType,
				thumbnail: image.thumbnail,
				sanitizedImage: this.sanitizer.bypassSecurityTrustResourceUrl(
					`data:image/${image.mediaType};base64,${image.thumbnail}`,
				),
			} as PhotoViewModel;
		});
	}
}
