import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorsComponent } from "./components/errors/errors.component";
import { DefaultLayoutComponent } from "./views/default-layout/default-layout.component";
import { DEFAULT_ROUTES } from "./views/default-layout/routes/default-layout.routes";

const routes: Routes = [
	// the children default routes allows us to create a hierarchy of our default layout: https://alligator.io/angular/angular-router-child-routes/
	{ path: "", component: DefaultLayoutComponent, children: DEFAULT_ROUTES, canActivate: [] },

	{ path: "unauthorized", component: ErrorsComponent },
	{ path: "server-error", component: ErrorsComponent },

	// when no route matches, show 404 page
	{ path: "**", pathMatch: "full", component: ErrorsComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
