import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, retry } from "rxjs/operators";
import { OverlayService } from "src/app/components/overlay/service/overlay.service";

export interface Request {
	url: string;
	httpOptions?: unknown;
	data?: unknown;
}

export const networkErrorMessage = "Network error";

@Injectable({
	providedIn: "root",
})
export class RequestService {
	constructor(private http: HttpClient, private overlayService: OverlayService) {}

	get<T>(request: Request): Observable<T> {
		this.overlayService.open(); // Activate the global spinner

		return this.http.get<T>(request.url, request.httpOptions).pipe(
			retry(1),
			catchError((error: HttpErrorResponse) => this.handleError(error)),
			finalize(() => this.overlayService.close()), // Close the global spinner when complete or error
		);
	}

	post<T>(request: Request): Observable<T> {
		this.overlayService.open(); // Activate the global spinner

		return this.http.post<T>(request.url, request.data, request.httpOptions).pipe(
			retry(1),
			catchError((error: HttpErrorResponse) => this.handleError(error)),
			finalize(() => this.overlayService.close()),
		);
	}

	handleError(error: HttpErrorResponse) {
		this.overlayService.close();
		return throwError(error);
	}
}
