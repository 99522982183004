<div
	class="container"
	[ngClass]="{ disabled: selected }"
>
	<div
		*ngFor="let fragment of fragments"
		[ngClass]="{
			highlight: fragment.regularHighlight
		}"
	>
		<span>{{ fragment.text }}</span>
	</div>
</div>
