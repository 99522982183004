<div class="print-view">
	<div *ngIf="viewModels; then printView; else backButton"></div>

	<ng-template #backButton>
		<button (click)="clickedOnBack()">
			{{ "back" | i18next: { format: "title" } }}
		</button>
	</ng-template>

	<ng-template #printView>
		<div class="back-button">
			<a
				[routerLink]="previousPageUrl"
				class="inspection-history-link"
			>
				<
				{{
					"back to previousPageTitle"
						| i18next: { format: "capitalize", previousPageTitle: previousPageTitle }
				}}
			</a>
		</div>
		<div *ngFor="let viewModel of viewModels; index as i">
			<!-- Inspection Info -->
			<div>
				{{
					"inspection number of numbers"
						| i18next: { format: "capitalize", number: i + 1, numbers: viewModels.length }
				}}
			</div>
			<br />

			<div>
				<span class="inspection-info-label">{{ "company" | i18next: { format: "title" } }}:</span>
				<span class="company-name">{{ viewModel.inspectionInfo.static.companyName }}</span>
			</div>

			<div>
				<span class="inspection-info-label">{{ "inspector" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.inspector }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "inspection type" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.inspectionType }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "inspection start time" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.time.start }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "inspection duration" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.time.duration }}
			</div>

			<br />

			<div>
				<span class="inspection-info-label">{{ "address" | i18next: { format: "uppercase" } }}:</span>
				{{ viewModel.inspectionInfo.static.geolocation }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "license" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.license }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "jurisdiction type" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.WA }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "vin" | i18next: { format: "uppercase" } }}:</span>
				{{ viewModel.inspectionInfo.static.vin }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "power unit" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.powerUnit }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "language choice" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.languageChoice }}
			</div>

			<div *ngFor="let detail of viewModel.inspectionInfo.dynamic">
				<span class="inspection-info-label">{{ detail.languageLabel }}:</span>
				{{ detail.inspectionDetailContent }}
			</div>
			<ng-container *ngFor="let photo of viewModel.inspectionInfo.formDataPhotos">
				<div *ngIf="photo.images">
					<span class="inspection-info-label">{{ photo.label }}:</span>
					<div class="photo-row">
						<ng-container *ngFor="let image of photo.images; let i = index">
							<img
								id="form-data-photos-image"
								class="thumbnail"
								[src]="image.sanitizedImage"
								(click)="handleInspectionImageClick(photo, i)"
							/>
						</ng-container>
					</div>
				</div>
				<div *ngIf="!photo.images">
					<span class="inspection-info-label">{{ photo.label }}:</span>
					{{ photo.defaultValue }}
				</div>
			</ng-container>

			<div>
				<span class="inspection-info-label">{{ "third signature" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.thirdSignature }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "user agent" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.userAgent }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "inspector divisions" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.inspectorDivisions }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "max severity" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.static.maxSeverity }}
			</div>

			<br />

			<div>
				<span class="inspection-info-label">{{ "inspection start time" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.time.start }}
			</div>
			<div>
				<span class="inspection-info-label">{{ "inspection end time" | i18next: { format: "title" } }}:</span>
				{{ viewModel.inspectionInfo.time.end }}
			</div>
			<div>
				<span class="inspection-info-label">
					{{ "inspection upload time" | i18next: { format: "title" } }}:
				</span>
				{{ viewModel.inspectionInfo.time.upload }}
			</div>
			<div>
				<span class="inspection-info-label">
					{{ "inspection certify time" | i18next: { format: "title" } }}:
				</span>
				{{ viewModel.inspectionInfo.time.certify }}
			</div>

			<!-- Asset Info -->
			<div *ngFor="let inspectionAsset of viewModel.inspectionAssets">
				<br />
				<br />

				<div>
					<span class="inspection-info-label">{{ "asset name" | i18next: { format: "title" } }}:</span>
					<span class="asset-name">{{ inspectionAsset.assetName }}</span>
				</div>
				<div>
					<span class="inspection-info-label">{{ "asset category" | i18next: { format: "title" } }}:</span>
					{{ inspectionAsset.assetCategory }}
				</div>
				<div>
					<span class="inspection-info-label">{{ "asset division" | i18next: { format: "title" } }}:</span>
					{{ inspectionAsset.assetDivision }}
				</div>

				<div *ngFor="let detail of inspectionAsset.assetDetails">
					<div>
						<span class="inspection-info-label">{{ detail.label }}:</span>
						{{ detail.value }}
					</div>
				</div>

				<div>
					<br />

					<!-- Inspection asset zones tables -->
					<div
						*ngIf="
							inspectionAsset.inspectionZones && inspectionAsset.inspectionZones.length > 0;
							then inspectionZonesTable;
							else noInspectionZonesMessage
						"
					></div>
					<ng-template #inspectionZonesTable>
						<div>{{ "inspection zones" | i18next: { format: "title" } }}</div>

						<table>
							<thead>
								<tr>
									<th>{{ "zone name" | i18next: { format: "title" } }}</th>
									<th>{{ "time" | i18next: { format: "title" } }}</th>
									<th>{{ "components" | i18next: { format: "title" } }}</th>
									<th>{{ "defects" | i18next: { format: "title" } }}</th>
									<th>{{ "verification type" | i18next: { format: "title" } }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let inspectionZone of inspectionAsset.inspectionZones">
									<td>{{ inspectionZone.languageLabel }}</td>
									<td>{{ inspectionZone.timestamp }}</td>
									<td>{{ inspectionZone.components }}</td>
									<td>{{ inspectionZone.inspectionDefectsYN }}</td>
									<td>{{ inspectionZone.verificationType }}</td>
								</tr>
							</tbody>
						</table>
					</ng-template>

					<ng-template #noInspectionZonesMessage>
						{{
							"there are no inspection zones present on this asset in this inspection"
								| i18next: { format: "capitalize" }
						}}
					</ng-template>

					<br />

					<!-- Inspection asset defects tables -->
					<div
						*ngIf="
							inspectionAsset.inspectionDefects && inspectionAsset.inspectionDefects.length > 0;
							then inspectionDefectsTable;
							else noInspectionDefectsMessage
						"
					></div>
					<ng-template #inspectionDefectsTable>
						<div>{{ "defects" | i18next: { format: "capitalize" } }}</div>

						<table>
							<thead>
								<tr>
									<th>{{ "zone" | i18next: { format: "title" } }}</th>
									<th>{{ "component" | i18next: { format: "title" } }}</th>
									<th>{{ "condition" | i18next: { format: "title" } }}</th>
									<th>{{ "severity" | i18next: { format: "title" } }}</th>
									<th>{{ "repair status" | i18next: { format: "title" } }}</th>
									<th>{{ "repair technician" | i18next: { format: "title" } }}</th>
									<th>{{ "repair date" | i18next: { format: "title" } }}</th>
									<th>{{ "repair resolution" | i18next: { format: "title" } }}</th>
									<th>{{ "repair comments" | i18next: { format: "title" } }}</th>
									<th>{{ "repair work order" | i18next: { format: "title" } }}</th>
									<th>{{ "photos" | i18next: { format: "title" } }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let inspectionDefect of inspectionAsset.inspectionDefects">
									<ng-container *ngIf="inspectionDefect">
										<td>{{ inspectionDefect.zoneName }}</td>
										<td>{{ inspectionDefect.componentLabel }}</td>
										<td>{{ inspectionDefect.conditionLabel }}</td>
										<td>{{ inspectionDefect.severity }}</td>
										<td>{{ inspectionDefect.repairStatus }}</td>
										<td>{{ inspectionDefect.repairTechnician }}</td>
										<td>{{ inspectionDefect.repairDate }}</td>
										<td>{{ inspectionDefect.repairResolution }}</td>
										<td>{{ inspectionDefect.repairComment }}</td>
										<td>{{ inspectionDefect.repairWorkOrder }}</td>
										<td>
											<div
												*ngFor="let photo of inspectionDefect.photos"
												class=""
											>
												<img
													class="photo"
													[src]="photo.sanitizedImage"
												/>
											</div>
										</td>
									</ng-container>
								</tr>
							</tbody>
						</table>
					</ng-template>

					<ng-template #noInspectionDefectsMessage>
						{{
							"there are no inspection defects present on this asset in this inspection"
								| i18next: { format: "capitalize" }
						}}
					</ng-template>
				</div>
			</div>

			<br />

			<!-- Telemetry table -->
			<div>{{ "telemetry info" | i18next: { format: "title" } }}</div>
			<table>
				<thead>
					<tr>
						<th>{{ "telemetry type" | i18next: { format: "title" } }}</th>
						<th>{{ "relevant value" | i18next: { format: "title" } }}</th>
						<th>{{ "latitude" | i18next: { format: "title" } }}</th>
						<th>{{ "longitude" | i18next: { format: "title" } }}</th>
						<th>{{ "elevation" | i18next: { format: "title" } }}</th>
						<th>{{ "horizontal accuracy" | i18next: { format: "title" } }}</th>
						<th>{{ "timestamp" | i18next: { format: "title" } }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let telemetry of viewModel.telemetry">
						<td>{{ telemetry.telemetryType }}</td>
						<td>{{ telemetry.relevantValue }}</td>
						<td>{{ telemetry.latitude }}</td>
						<td>{{ telemetry.longitude }}</td>
						<td>{{ telemetry.elevation }}</td>
						<td>{{ telemetry.horizontalAccuracy }}</td>
						<td>{{ telemetry.timestamp }}</td>
					</tr>
				</tbody>
			</table>

			<br />

			<div class="pagebreak"></div>
		</div>
	</ng-template>
</div>
