import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-tab",
	templateUrl: "./tab.component.html",
	styleUrls: ["./tab.component.scss"],
})
export class TabComponent {
	@Input()
	label: string;

	@Input()
	selected = true;

	@Output()
	selectTab = new EventEmitter();

	onClick() {
		this.selected = true;

		this.selectTab.emit();
	}
}
