import i18next from "i18next";

/**
 * Return a key translated for i18next
 *
 * @param keyToTranslate Key of the Translation Json
 * @param formatType format to apply: 'capitalize', 'uppercase' or 'title'
 */
export const translateAndFormat = (
	keyToTranslate: string,
	formatType: "capitalize" | "uppercase" | "title" | "camelCase",
) => i18next.format(i18next.t(keyToTranslate), formatType);
