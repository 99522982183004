<button
	mat-button
	title="{{ title | i18next: { format: format } }}"
	class="mat-stroked-button"
	[style]="customStyle"
	[class.filled]="type === 'filled'"
	[class.outlined]="type === 'outlined'"
	[class.outlined-1]="type === 'outlined-1'"
	[class.outlined-2]="type === 'outlined-2'"
	(click)="onButtonClick()"
	[disabled]="disabled"
>
	{{ title | i18next: { format: format } }}
</button>
