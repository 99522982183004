import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	GpsMissingInspectionList,
	GpsMissingInspectionRequestBody,
	MissingInspectionsAssetsResponse,
	MissingInspectionsInspectorsResponse,
	MissingInspectionsRequestBody,
} from "src/app/models/openAPIAliases";
import { MissingInspectionsReportType } from "src/app/views/missing-inspections/missing-inspections.component";
import { environment } from "src/environments/environment";
import { RequestService } from "../request/request.service";

@Injectable({
	providedIn: "root",
})
export class MissingInspectionsService {
	constructor(private requestService: RequestService) {}

	/**
	 *  Get Missing Inspection List
	 * @param dates Dates to filter the missing inspections
	 * @param reportFocus Report focus (assets or inspectors)
	 * @param companyId Company ID
	 * @param query Query params
	 * @returns Missing inspections list
	 */
	getMissingInspections(
		dates: Array<{ startTime: Date; endTime?: Date }>,
		reportFocus: MissingInspectionsReportType,
		companyId: string,
		query = new URLSearchParams(),
	) {
		const url = new URL(environment.environmentConstants.APP_ENDPOINT_EVIR);
		const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		query.forEach((value, key) => url.searchParams.set(key, value));
		if (reportFocus === "assets-gps") {
			url.pathname = `${url.pathname}/reports/missing-inspections-gps`;
			url.searchParams.set("allChildren", "true");
			url.searchParams.delete("reportFocus");
		} else {
			url.pathname = `${url.pathname}/reports/missing-inspections`;
			url.searchParams.set("reportFocus", reportFocus ?? "inspectors");
			url.searchParams.set("timezone", currentTimeZone);
		}

		let data: MissingInspectionsRequestBody | GpsMissingInspectionRequestBody = {
			dates: this.transformDatesToISOStrings(dates),
			companyId,
		};

		data = this.generateRequestBodyFromQuery(
			url,
			data,
			reportFocus === "assets" ? "assetIds" : reportFocus === "inspectors" ? "inspectorIds" : "",
		);

		return this.requestService.post<
			HttpResponse<
				MissingInspectionsAssetsResponse | MissingInspectionsInspectorsResponse | GpsMissingInspectionList
			>
		>({
			url: url.toString(),
			httpOptions: { observe: "response" },
			data,
		});
	}

	/**
	 * Get a param value from the query and delete it from the URL
	 * @param url URL with the query params
	 * @param param Param name to get from the query
	 * @returns Param value
	 */
	private getParamValueAndDeleteItFromUrl(url: URL, param: string) {
		const paramValue = url.searchParams.get(param)?.split(",");
		url.searchParams.delete(param);

		return paramValue;
	}

	/**
	 * Generate the request body from the query params
	 * @param url URL with the query params
	 * @param data Array of objects with startTime and endTime properties
	 * @param paramName Name of the param to get from the query
	 * @returns
	 */

	private generateRequestBodyFromQuery(
		url: URL,
		data: MissingInspectionsRequestBody | GpsMissingInspectionRequestBody,
		paramName: string,
	) {
		const paramsToGet = [
			"inspectionTypeIds",
			"inspectionNameIds",
			"divisionIds",
			"allChildren",
			"zoneLayoutIds",
			"assetIds",
			"inspectorIds",
			"timezone",
			paramName,
		];
		const bodyParams = paramsToGet.reduce((acc, param) => {
			const paramData = this.getParamAndClean(url, param);
			return paramData ? { ...acc, ...paramData } : acc;
		}, {});

		return { ...data, ...bodyParams };
	}

	private getParamAndClean(url: URL, paramName: string): any {
		const paramValue = this.getParamValueAndDeleteItFromUrl(url, paramName);
		return paramValue && paramValue.length > 0
			? {
					[paramName]:
						paramName === "allChildren"
							? JSON.parse(paramValue[0])
							: paramName === "timezone"
							? paramValue[0]
							: paramValue,
			  }
			: null;
	}

	/**
	 * Transform Dates in an array of MissingInspectionsRequestBody to ISO strings
	 * @param dates Array of objects with startTime and endTime properties filled with Dates
	 * @returns Return an Array of objects with startTime and endTime properties filled with ISO strings
	 */
	private transformDatesToISOStrings(dates: Array<{ startTime: Date; endTime?: Date }>) {
		return dates.map(({ startTime, endTime }) =>
			endTime
				? {
						startTime: startTime.toISOString(),
						endTime: endTime.toISOString(),
				  }
				: { startTime: startTime.toISOString() },
		);
	}
}
